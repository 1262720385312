import React, { FC } from "react";
import { Modal } from "antd";
import { Props } from "./types";
import DetailsJobCard from "../DetailsJobCard/DetailsJobCard";

const DetailsJobModal: FC<Props> = ({
  job,
  isShowJobDetailsModal,
  setIsShowJobDetailsModal,
}) => {
  return (
    <Modal
      centered
      mask={true}
      visible={isShowJobDetailsModal}
      onCancel={() => setIsShowJobDetailsModal(false)}
      footer={false}
      width={800}
    >
      <DetailsJobCard job={job} isJobDetailsPage={false} />
    </Modal>
  );
};

export default DetailsJobModal;
