import { SuperAdminApplicants } from "api/superadmin/applicants/types";

import { dateTimeGenerator } from "shared/helpers/helperFunctions";

import { applicantDataType } from "./types";

export const transformSuperAdminApplicantsToTableData = (
  applicants: SuperAdminApplicants[]
): applicantDataType[] => {
  return applicants.map((applicant) => ({
    key: applicant.id.toString(),
    full_name: {
      text: applicant.full_name,
    },
    email: {
      text: applicant.email,
    },
    company_name: {
      text: applicant.company_name,
    },
    job_title: {
      text: applicant.job_title,
    },
    created_at: {
      text: `${dateTimeGenerator(applicant.created_at)}`,
    },
  }));
};
