import { PlusOutlined } from "@ant-design/icons";
import Button from "components/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsMobile, useIsTab } from "shared/hooks";
import { ROUTES } from "shared/routes/constants";
import { useAppSelector } from "store/hooks";
import { selectLoginData } from "store/shared/auth/selectors";
import logoImagePath from "../../../assets/images/Business-in-Japan-logo.svg";
import styles from "./logo.module.scss";
import classNames from "classnames";

const Logo = () => {
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const loginData = useAppSelector(selectLoginData);
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <a href="https://www.businessinjapan.com/">
        <img
          className={classNames({
            [styles.logo]: true,
            [styles.isMobile]: isMobile,
          })}
          src={logoImagePath}
          alt="Company Logo"
        />
      </a>
      {loginData && !loginData.is_superuser === true && (
        <Button
          onClick={() => navigate(ROUTES.PATHS.ADMIN.ADMIN_CREATE_JOBS)}
          type="primary"
          isBordered={true}
          icon={<PlusOutlined />}
          className={classNames({ [styles["is-tab"]]: isTab })}
        >
          <span className={styles.buttonText}>Create Job</span>
        </Button>
      )}
    </div>
  );
};

export default Logo;
