import { Column } from "components/tables/MasterTable/types";

export const COLUMNS: Column[] = [
  {
    name: "Applicant Name",
    key: "full_name",
    type: "text",
  },
  {
    name: "Email",
    key: "email",
    type: "text",
  },
  {
    name: "Job Title",
    key: "job_title",
    type: "text",
  },
  {
    name: "Company",
    key: "company_name",
    type: "text",
  },
  {
    name: "Application Date",
    key: "created_at",
    type: "text",
  },
  //  {
  //    name: "",
  //    key: "three_dot_btn",
  //    type: "action-button-cell",
  //  },
];
