import Sidebar from "components/Sidebar";
import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";
import { ROUTES } from "shared/routes/constants";
import Account from "uicontainers/Admin/Account";
import Applicants from "uicontainers/Admin/Applicants";
import CreateJob from "uicontainers/Admin/Dashboard/CreateJob";
import Dashboard from "uicontainers/Admin/Dashboard/Home";
import Jobs from "uicontainers/Admin/Jobs";
import Queries from "uicontainers/Admin/Queries";
import Content from "uicontainers/Layout/Content";
import { getSideBarMenu } from "./helpers";

const AdminLayout = () => {
  return (
    <Content leftSection={<Sidebar menuItems={getSideBarMenu()} />}>
      <Outlet />
    </Content>
  );
};

const Admin = () => {
  return [
    <Route
      path={ROUTES.PATHS.ADMIN.ADMIN_HOME}
      element={
        <PrivateRoute>
          <AdminLayout />
        </PrivateRoute>
      }
    >
      <Route
        index
        element={<Navigate replace to={ROUTES.PATHS.ADMIN.ADMIN_DASHBOARD} />}
      />
      <Route
        path={ROUTES.PATHS.ADMIN.ADMIN_DASHBOARD}
        element={<Dashboard />}
      />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_JOBS} element={<Jobs />} />
      <Route
        path={ROUTES.PATHS.ADMIN.ADMIN_JOB_APPLICATANS}
        element={<Applicants />}
      />
      <Route
        path={ROUTES.PATHS.ADMIN.ADMIN_JOB_QUERIES}
        element={<Queries />}
      />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_PROFILE} element={<Account />} />
      <Route
        path={ROUTES.PATHS.ADMIN.ADMIN_CREATE_JOBS}
        element={<CreateJob />}
      />
      <Route
        path={ROUTES.PATHS.ADMIN.ADMIN_EDIT_JOBS}
        element={<CreateJob isEditPage={true} />}
      />
    </Route>,
  ];
};

export default Admin;
