import { RecentApplicants } from "api/admin/Dashboard/recent-applicants/types";
import { dateGenerator } from "shared/helpers/helperFunctions";
import { RecentApplicantsProps } from "./types";

export const transformRecentApplicantsToTableData = (
  recent_applicants: RecentApplicants[]
): RecentApplicantsProps[] => {
  return recent_applicants.map((recent_applicant) => ({
    key: recent_applicant.id.toString(),
    full_name: {
      text: recent_applicant.full_name,
    },
    email: {
      text: recent_applicant.email,
    },
    created_at: {
      text: `${dateGenerator(recent_applicant.created_at)}`,
    },
  }));
};
