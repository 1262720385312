import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getCompaniesAsync,
  GetCompanyDetailsAsync,
  getIndustryAsync,
  getMoreCompaniesAsync,
} from "./thunks";
import { PublicCompaniesState } from "./types";

const initialState: PublicCompaniesState = {
  companies: [],
  industry: [],
  getMoreCompanies: [],
  companyDetails: null,
  companiesStatus: "initial",
  companyDetailsStatus: "initial",
  industryResponseStatus: "initial",
  getMoreCompaniesStatus: "initial",
  totalCount: 0,
  currentPage: 1,
  next: null,
};

export const getPublicCompanies = createSlice({
  name: "public/companies",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompaniesAsync.pending, (state) => {
      state.companiesStatus = "started";
    });
    builder.addCase(getCompaniesAsync.fulfilled, (state, action) => {
      state.currentPage = 1;
      state.totalCount = action.payload.count;
      state.next = action.payload.next;
      state.companies = action.payload.results;
      state.companiesStatus = "done";
    });
    builder.addCase(getCompaniesAsync.rejected, (state) => {
      state.companiesStatus = "failed";
    });
    builder
      .addCase(getMoreCompaniesAsync.pending, (state) => {
        state.getMoreCompaniesStatus = "started";
      })
      .addCase(getMoreCompaniesAsync.fulfilled, (state, action) => {
        state.totalCount = action.payload.count;
        state.next = action.payload.next;
        state.companies = [...state.companies, ...action.payload.results];
        state.getMoreCompaniesStatus = "done";
      })
      .addCase(getMoreCompaniesAsync.rejected, (state) => {
        state.getMoreCompaniesStatus = "failed";
      });
    builder.addCase(GetCompanyDetailsAsync.pending, (state) => {
      state.companiesStatus = "started";
    });
    builder.addCase(GetCompanyDetailsAsync.fulfilled, (state, action) => {
      state.companyDetails = action.payload.results;
      state.companiesStatus = "done";
    });
    builder.addCase(GetCompanyDetailsAsync.rejected, (state) => {
      state.companiesStatus = "failed";
    });
    builder.addCase(getIndustryAsync.pending, (state) => {
      state.industryResponseStatus = "started";
    });
    builder.addCase(getIndustryAsync.fulfilled, (state, action) => {
      state.industry = action.payload.results;
      state.industryResponseStatus = "done";
    });
    builder.addCase(getIndustryAsync.rejected, (state) => {
      state.industryResponseStatus = "failed";
    });
  },
});

export default getPublicCompanies;
