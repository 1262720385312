import { createSlice } from "@reduxjs/toolkit";

import { getJobLocationsAsync } from "./thunks";
import { JobLocationsState } from "./types";

const initialState: JobLocationsState = {
  jobLocations: [],
  jobLocationsRequestStatus: "initial",
};

export const getJobLocactionSlice = createSlice({
  name: "admin/applicants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobLocationsAsync.pending, (state) => {
      state.jobLocationsRequestStatus = "started";
    });
    builder.addCase(getJobLocationsAsync.fulfilled, (state, action) => {
      state.jobLocationsRequestStatus = "done";
      state.jobLocations = action.payload.results;
    });
    builder.addCase(getJobLocationsAsync.rejected, (state) => {
      state.jobLocationsRequestStatus = "failed";
    });
  },
});

export default getJobLocactionSlice;
