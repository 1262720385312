import React, { FC } from "react";
import { Dropdown, Space } from "antd";
import { SuperAdminTags } from "api/superadmin/tags/types";
import MenuActions from "./MenuActions";
import { MoreOutlined } from "@ant-design/icons";

type Props = {
  tag: SuperAdminTags;
};

const TagEditActionButton: FC<Props> = ({ tag }) => {
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  return (
    <>
      <Dropdown
        overlay={
          <MenuActions tag={tag} setDropdownVisible={setDropdownVisible} />
        }
        trigger={["click"]}
        placement="bottomCenter"
        visible={dropdownVisible}
        onVisibleChange={(visible) => setDropdownVisible(visible)}
      >
        <Space>
          <MoreOutlined onClick={() => setDropdownVisible(true)} />
        </Space>
      </Dropdown>
    </>
  );
};

export default TagEditActionButton;
