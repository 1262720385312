import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getJobsAsync,
  getJobTitleAsync,
  getJobDetailsAsync,
  postJobsAsync,
  updateAdminJobsAsync,
} from "./thunks";
import { JobsState } from "./types";

const initialState: JobsState = {
  jobs: [],
  job_titles: [],
  job: null,
  totalCount: 0,
  currentPage: 1,
  pageSize: 10,
  postJob: null,
  updateAdminJobs: null,
  jobTitleStatus: "initial",
  postJobsCreateStatus: "initial",
  jobsRequestStatus: "initial",
  patchAdminRequestStatus: "initial",
};

export const getJobsSlice = createSlice({
  name: "admin/jobs",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobsAsync.pending, (state) => {
      state.jobsRequestStatus = "started";
    });
    builder.addCase(getJobsAsync.fulfilled, (state, action) => {
      state.jobsRequestStatus = "done";
      state.totalCount = action.payload.count;
      state.jobs = action.payload.results;
    });
    builder.addCase(getJobDetailsAsync.fulfilled, (state, action) => {
      state.job = action.payload;
    });
    builder
      .addCase(getJobsAsync.rejected, (state) => {
        state.jobsRequestStatus = "failed";
      })
      .addCase(postJobsAsync.pending, (state) => {
        state.postJobsCreateStatus = "started";
      })
      .addCase(postJobsAsync.fulfilled, (state, action) => {
        state.postJobsCreateStatus = "done";
        state.postJob = action.payload;
      })
      .addCase(postJobsAsync.rejected, (state) => {
        state.postJobsCreateStatus = "failed";
      });
    builder.addCase(updateAdminJobsAsync.pending, (state) => {
      state.patchAdminRequestStatus = "started";
    });
    builder.addCase(updateAdminJobsAsync.fulfilled, (state, action) => {
      state.patchAdminRequestStatus = "done";
      state.jobs = state.jobs.map((item) => {
        if (item.id === action.payload.results.id) {
          return action.payload.results;
        }
        return item;
      });
    });
    builder.addCase(updateAdminJobsAsync.rejected, (state) => {
      state.patchAdminRequestStatus = "failed";
    });
    builder.addCase(getJobTitleAsync.pending, (state) => {
      state.jobTitleStatus = "started";
    });
    builder.addCase(getJobTitleAsync.fulfilled, (state, action) => {
      state.jobTitleStatus = "done";
      state.job_titles = action.payload.results;
    });
    builder.addCase(getJobTitleAsync.rejected, (state) => {
      state.jobTitleStatus = "failed";
    });
  },
});

export default getJobsSlice;
