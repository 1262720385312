import React from "react";
import { SuperAdminJobs } from "api/superadmin/jobs/types";
import { dateTimeGenerator } from "shared/helpers/helperFunctions";
import ActionButton from "./ActionButton";
import { JOB_PUBLISH_STATUS_TAG_PROPS, JOB_TYPES_TAG_PROPS } from "./constants";
import { jobDataType } from "./types";

export const transformSuperAdminJobsToTableData = (
  jobs: SuperAdminJobs[]
): jobDataType[] => {
  return jobs.map((job) => ({
    key: job.id.toString(),
    company_name: {
      text: job.company_name,
    },
    job_title: {
      text: job.job_title,
    },
    job_location: {
      text: job.job_location,
    },
    job_category: {
      text: job.job_category.name,
    },
    job_type: {
      tags: [
        {
          name: JOB_TYPES_TAG_PROPS[job.job_type].name?.replace("_", " "),
          color: JOB_TYPES_TAG_PROPS[job.job_type].color,
        },
      ],
    },
    publish_date: {
      text: `${dateTimeGenerator(job.publish_date)}`,
    },
    publish_status: {
      tags: [
        {
          name: JOB_PUBLISH_STATUS_TAG_PROPS[job.publish_status].name,
          color: JOB_PUBLISH_STATUS_TAG_PROPS[job.publish_status].color,
        },
      ],
    },
    three_dot_btn: {
      buttons: [
        {
          icon: <ActionButton job={job} />,
          onClick: () => {},
        },
      ],
    },
  }));
};
