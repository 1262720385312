import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProfilePercentage } from "api/admin/Dashboard/profile-percentage";


export const getProfilePercentageAsync = createAsyncThunk(
  "admin/dashboard/getProfilePercentage",
  async () => {
    const response = await getProfilePercentage();
    return response;
  }
);
