import Button from "components/Button";
import React, { FC, useEffect, useState } from "react";
import styles from "./jobs.module.scss";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import TableHeader from "uicontainers/_shared/TableHeader";
import MasterTable from "components/tables/MasterTable";
import { COLUMNS } from "./constants";
import {
  useAppSelector,
  useAppDispatch,
  useUnwrapAsyncThunk,
} from "store/hooks";

import {
  selectTotalCount,
  selectCurrentPage,
  createAdminJobsTableData,
  selectPageSize,
} from "./selectors";

import { OptionType } from "./types";
import {
  getJobsAsync,
  setCurrentPage,
  setPageSize,
} from "store/admin/jobs/actions";
import { ROUTES } from "shared/routes/constants";
import { superAdminJobs } from "store/superadmin/jobs/selectors";
import { getSuperAdminJobsCategoryAsync } from "store/superadmin/jobs/actions";
import { PaginationProps } from "antd";

const Jobs: FC = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getSuperAdminJobsCategoryAsync());
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedSecondValue, setSelectedSecondValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const pageSize = useAppSelector(selectPageSize);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSecondSelect = (value: string) => {
    setSelectedSecondValue(value);
    dispatch(setCurrentPage(1));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRangeChange = (_: any, dateStr: any) => {
    setStartDate(dateStr[0]);
    setEndDate(dateStr[1]);
    dispatch(setCurrentPage(1));
  };

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };
  const data = useAppSelector(superAdminJobs).superadmin_jobs_categories;

  const options: OptionType[] = data.map((job) => ({
    name: job.name,
    value: job.id.toString(),
  }));
  const extraOptions: OptionType[] = [
    { name: "FULL TIME", value: "FULL_TIME" },
    { name: "PART TIME", value: "PART_TIME" },
    { name: "CONTRACT", value: "CONTRACT" },
    { name: "FREELANCING", value: "FREELANCING" },
  ];
  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, job_category: selectedValue };
    }
    if (selectedSecondValue) {
      searchParams = {
        ...searchParams,
        job_type: selectedSecondValue,
      };
    }
    if (startDate) {
      searchParams = { ...searchParams, start_date: startDate };
    }
    if (endDate) {
      searchParams = { ...searchParams, end_date: endDate };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    if (pageSize) {
      searchParams = { ...searchParams, page_size: pageSize };
    }
    dispatch(getJobsAsync(searchParams));
  }, [
    searchValue,
    selectedValue,
    selectedSecondValue,
    currentPage,
    pageSize,
    getJobsAsync,
    startDate,
    endDate,
    startDate,
  ]);

  const tableData = useAppSelector(createAdminJobsTableData);

  const button = (
    <Button
      onClick={() => navigate(ROUTES.PATHS.ADMIN.ADMIN_CREATE_JOBS)}
      type="primary"
      isBordered={true}
      icon={<PlusOutlined />}
      className={styles.buttonIcon}
    >
      <span className={styles.buttonText}>Create Job</span>
    </Button>
  );

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    currentPage,
    pageSize
  ) => {
    dispatch(setCurrentPage(currentPage));
    dispatch(setPageSize(pageSize));
  };

  return (
    <div className={styles.root}>
      <>
        <TableHeader
          button={button}
          title="Jobs"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          onSecondSelectChange={handleSecondSelect}
          dropdownTitle="Job Type"
          options={options}
          onChange={handleRangeChange}
          extraOptions={extraOptions}
          placeholder="Job Category"
          extraPlaceholder="Job Type"
        />
        <MasterTable
          data={[...tableData]}
          columns={COLUMNS}
          pagination={{
            currentPage: currentPage,
            count: totalCount,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            onChange,
          }}
        />
      </>
    </div>
  );
};

export default Jobs;
