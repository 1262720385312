import React, { Suspense, useEffect, useState } from "react";
import { Typography } from "antd";
import styles from "./homeJob.module.scss";
import Button from "components/Button";
import { useUnwrapAsyncThunk, useAppSelector } from "store/hooks";
import { getJobsAsync, getMoreJobsAsync } from "store/public/jobs/thunks";
import {
  selectPublicJobs,
  selectNextPage,
  selectCurrentPage,
} from "store/public/jobs/selectors";
import SelectInput from "components/SelectInput";
import classNames from "classnames";
import Input from "components/Input";
import { OptionType } from "../HomeJob/type";
import { getJobLocationsAsync } from "store/admin/jobLocations/thunks";
import { jobLocations } from "store/admin/jobLocations/selectors";
import { getSuperAdminJobsCategoryAsync } from "store/superadmin/jobs/thunks";
import { superAdminJobs } from "store/superadmin/jobs/selectors";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "store/public/jobs/actions";
import { useLocation } from "react-router-dom";
import { LocationStateType } from "./type";
import Checkbox from "components/CheckBox";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
const { Title } = Typography;

const JobsCard = React.lazy(() => import("components/jobsCard"));

const JobList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [enteredSearchValue, setEnteredSearchValue] = useState<string>("");
  const [location, setLocation] = useState<string | undefined>(undefined);
  const [category, setCategory] = useState<string | undefined>(undefined);
  const [jobType, setJobType] = useState<string | undefined>(undefined);
  const [isRemote, setIsRemote] = useState<boolean | undefined>(undefined);
  const unwrap = useUnwrapAsyncThunk();
  const pathLocation = useLocation();
  const state = pathLocation.state as LocationStateType;

  useEffect(() => {
    unwrap(getSuperAdminJobsCategoryAsync());
    unwrap(getJobLocationsAsync());
    if (state && !state.categoryId) {
      unwrap(getJobsAsync());
    }
  }, []);

  useEffect(() => {
    dispatch(
      getJobsAsync({
        search: searchValue,
        job_location: location,
        job_category: state?.categoryId,
        job_type: jobType,
        is_remote: isRemote,
      })
    );
    setCategory(state?.categoryId);
  }, [state?.categoryId]);

  const dispatch = useDispatch();
  const jobs = useAppSelector(selectPublicJobs).jobs;
  const jobLocation = useAppSelector(jobLocations).jobLocations;
  const jobCategories = useAppSelector(superAdminJobs)
    .superadmin_jobs_categories;
  const jobLocationOptions: OptionType[] = jobLocation.map((location) => ({
    name: location.location,
    value: location.location,
  }));
  const jobCategoryOptions: OptionType[] = jobCategories.map((category) => ({
    name: category.name,
    value: category.id.toString(),
  }));
  const jobTypeOptions: OptionType[] = [
    { name: "FULL TIME", value: "FULL_TIME" },
    { name: "PART TIME", value: "PART_TIME" },
    { name: "CONTRACT", value: "CONTRACT" },
    { name: "FREELANCING", value: "FREELANCING" },
  ];

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      clearTimeout(searchTimeout);
      dispatch(
        getJobsAsync({
          search: e.currentTarget.value,
        })
      );
      setEnteredSearchValue(searchValue);
    }
  };

  let currentPage = useAppSelector(selectCurrentPage);
  const nextPage = useAppSelector(selectNextPage);
  const loadMoreJobClickHandler = () => {
    if (nextPage) {
      currentPage = currentPage + 1;
      dispatch(setCurrentPage(currentPage));
      dispatch(
        getMoreJobsAsync({
          search: searchValue,
          job_location: location,
          job_category: category,
          job_type: jobType,
          is_remote: isRemote,
          page: currentPage,
        })
      );
    }
  };

  const handleIsRemote = (e: CheckboxChangeEvent) => {
    let isRemoteJob: boolean | undefined = e.target.checked;
    if (isRemoteJob) {
      setIsRemote(isRemoteJob);
    } else {
      isRemoteJob = undefined;
      setIsRemote(isRemoteJob);
    }
    dispatch(
      getJobsAsync({
        search: searchValue,
        job_location: location,
        job_category: category,
        job_type: jobType,
        is_remote: isRemoteJob,
      })
    );
  };

  let searchTimeout: NodeJS.Timeout;
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    clearTimeout(searchTimeout);
    setTimeout(() => {
      dispatch(
        getJobsAsync({
          search: e.target.value,
        })
      );
      setEnteredSearchValue(e.target.value);
    }, 500);
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Title level={3} className={styles.mainTitle}>
          Find good work or reach GoodPeople in Japan
        </Title>
        <p className={styles.text}>
          Discover jobs or contracts in or from Japan | Reach GoodPeople for
          your organization.
        </p>
        <div className={styles.buttonContainer}>
          <Input
            placeholder="Search"
            onKeyPress={handleKeyPress}
            onChange={handleSearch}
            // value={searchValue}
          />
          <SelectInput
            bordered={false}
            placeholder="Location"
            defaultValue="Location"
            options={jobLocationOptions}
            className={classNames({
              [styles.fromContainerSelect]: true,
            })}
            onChange={(value: string) => {
              setLocation(value);
              dispatch(
                getJobsAsync({
                  search: searchValue,
                  job_location: value,
                  job_category: category,
                  job_type: jobType,
                  is_remote: isRemote,
                })
              );
            }}
            style={{ width: "120px" }}
          ></SelectInput>
          <SelectInput
            bordered={false}
            placeholder="Category"
            defaultValue={state?.categoryName || "Category"}
            options={jobCategoryOptions}
            className={classNames({
              [styles.fromContainerSelect]: true,
            })}
            onChange={(value: string) => {
              setCategory(value);
              dispatch(
                getJobsAsync({
                  search: searchValue,
                  job_location: location,
                  job_category: value,
                  job_type: jobType,
                  is_remote: isRemote,
                })
              );
            }}
            style={{ width: "140px" }}
          ></SelectInput>
          <SelectInput
            bordered={false}
            placeholder="Job Type"
            defaultValue="Job Type"
            options={jobTypeOptions}
            className={classNames({
              [styles.fromContainerSelect]: true,
            })}
            onChange={(value: string) => {
              setJobType(value);
              dispatch(
                getJobsAsync({
                  search: searchValue,
                  job_location: location,
                  job_category: category,
                  job_type: value,
                  is_remote: isRemote,
                })
              );
            }}
            dropdownStyle={{ width: "140px" }}
            style={{ width: "140px" }}
          ></SelectInput>
          <Checkbox
            className={styles.checkboxText}
            onChange={handleIsRemote}
            // checked={isRemote}
          >
            Remote
          </Checkbox>
          {/* <Button
            type="primary"
            isBordered={true}
            className={styles.buttonTag}
            icon={
              <img
                src={peopleIcon}
                alt="filter"
                className={styles.filterIcon}
              />
            }
            onClick={handleFindJob}
          >
            Apply Filters
          </Button> */}
        </div>
        {jobs?.map((job) => (
          <Suspense fallback={"Loading..."} key={job.id}>
            <JobsCard job={job} key={job.id} />
          </Suspense>
        ))}
        {jobs.length < 1 && (
          <>
            <h1 className={styles.noJobTitle}>
              No results found for your search ("{enteredSearchValue}")
            </h1>
            <h4 className={styles.noJobSubTitle}>
              Try again or tell us what you're looking for{" "}
              <a href="https://www.businessinjapan.com/contact">here</a>
            </h4>
          </>
        )}
        {nextPage && (
          <div className={styles.buttonDiv}>
            <Button
              type="primary"
              onClick={loadMoreJobClickHandler}
              danger
              shape="round"
              className={styles.homeButton}
            >
              Load more jobs
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobList;
