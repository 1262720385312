import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Menu } from "antd";
import classNames from "classnames";
import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import avatar from "../../../assets/images/avatar.svg";
import { ACCESS_TOKEN_NAME, USER_ID, COMPANY_ID } from "api/base/constants";
import { useIsMobile, useIsTab } from "shared/hooks";
// import { ROUTES } from 'shared/routes/constants';
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from "store/hooks";
import {
  getProfileAsync,
  setIsAuthenticated,
  setUserData,
} from "store/shared/auth/actions";
import { getProfileData, selectLoginData } from "store/shared/auth/selectors";

import styles from "./profile.module.scss";
import { Props } from "./types";
import { ROUTES } from "shared/routes/constants";
import Image from "components/Image/Image";

export const ProfileMenu: FC<Props> = (props) => {
  const { isLoggedIn } = props;
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const navigate = useNavigate();
  const loginData = useAppSelector(selectLoginData);
  const userName = `${loginData?.first_name}`;
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getProfileAsync());
  }, []);
  const p_data = useAppSelector(getProfileData)[0];

  // @ts-ignore
  const handleClick = (info: MenuInfo) => {
    if (info.key === "superadminProfile") {
      navigate(ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_PROFILE);
    }
    if (info.key === "profile") {
      navigate(ROUTES.PATHS.ADMIN.ADMIN_PROFILE);
    }
    if (info.key === "jobs") {
      navigate(ROUTES.PATHS.PUBLIC.JOBS);
    }
    if (info.key === "companies") {
      navigate(ROUTES.PATHS.PUBLIC.COMPANIES);
    }
    if (info.key === "changePassword") {
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      dispatch(setIsAuthenticated(false));
      dispatch(setUserData(undefined));
      navigate("/forgot-password");
    }
    if (info.key === "logOut") {
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      localStorage.removeItem(USER_ID);
      localStorage.removeItem(COMPANY_ID);
      dispatch(setIsAuthenticated(false));
      dispatch(setUserData(undefined));
      navigate("/login");
    }
  };
  const menu = (
    <Menu onClick={handleClick}>
      {loginData && loginData.is_superuser && (
        <Menu.Item key="superadminProfile">User Profile</Menu.Item>
      )}
      {loginData && !loginData.is_superuser && (
        <Menu.Item key="profile">User Profile</Menu.Item>
      )}
      <Menu.Item key="changePassword">Change Password</Menu.Item>
      {isTab && (
        <>
          <Menu.Divider />
          <Menu.Item key="jobs">Jobs</Menu.Item>
          <Menu.Item key="companies">Companies</Menu.Item>
        </>
      )}
      <Menu.Divider />
      <Menu.Item key="logOut">Log out</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <div
        className={classNames({
          [styles.container]: true,
          [styles["is-mobile"]]: isMobile,
        })}
        onClick={() => {}}
      >
        <div className={styles.avatar}>
          {p_data && p_data?.profile_picture ? (
            <Image
              src={
                p_data?.profile_picture === null ? "" : p_data?.profile_picture
              }
              defaultSrc={avatar}
              alt={"profile picture"}
              style={styles.avatarImage}
            />
          ) : (
            <Avatar size={32} icon={<UserOutlined />} />
          )}
          {!isMobile && (
            <span className={styles.userName}>
              {p_data && p_data?.user_name}
            </span>
          )}
        </div>
        {!isMobile && isLoggedIn ? (
          <div className={styles.userName}>{userName}</div>
        ) : (
          !isMobile && <div className={styles.userName}>Sign up</div>
        )}
        <div className={styles.downIcon}>
          <DownOutlined />
        </div>
      </div>
    </Dropdown>
  );
};

export default ProfileMenu;
