import { combineReducers as _, configureStore } from "@reduxjs/toolkit";
import jobsReducer from "./public/jobs/reducer";
import superAdminQueriesReducer from "./superadmin/queries/reducer";
import superAdminCompaniesReducer from "./superadmin/companies/reducer";
import superAdminReducer from "./superadmin/applicants/reducer";
import superAdminTagsReducer from "./superadmin/tags/reducer";
import adminJobsReducer from "./admin/jobs/reducer";
import quriesReducer from "./admin/queries/reducer";
import applicantsReducer from "./admin/applicants/reducer";
import summaryReducer from "./admin/Dashboard/summary/reducer";
import recentApplicantsReducer from "./admin/Dashboard/recent-applicants/reducer";
import superAdminJobsReducer from "./superadmin/jobs/reducer";
import authReducer from "./shared/auth/reducer";
import recentQueriesReducer from "./admin/Dashboard/recent-queries/reducer";
import superadminRecentApplicantsReducer from "./superadmin/Dashboard/recent-applicants/reducer";
import recentJobPostsReducer from "./superadmin/Dashboard/recent-job-posts/reducer";
import superAdminSummaryReducer from "./superadmin/Dashboard/summary/reducer";
import profilePercentageReducer from "./admin/Dashboard/profile-percentage/reducer";
import applicantsChartsReducer from "./admin/Dashboard/job-applications-chart/reducer";
import applyNowReducer from "./public/apply-now/reducer";
import adminJobLocationsReducer from "./admin/jobLocations/reducer";
import PublicCompanieeReducer from "./public/companies/reducer";
import askMoreReducer from "./public/ask-more/reducer";
import superAdminApplicantsReducer from "./superadmin/Dashboard/job-applications-chart/reducer";
import AdminChartCategory from "../uicontainers/Admin/Dashboard/JobApplications/reducer";
import SuperAdminChartCategory from "../uicontainers/SuperAdmin/Dashboard/JobApplications/reducer";
import superAdminPersonalInformation from "../uicontainers/SuperAdmin/Account/reducer";
import adminPersonalInformation from "../uicontainers/Admin/Account/reducer";
import sidebarReducer from "./shared/sidebar/reducer";

const rootReducer = _({
  resources: _({
    public: _({
      jobs: jobsReducer,
      companies: PublicCompanieeReducer,
      applyNow: applyNowReducer,
      askMore: askMoreReducer,
    }),
    superAdmin: _({
      superAdminJobs: superAdminJobsReducer,
      superAdminCompanies: superAdminCompaniesReducer,
      superAdminApplicants: superAdminReducer,
      superAdminQueries: superAdminQueriesReducer,
      superAdminRecentApplicants: superadminRecentApplicantsReducer,
      superAdminRecentJobs: recentJobPostsReducer,
      superAdminSummary: superAdminSummaryReducer,
      superAdminApplicantsCharts: superAdminApplicantsReducer,
      superAdminChartCategory: SuperAdminChartCategory,
      superAdminPersonal: superAdminPersonalInformation,
      superAdminTags: superAdminTagsReducer,
    }),
    admin: _({
      adminJobs: adminJobsReducer,
      queries: quriesReducer,
      applicants: applicantsReducer,
      recentApplicants: recentApplicantsReducer,
      summary: summaryReducer,
      profilePercentage: profilePercentageReducer,
      recentQueries: recentQueriesReducer,
      applicantsCharts: applicantsChartsReducer,
      adminChartCategory: AdminChartCategory,
      adminJobLocations: adminJobLocationsReducer,
      adminPersonal: adminPersonalInformation,
    }),
  }),
  ui: _({
    auth: authReducer,
    sidebar: sidebarReducer,
  }),
});

export const store = configureStore({
  reducer: rootReducer,
});
