import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSummary } from "api/admin/Dashboard/summary";

export const getSummaryAsync = createAsyncThunk(
  "admin/summary/get_summary",
  async () => {
    const response = await getSummary();
    return response;
  }
);
