import { createAsyncThunk } from "@reduxjs/toolkit";
import { getGetSuperAdminRecentJobPosts } from "api/superadmin/Dashboard/recent-job-posts";

export const getSuperAdminRecentJobPostsAsync = createAsyncThunk(
  "superadmin/dashbaord/recent-job-posts",
  async () => {
    const response = await getGetSuperAdminRecentJobPosts();
    return response;
  }
);
