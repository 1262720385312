import { superadminAPI } from "api/base";

import {
  GetSuperAdminJobs,
  GetSuperAdminmJobsCategory,
  GetSuperAdminmJobsTitle,
  UpdateSuperAdminJobs,
} from "./types";

export const getSuperAdminJobs: GetSuperAdminJobs = (params) => {
  return superadminAPI.get("/jobs/", { params });
};

export const getSuperAdminJobsCategory: GetSuperAdminmJobsCategory = () => {
  return superadminAPI.get("/jobcategories/");
};

export const getSuperAdminJobsTitle: GetSuperAdminmJobsTitle = (id) => {
  return superadminAPI.get(`/jobs-title/${id}/`);
};

export const updateSuperAdminJobs: UpdateSuperAdminJobs = (data, id) => {
  return superadminAPI.patch(`/jobs/${id}/`, data);
};
