import jwt_decode from "jwt-decode";
import { ACCESS_TOKEN_NAME } from "api/base/constants";
import { UserType } from "shared/constants/user";

interface TokenProperty {
  user_id?: number;
  email?: string;
  is_superuser?: boolean;
  user_type?: string;
}

export const isAdminUser = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  if (token) {
    const decodedToken = jwt_decode<TokenProperty>(token);
    return decodedToken["user_type"] === UserType.COMPANY_ADMIN;
  }
  return false;
};

export const isSuperUser = () => {
  const token = localStorage.getItem(ACCESS_TOKEN_NAME);
  if (token) {
    const decodedToken = jwt_decode<TokenProperty>(token);
    return decodedToken["is_superuser"];
  }
  return false;
};
