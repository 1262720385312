import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSuperAdminQueries } from "api/superadmin/queries";
import { SuperAdminQueriesRequestParams } from "api/superadmin/queries/types";

export const getSuperAdminQueriesAsync = createAsyncThunk(
  "superadmin/getQueries",
  async (params?: SuperAdminQueriesRequestParams) => {
    const response = await getSuperAdminQueries(params);
    return response;
  }
);
