import { createAsyncThunk } from "@reduxjs/toolkit";
import { getJobApplicantionsChart } from "api/admin/Dashboard/job-applications-chart";

export const getRecentApplicantsChartAsync = createAsyncThunk(
  "admin/recent-applicant-chart",
  async () => {
    const response = await getJobApplicantionsChart();
    return response;
  }
);
