import { adminAPI } from "api/base";

import { GetQueries, UpdateQueriesReply } from "./types";

export const getQueries: GetQueries = (params) => {
  return adminAPI.get("/queries/", { params });
};

export const updateQueriesReply: UpdateQueriesReply = (data, id) => {
  return adminAPI.patch(`/queries-reply/${id}/`, data);
};
