import {
  formatHTTPErrorMessage,
  notification,
} from "shared/helpers/notifications";
import { store } from "store";
import { setIsAuthenticated, setIsNewAccount } from "store/shared/auth/actions";
import { ACCESS_TOKEN_NAME } from "./constants";

import { axiosCreate } from "./helpers";
import {
  InterceptErrorResponse,
  InterceptSuccessRequest,
  InterceptSuccessResponse,
} from "./types";

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const interceptSuccessRequest: InterceptSuccessRequest = (request) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

  if (accessToken && request.headers) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
};

export const interceptSuccessResponse: InterceptSuccessResponse = (
  response
) => {
  const txtMatch = new RegExp("/agreements/[0-9]?");
  if (response.config.url) {
    if (txtMatch.test(response.config.url)) {
      return response.data;
    }
  }
  if (response?.data.status) {
    return response.data.success_data;
  }
  return {};
};

export const interceptErrorResponse: InterceptErrorResponse = (error) => {
  if (
    error?.response?.status === 401 &&
    error?.response?.data?.error_data?.code === "token_not_valid"
  ) {
    store.dispatch(setIsAuthenticated(false));
  } else if (
    error?.response?.status === 401 &&
    error?.response?.data?.error_data?.error_code === "UNREVIEWED_ACCOUNT"
  ) {
    store.dispatch(setIsNewAccount(true));
  } else {
    notification({
      type: "error",
      title: "Error occurred",
      description: formatHTTPErrorMessage(error),
    });
  }
  return Promise.reject(error);
};

const authAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/users`,
})(interceptSuccessResponse, interceptErrorResponse);

const publicJobsAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/public`,
})(interceptSuccessResponse, interceptErrorResponse);

const superadminAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/platform`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

const adminAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/admin`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

const silentAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/users`,
})(interceptSuccessResponse);

const profileAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/users`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

export {
  authAPI,
  publicJobsAPI,
  superadminAPI,
  silentAPI,
  adminAPI,
  profileAPI,
};
