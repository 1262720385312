import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACCESS_TOKEN_NAME, USER_ID, COMPANY_ID } from "api/base/constants";
import { RequestStatus } from "api/base/types";
import { AuthenticationData } from "api/shared/auth/types";

import {
  activateAccountAsync,
  getProfileAsync,
  getCompanyProfileAsync,
  loginAsync,
  passwordResetRequestAsync,
  resetPasswordAsync,
  signupAsync,
  verifyTokenAsync,
} from "./thunks";
import { AuthState } from "./types";

const initialState: AuthState = {
  loginData: undefined,
  loginStatus: "initial",
  isAuthenticated: false,
  isNewAccount: false,
  signupStatus: "initial",
  forgotPasswordStatus: "initial",
  resetPasswordStatus: "initial",
  activateAccountStatus: "initial",
  verifyTokenStatus: "started",
  getProfileRequestStatus: "initial",
  getCompanyProfileRequestStatus: "initial",
  getProfileDetails: [],
  getCompanyProfileDetails: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAccessToken(_, action: PayloadAction<string>) {
      localStorage.setItem(ACCESS_TOKEN_NAME, action.payload);
    },
    setUserData(state, action: PayloadAction<AuthenticationData | undefined>) {
      state.loginData = action.payload;
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setIsNewAccount(state, action: PayloadAction<boolean>) {
      state.isNewAccount = action.payload;
    },
    setSignupStatus(state, action: PayloadAction<RequestStatus>) {
      state.signupStatus = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loginStatus = "started";
        state.isAuthenticated = false;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loginStatus = "done";
        state.loginData = action.payload.results;
        state.isAuthenticated = true;
        localStorage.setItem(ACCESS_TOKEN_NAME, action.payload.results.access);
        localStorage.setItem(USER_ID, action.payload.results.user_id);
        if (action.payload.results.company_id) {
          localStorage.setItem(COMPANY_ID, action.payload.results.company_id);
        }
      })
      .addCase(loginAsync.rejected, (state) => {
        state.loginStatus = "failed";
        state.loginData = undefined;
        state.isAuthenticated = false;
      })
      .addCase(signupAsync.pending, (state) => {
        state.signupStatus = "started";
      })
      .addCase(signupAsync.fulfilled, (state) => {
        state.signupStatus = "done";
      })
      .addCase(signupAsync.rejected, (state) => {
        state.signupStatus = "failed";
      })
      .addCase(passwordResetRequestAsync.pending, (state) => {
        state.forgotPasswordStatus = "started";
      })
      .addCase(passwordResetRequestAsync.fulfilled, (state) => {
        state.forgotPasswordStatus = "done";
      })
      .addCase(passwordResetRequestAsync.rejected, (state) => {
        state.forgotPasswordStatus = "failed";
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.resetPasswordStatus = "started";
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.resetPasswordStatus = "done";
      })
      .addCase(resetPasswordAsync.rejected, (state) => {
        state.resetPasswordStatus = "failed";
      })
      .addCase(activateAccountAsync.pending, (state) => {
        state.activateAccountStatus = "started";
      })
      .addCase(activateAccountAsync.fulfilled, (state) => {
        state.activateAccountStatus = "done";
      })
      .addCase(activateAccountAsync.rejected, (state) => {
        state.activateAccountStatus = "failed";
      })
      .addCase(verifyTokenAsync.fulfilled, (state, action) => {
        if (action.payload.results) {
          state.loginData = action.payload.results;
          state.isAuthenticated = true;
        } else {
          state.loginData = undefined;
          state.isAuthenticated = false;
        }
        state.verifyTokenStatus = "done";
      })
      .addCase(verifyTokenAsync.rejected, (state) => {
        state.loginData = undefined;
        state.isAuthenticated = false;
        state.verifyTokenStatus = "failed";
      });
    builder.addCase(getProfileAsync.pending, (state) => {
      state.getProfileRequestStatus = "started";
    });
    builder.addCase(getProfileAsync.fulfilled, (state, action) => {
      state.getProfileDetails = action.payload.results;
      state.getProfileRequestStatus = "done";
    });
    builder.addCase(getProfileAsync.rejected, (state) => {
      state.getProfileRequestStatus = "failed";
    });
    builder.addCase(getCompanyProfileAsync.pending, (state) => {
      state.getCompanyProfileRequestStatus = "started";
    });
    builder.addCase(getCompanyProfileAsync.fulfilled, (state, action) => {
      state.getCompanyProfileDetails = action.payload.results;
      state.getCompanyProfileRequestStatus = "done";
    });
    builder.addCase(getCompanyProfileAsync.rejected, (state) => {
      state.getCompanyProfileRequestStatus = "failed";
    });
  },
});

export default authSlice;
