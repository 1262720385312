import { Applicants } from "api/admin/applicants/types";
import { dateTimeGenerator } from "shared/helpers/helperFunctions";
import { AdminApplicantsStae } from "./types";

export const transformAdminApplicantsToTableData = (
  applicants: Applicants[]
): AdminApplicantsStae[] => {
  return applicants.map((applicant) => ({
    key: applicant.id.toString(),
    full_name: {
      text: applicant.full_name,
    },
    email: {
      text: applicant.email,
    },
    job_title: {
      text: applicant.job_title,
    },
    created_at: {
      text: `${dateTimeGenerator(applicant.created_at)}`,
    },
  }));
};
