import { superadminAPI } from "api/base";
import {
  GetSuperAdminTags,
  GetSuperAdminJobTags,
  PostSuperAdminTag,
  PutSuperAdminTag,
} from "./types";

export const getSuperAdminTags: GetSuperAdminTags = (params) => {
  return superadminAPI.get("/jobtags/", { params });
};

export const getSuperAdminJobTags: GetSuperAdminJobTags = () => {
  return superadminAPI.get("/admin-jobtags/");
};

export const postSuperAdminTags: PostSuperAdminTag = (data) => {
  return superadminAPI.post("/jobtags/", data);
};

export const putSuperAdminTags: PutSuperAdminTag = (tagId, data) => {
  return superadminAPI.put(`/jobtags/${tagId}/`, data);
};

export const deleteSuperAdminTags = (tagId: number) => {
  return superadminAPI.delete(`/jobtags/${tagId}/`);
};
