import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getSuperAdminJobsAsync,
  getSuperAdminJobsCategoryAsync,
  getSuperAdminJobsTitleAsync,
  updateSuperAdminJobsAsync,
} from "./thunks";
import { SuperAdminJobsState } from "./types";

const initialState: SuperAdminJobsState = {
  superadmin_jobs: [],
  superadmin_jobs_categories: [],
  superadmin_jobs_title: [],
  updateSuperAdminJobs: null,
  totalCount: 0,
  pageSize: 10,
  currentPage: 1,
  superadmin_jobsRequestStatus: "initial",
  superadmin_jobsCategoryRequestStatus: "initial",
  superadminJobsTitleRequestStatus: "initial",
  patchSuperAdminRequestStatus: "initial",
};

export const getSuperAdminJobsSlice = createSlice({
  name: "superadmin/jobs",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setSuperadminJobTitleToEmpty(state) {
      state.superadmin_jobs_title = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSuperAdminJobsAsync.pending, (state) => {
      state.superadmin_jobsRequestStatus = "started";
    });
    builder.addCase(getSuperAdminJobsAsync.fulfilled, (state, action) => {
      state.superadmin_jobsRequestStatus = "done";
      state.totalCount = action.payload.count;
      state.superadmin_jobs = action.payload.results;
    });
    builder.addCase(getSuperAdminJobsAsync.rejected, (state) => {
      state.superadmin_jobsRequestStatus = "failed";
    });
    builder.addCase(getSuperAdminJobsCategoryAsync.pending, (state) => {
      state.superadmin_jobsRequestStatus = "started";
    });
    builder.addCase(
      getSuperAdminJobsCategoryAsync.fulfilled,
      (state, action) => {
        state.superadmin_jobsRequestStatus = "done";
        state.superadmin_jobs_categories = action.payload.results;
      }
    );
    builder.addCase(getSuperAdminJobsCategoryAsync.rejected, (state) => {
      state.superadmin_jobsRequestStatus = "failed";
    });
    builder.addCase(updateSuperAdminJobsAsync.pending, (state) => {
      state.patchSuperAdminRequestStatus = "started";
    });
    builder.addCase(updateSuperAdminJobsAsync.fulfilled, (state, action) => {
      state.patchSuperAdminRequestStatus = "done";
      state.superadmin_jobs = state.superadmin_jobs.map((item) => {
        if (item.id === action.payload.results.id) {
          return action.payload.results;
        }
        return item;
      });
    });
    builder.addCase(updateSuperAdminJobsAsync.rejected, (state) => {
      state.patchSuperAdminRequestStatus = "failed";
    });
    builder.addCase(getSuperAdminJobsTitleAsync.pending, (state) => {
      state.superadminJobsTitleRequestStatus = "started";
    });
    builder.addCase(getSuperAdminJobsTitleAsync.fulfilled, (state, action) => {
      state.superadminJobsTitleRequestStatus = "done";
      state.superadmin_jobs_title = action.payload.results;
    });
    builder.addCase(getSuperAdminJobsTitleAsync.rejected, (state) => {
      state.superadminJobsTitleRequestStatus = "failed";
    });
  },
});

export default getSuperAdminJobsSlice;
