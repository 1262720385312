import { Spin } from "antd";
import MasterTable from "components/tables/MasterTable";
import React, { useEffect } from "react";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import { superAdminRecentJobs } from "store/superadmin/Dashboard/recent-job-posts/selectors";
import { getSuperAdminRecentJobPostsAsync } from "store/superadmin/Dashboard/recent-job-posts/thunks";
import Card from "uicontainers/_shared/Card";
import { COLUMNS } from "./constants";
import { transformRecentJobsPostsToTableData } from "./helpers";
const RecenetJobPosts = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getSuperAdminRecentJobPostsAsync());
  }, []);

  const tableData = transformRecentJobsPostsToTableData(
    useAppSelector(superAdminRecentJobs).superadminRecentJobPosts
  );
  return (
    <Spin spinning={false}>
      <Card title="Recent Job Posts" hasPadding={false}>
        <MasterTable data={[...tableData]} columns={COLUMNS} />
      </Card>
    </Spin>
  );
};

export default RecenetJobPosts;
