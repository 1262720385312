import React, { useEffect, useState, Suspense } from "react";
import { Col, Row, Typography, Tag, Input } from "antd";
import styles from "./homeJob.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import Button from "components/Button";
import classNames from "classnames";
import { useIsExtraExtraSmall, useIsMobile } from "shared/hooks";
import {
  useUnwrapAsyncThunk,
  useAppSelector,
  useAppDispatch,
} from "store/hooks";
import { selectPublicJobs } from "store/public/jobs/selectors";
import {
  getJobsAsync,
  homeOpenCompaniesAsync,
  homeOpenJobsAsync,
} from "store/public/jobs/thunks";
import { getSuperAdminJobsCategoryAsync } from "store/superadmin/jobs/thunks";
import { superAdminJobs } from "store/superadmin/jobs/selectors";
import SelectInput from "components/SelectInput";
import { OptionType } from "./type";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ROUTES } from "shared/routes/constants";
import { HomeJobsRequestParams } from "api/public/jobs/types";

const { Title } = Typography;

const JobsCard = React.lazy(() => import("components/jobsCard"));
const CompaniesCard = React.lazy(() => import("components/CompaniesCard"));
const PromotionBanner = React.lazy(() =>
  import("uicontainers/_shared/PromotionBanner")
);

const HomeJob = () => {
  const [activeFilter, setActiveFilter] = useState<HomeJobsRequestParams>({});
  const [searchValue, setSearchValue] = useState("");
  const [isAllJobFilter, setIsAllJobFilter] = useState(false);
  const [category, selectCategory] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getJobsAsync());
    unwrap(homeOpenJobsAsync());
    unwrap(homeOpenCompaniesAsync());
    unwrap(getSuperAdminJobsCategoryAsync());
  }, []);

  const jobs = useAppSelector(selectPublicJobs).jobs;
  const homeOpenJobs = useAppSelector(selectPublicJobs).homeOperJobs;
  const homeOpenCompanies = useAppSelector(selectPublicJobs).homeOpenCompanies;
  const data = useAppSelector(superAdminJobs).superadmin_jobs_categories;

  const options: OptionType[] = data.map((category) => ({
    name: category.name,
    value: category.id.toString(),
  }));
  options.unshift({ name: "All Jobs", value: "" });
  const dispatch = useAppDispatch();

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleFindJob();
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleFindJob = () => {
    dispatch(
      getJobsAsync({
        search: searchValue,
        job_category: category,
      })
    );
    setActiveFilter({});
  };

  const setActiveFilterState = (
    filterKey: keyof HomeJobsRequestParams,
    filterVal: keyof HomeJobsRequestParams[keyof HomeJobsRequestParams]
  ) => {
    isAllJobFilter && setIsAllJobFilter(false);
    searchValue && setSearchValue("");
    if (filterKey === "job_type") {
      if (activeFilter.job_type) {
        if (activeFilter.job_type?.includes(filterVal)) {
          activeFilter.job_type = activeFilter.job_type.filter(
            (val) => val !== filterVal
          );
        } else {
          activeFilter.job_type?.push(filterVal);
        }
      } else {
        activeFilter.job_type = [filterVal];
      }
    } else {
      if (filterKey in activeFilter) {
        delete activeFilter[filterKey];
      } else {
        activeFilter[filterKey] = filterVal;
      }
    }
    setActiveFilter(activeFilter);
    dispatch(getJobsAsync(activeFilter));
  };

  const allJobClickHandler = () => {
    if (!isAllJobFilter) {
      dispatch(getJobsAsync());
      setIsAllJobFilter(true);
      setActiveFilter({});
    }
  };

  const getActiveButtonStyle = (filterName: string) => {
    if (filterName === "all_job" && isAllJobFilter) {
      return { border: "1px solid #BC012D", color: "#BC012D" };
    }
    if (filterName === "FULL_TIME" || filterName === "PART_TIME") {
      if (activeFilter.job_type?.includes(filterName)) {
        return { border: "1px solid #BC012D", color: "#BC012D" };
      }
    }
    return filterName in activeFilter
      ? { border: "1px solid #BC012D", color: "#BC012D" }
      : {};
  };

  const isMobile = useIsMobile();
  const isExtraExtraSmall = useIsExtraExtraSmall();

  const getDropdownWidth = () => {
    if (isExtraExtraSmall) {
      return "92px";
    } else if (isMobile) {
      return "115px";
    }
    return "140px";
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Title level={3} className={styles.mainTitle}>
          Find good work or reach GoodPeople in Japan
        </Title>
        <p className={styles.text}>
          Discover jobs or contracts in or from Japan | Reach GoodPeople for
          your organization
        </p>
        <div className={styles.SiteInput}>
          <div className={styles.FromContainer}>
            <Input.Group compact>
              <Input
                bordered={false}
                className={classNames({
                  [styles.fromContainerInput]: true,
                  [styles["is-mobile"]]: isMobile,
                })}
                onKeyPress={handleSearchKeyPress}
                onChange={handleSearch}
                value={searchValue}
                placeholder="Job title, keywords, Company..."
              />
              <SelectInput
                bordered={false}
                placeholder="Job Category"
                defaultValue="Job Category"
                options={options}
                className={classNames({
                  [styles.fromContainerSelect]: true,
                  [styles["is-mobile"]]: isMobile,
                })}
                onChange={(value: string) => {
                  selectCategory(value);
                }}
                style={{ width: getDropdownWidth() }}
              ></SelectInput>

              <Button
                className={classNames({
                  [styles.buttonClaass]: true,
                  [styles["is-mobile"]]: isMobile,
                })}
                style={{ width: "20%" }}
                type="primary"
                shape="circle"
                icon={<SearchOutlined />}
                onClick={handleFindJob}
              >
                {isExtraExtraSmall ? "" : "Find Job"}
              </Button>
            </Input.Group>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <h4 className={styles.filterHeading}>Sort Jobs by:</h4>
          <Button
            type="primary"
            className={styles.buttonTag}
            style={getActiveButtonStyle("all_job")}
            onClick={allJobClickHandler}
          >
            All
          </Button>
          <Button
            type="primary"
            style={getActiveButtonStyle("recent_job")}
            className={styles.buttonTag}
            onClick={() => setActiveFilterState("recent_job", true as never)}
          >
            Recent
          </Button>
          <Button
            type="primary"
            className={styles.buttonTag}
            style={getActiveButtonStyle("FULL_TIME")}
            onClick={() =>
              setActiveFilterState("job_type", "FULL_TIME" as never)
            }
          >
            Full Time
          </Button>
          <Button
            type="primary"
            className={styles.buttonTag}
            style={getActiveButtonStyle("PART_TIME")}
            onClick={() =>
              setActiveFilterState("job_type", "PART_TIME" as never)
            }
          >
            Part Time
          </Button>
          <Button
            type="primary"
            className={styles.buttonTag}
            style={getActiveButtonStyle("japanese")}
            onClick={() =>
              setActiveFilterState("japanese", "REQUIRED" as never)
            }
          >
            Japanese Required
          </Button>
          <Button
            type="primary"
            className={styles.buttonTag}
            style={getActiveButtonStyle("english")}
            onClick={() => setActiveFilterState("english", "REQUIRED" as never)}
          >
            English Required
          </Button>
          <Button
            type="primary"
            className={styles.buttonTag}
            style={getActiveButtonStyle("is_remote")}
            onClick={() => setActiveFilterState("is_remote", true as never)}
          >
            Remote
          </Button>
        </div>
        <h2 className={styles.jobListHeading}>Top Ten Jobs</h2>
        {jobs?.map((job) => (
          <Suspense fallback={"Loading..."} key={job?.id}>
            <JobsCard job={job} key={job?.id} />
          </Suspense>
        ))}
        {jobs.length < 1 && (
          <h1 className={styles.noJobTitle}>No Jobs Found!</h1>
        )}
        <div className={styles.buttonDiv}>
          <Button
            type="primary"
            onClick={() => navigate("/jobslists")}
            danger
            shape="round"
            className={styles.homeButton}
          >
            View All Jobs
          </Button>
        </div>
        <div className={styles.borderStyle}></div>
        <Title level={3} className={styles.mainTitle}>
          Jobs By Category
        </Title>
        <p
          className={classNames({
            [styles.text]: true,
            [styles.italic]: true,
          })}
        >
          Find the best job in your area of expertise
        </p>

        <Row
          gutter={[{ xs: 8, sm: 8, md: 16, lg: 32 }, 10]}
          className={styles.categoryContainer}
        >
          {homeOpenJobs?.map((openJob) => (
            <Col lg={8} md={8} sm={24} xs={24} key={openJob?.id}>
              <Link
                to={{
                  pathname: ROUTES.PATHS.PUBLIC.JOB_LIST,
                }}
                state={{ categoryId: openJob.id, categoryName: openJob.name }}
              >
                <div className={styles.jobContianer}>
                  <h3>{openJob?.name}</h3>
                  <Tag className={styles.tag}>
                    {openJob?.jobs_count}{" "}
                    {openJob?.jobs_count > 1 ? "jobs" : "job"}
                  </Tag>
                </div>
              </Link>
            </Col>
          ))}
        </Row>

        <div className={styles.borderStyle}></div>
        <Title level={3} className={styles.mainTitle}>
          Top Companies with open jobs
        </Title>
        <p
          className={classNames({
            [styles.text]: true,
            [styles.italic]: true,
          })}
        >
          Opportunities from companies in or connected to Japan who are open to
          hiring non-Japanese and Japanese who are interested in working in
          roles that are English friendly
        </p>
        <Suspense fallback={"Loading..."}>
          <CompaniesCard companies={homeOpenCompanies} />
        </Suspense>
        <div className={styles.buttonDiv}>
          <Button
            type="primary"
            onClick={() => navigate("/companies")}
            danger
            shape="round"
            className={styles.homeButton}
          >
            View All Companies
          </Button>
        </div>
        <div>
          <Suspense fallback={"Loading..."}>
            <PromotionBanner />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default HomeJob;
