import { Spin } from "antd";
import MasterTable from "components/tables/MasterTable";
import React, { useEffect } from "react";
import { getRecentQueriesAsync } from "store/admin/Dashboard/recent-queries/thunks";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import Card from "uicontainers/_shared/Card";
import { COLUMNS } from "./constants";
import { transformRecentQueriesToTableData } from "./helpers";
import { adminRecentQueriesSelect } from "./selectors";
const RecentQueries = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getRecentQueriesAsync());
  }, []);

  const tableData = transformRecentQueriesToTableData(useAppSelector(adminRecentQueriesSelect));

  return (
    <Spin spinning={false}>
      <Card title="Recent Queries" hasPadding={false}>
        <MasterTable data={[...tableData]} columns={COLUMNS} />
      </Card>
    </Spin>
  );
};

export default RecentQueries;
