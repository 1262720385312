import { createSelector } from "reselect";
import { RootState } from "store/types";
import { transformSuperAdminTagsToTableData } from "uicontainers/SuperAdmin/Tags/helpers";

export const superAdminTags = (state: RootState) =>
  state.resources.superAdmin.superAdminTags.superadmin_tags;

export const selectTotalCount = (state: RootState) =>
  state.resources.superAdmin.superAdminTags.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.superAdmin.superAdminTags.currentPage;

export const selectPageSize = (state: RootState) =>
  state.resources.superAdmin.superAdminTags.pageSize;

export const createSuperUserTagsTableData = createSelector(
  superAdminTags,
  transformSuperAdminTagsToTableData
);
