import { Spin } from "antd";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "shared/routes/constants";
import { useAppSelector } from "store/hooks";
import {
  selectIsAuthenticated,
  selectVerifyTokenRequestStatus,
} from "store/shared/auth/selectors";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isCheckingToken =
    useAppSelector(selectVerifyTokenRequestStatus) === "started";

  if (isCheckingToken) {
    return <Spin spinning={true} size="large"></Spin>;
  } else {
    if (!isAuthenticated) {
      return (
        <Navigate to={ROUTES.PATHS.PUBLIC.LOGIN} state={{ from: location }} />
      );
    }

    return children;
  }
};

export default PrivateRoute;
