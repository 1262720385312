import { Divider, Row, Col } from "antd";
import React, { FC } from "react";
import { useIsMobile } from "shared/hooks";
import styles from "./footer.module.scss";
import classNames from "classnames";
import NavigationFooter from "../NavigationFooter";
import { useLocation } from "react-router-dom";
import { ROUTES } from "shared/routes/constants";

const Footer: FC = () => {
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  const getNavFooter = () => {
    if (
      pathname.includes(ROUTES.PATHS.PUBLIC.COMPANIES) ||
      pathname.includes(ROUTES.PATHS.PUBLIC.JOBS) ||
      pathname.includes(ROUTES.PATHS.PUBLIC.ROOT) ||
      pathname === ROUTES.PATHS.PUBLIC.JOB_LIST
    ) {
      return (
        <div
          className={classNames({
            [styles.navFooter]: true,
            [styles.isMobile]: isMobile,
          })}
        >
          <NavigationFooter />
        </div>
      );
    }
  };

  return (
    <>
      {getNavFooter()}
      <Divider className={styles.dividerStyle} />
      <Row justify="space-around">
        <Col xs={24} md={16} lg={16}>
          <h6
            className={classNames({
              [styles.copyRight]: true,
              [styles["is-mobile"]]: isMobile,
            })}
          >
            Copyright © {new Date().getFullYear()} Business in Japan™
          </h6>
        </Col>
        <Col
          xs={24}
          md={8}
          lg={8}
          className={classNames({
            [styles.footerContainer]: true,
            [styles["is-mobile"]]: isMobile,
          })}
        >
          <a
            href="https://www.businessinjapan.com/privacy-policy/"
            rel="noreferrer"
            target="_blank"
          >
            <h3 className={styles.footerRight}>Privacy Policy</h3>
          </a>
          <a
            href="https://www.businessinjapan.com/terms-of-service/"
            rel="noreferrer"
            target="_blank"
          >
            <h3 className={styles.footerRight}>Terms of Service</h3>
          </a>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
