import React from "react";
import { Queries } from "api/admin/queries/types";
import { dateTimeGenerator2 } from "shared/helpers/helperFunctions";
import ActionButton from "./ActionButton";
import { queriesDataState } from "./types";
import ActionButtonTwo from "./ActionButtonTwo";

export const transformAdminQueriesToTableData = (
  queries: Queries[]
): queriesDataState[] => {
  return queries.map((query) => ({
    key: query.id.toString(),
    full_name: {
      text: query.full_name,
    },
    email: {
      text: query.email,
    },
    job_title: {
      text: query.job_title,
    },
    queries: {
      text: query.queries,
    },
    created_at: {
      text: `${dateTimeGenerator2(query.created_at)}`,
    },
    three_dot_btn: {
      buttons: [
        {
          icon: <ActionButton isSuperAdmin={false} query={query} />,
          onClick: () => {},
        },
        {
          icon: <ActionButtonTwo isSuperAdmin={false} query={query} />,
          onClick: () => {},
        },
      ],
    },
  }));
};
