import { updateSuperAdminJobsAsync } from "./../../../store/superadmin/jobs/thunks";
import { useCallback } from "react";
import { message } from "antd";
import { useUnwrapAsyncThunk } from "store/hooks";

import { BasicInfo } from "./types";

export const useUpdateSuperAdminBasicInfo = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback(
    (basicInfo: BasicInfo, id: number) => {
      return unwrap(updateSuperAdminJobsAsync({ ...basicInfo, jobsId: id }))
        .then((response) => {
          message.success("Job Status Updated Successfully", 0.5);
        })
        .catch((e) => {
          // message.error(e.message);
        });
    },
    [updateSuperAdminJobsAsync]
  );
};
