import {
  AuditOutlined,
  UserOutlined,
  UserAddOutlined,
  DashboardOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import React from "react";
import { ROUTES } from "shared/routes/constants";

export const getSideBarMenu = () => [
  {
    title: "Dashboard",
    icon: <DashboardOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_DASHBOARD,
  },
  {
    title: "Jobs",
    icon: <AuditOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_JOBS,
  },
  {
    title: "Applicants",
    icon: <UserOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_JOB_APPLICATANS,
  },
  {
    title: "Queries",
    icon: <QuestionCircleOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_JOB_QUERIES,
  },
  {
    title: "User Profile",
    icon: <UserAddOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_PROFILE,
  },
];
