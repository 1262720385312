import { Row, Typography } from "antd";
import classNames from "classnames";
import React, { FC } from "react";

import DateRangePicker from "components/DateRangePicker";
import SearchInput from "components/SearchInput/SearchInput";
import SelectInput from "components/SelectInput";
import { DefaultOptionType } from "antd/lib/select";
import { FilterFunc } from "rc-select/lib/Select";

import styles from "./tableHeader.module.scss";
import { Props } from "./types";

const TableHeader: FC<Props> = (props) => {
  const {
    title,
    button,
    onSearch,
    onChange,
    onSelectChange,
    onSecondSelectChange,
    oneThirdSelectChange,
    secondExtraPlaceholder = "",
    className = "",
    options,
    extraOptions = [],
    secondExtrapOptions = [],
    placeholder = "",
    extraPlaceholder = "",
    firstShowSearch = false,
    secondShowSearch = false,
    selectedValue = "",
    cascading = false,
  } = props;
  const Title = Typography.Title;

  const filterOption: FilterFunc<DefaultOptionType> = (input, option) =>
    ((option!.children as unknown) as string)
      .toLowerCase()
      .includes(input.toLowerCase());

  return (
    <div
      className={classNames({
        [styles.tableTopContainer]: true,
        [className]: Boolean(className),
      })}
    >
      <Row className={styles.titleContainer}>
        <Title level={3}>{title}</Title>
        {button}
      </Row>
      <Row>
        <SearchInput onSearch={onSearch} />

        {options && (
          <SelectInput
            options={options}
            placeholder={placeholder}
            onChange={onSelectChange}
            className={styles.optionsStyle}
            style={{ width: "140px" }}
            showSearch={firstShowSearch}
            filterOption={filterOption}
          />
        )}
        {extraPlaceholder && (
          <SelectInput
            options={extraOptions}
            placeholder={extraPlaceholder}
            onChange={onSecondSelectChange}
            className={styles.optionsStyle}
            style={{ width: "140px" }}
            showSearch={secondShowSearch}
            filterOption={filterOption}
            disabled={cascading && !selectedValue ? true : false}
          />
        )}
        {secondExtraPlaceholder && (
          <SelectInput
            options={secondExtrapOptions}
            placeholder={secondExtraPlaceholder}
            onChange={oneThirdSelectChange}
            className={styles.optionsStyle}
            style={{ width: "140px" }}
          />
        )}
        {onChange && <DateRangePicker onChange={onChange} />}
      </Row>
    </div>
  );
};
export default TableHeader;
