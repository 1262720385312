import { createSelector } from "reselect";
import { transformSuperAdminQueriesToTableData } from "./helpers";

import { RootState } from "store/types";
export const superAdminQueriesData = (state: RootState) =>
  state.resources.superAdmin.superAdminQueries.superadmin_queries;

export const createSuperAdminQueriesTableData = createSelector(
  superAdminQueriesData,
  transformSuperAdminQueriesToTableData
);

export const selectTotalCount = (state: RootState) =>
  state.resources.superAdmin.superAdminQueries.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.superAdmin.superAdminQueries.currentPage;

export const selectPageSize = (state: RootState) =>
  state.resources.superAdmin.superAdminQueries.pageSize;
