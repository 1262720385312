import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleAskMore } from "api/public/ask-more";
import { askMore } from "api/public/ask-more/types";


export const handleAskMoreAsync = createAsyncThunk(
  "public/ask-more",
  async (data: askMore) => {
    const response = await handleAskMore(data);
    return response;
  }
);
