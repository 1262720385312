import React from "react";
import { notification as antdNotification } from "antd";
import { AxiosError } from "axios";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import isEmpty from "lodash/isEmpty";

export interface NotificationParams {
  type: "success" | "info" | "warning" | "error";
  description: string;
  title?: string;
}

export const formatValidationErrorMessage = (error: {
  errorFields: { errors: string[] }[];
}) => {
  let message = "";
  error.errorFields.forEach((e) => {
    const errors = e.errors || [];
    errors.forEach((m) => {
      message += m + "\n";
    });
  });
  return message;
};

export const formatHTTPErrorTitle = (error: AxiosError) => {
  const statusCode = error?.response?.status;
  if (!statusCode) {
    return "Connectivity Lost";
  }
  return `HTTP Error: ${statusCode}`;
};

export const formatHTTPErrorMessage = (error: AxiosError) => {
  let message = error.message;
  const statusCode = error?.response?.status;
  const data = error?.response?.data || {};
  if (!isEmpty(data)) {
    if (statusCode === 404) {
      message = data.error_data.url;
    } else if (statusCode === 400) {
      const array: string[] = [];
      const keys = Object.keys(data?.error_data);
      keys.forEach((key, index) => {
        if (data?.error_data[key]?.join) {
          if (key === "non_field_errors") {
            array.push(`${data?.error_data[key].join("-")}`);
          } else {
            array.push(
              `${key.replaceAll("_", " ")}: ${data?.error_data[key].join("-")}`
            );
          }
        }
      });
      message = array.join("\n");
    } else if (statusCode === 401 || statusCode === 403) {
      message = data.error_data.detail;
    } else if (statusCode === 500) {
      message = "Internal server error! Please try again later.";
    }
  }
  return message;
};

export const notification = (params: NotificationParams) => {
  const { type, description, title } = params;
  const icons = {
    success: <CheckCircleOutlined style={{ color: "green" }} />,
    error: <CloseCircleOutlined style={{ color: "red" }} />,
    info: <InfoCircleOutlined style={{ color: "gray" }} />,
    warning: <ExclamationCircleOutlined style={{ color: "yellow" }} />,
  };
  const defaultTitle = {
    success: "Success",
    error: "Error",
    info: "Info",
    warning: "Warning",
  };
  antdNotification[type]({
    message: title || defaultTitle[type],
    description,
    placement: "bottomRight",
    icon: icons[type],
  });
};
