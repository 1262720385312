import { Card, Row, Col } from "antd";
import companyLogo from "../../assets/images/companyLogo.png";
import streetLoactaionIcon from "../../assets/images/streetLoactaionIcon.svg";
import React from "react";
import { Link } from "react-router-dom";
import { Props } from "./type";
import styles from "./simpleJobCard.module.scss";
import classNames from "classnames";
import { convertSalaryToMillion } from "shared/helpers/helperFunctions";
import Image from "components/Image/Image";

const SimpleJobCard: React.FC<Props> = ({ job }) => {
  return (
    <Card id="jobs" className={styles.cardContainer}>
      <Link to={`/jobs/${job?.id}/`}>
        <Row>
          <Col lg={12} md={12} sm={24} xs={24}>
            <div className={styles.comanyFeature}>
              <div>
                <Image
                  src={
                    job?.company?.profile_picture === null
                      ? ""
                      : job?.company?.profile_picture
                  }
                  defaultSrc={companyLogo}
                  alt={job?.company?.company_name || "company logo"}
                  style={styles.imageLogo}
                />
              </div>
              <div className={styles.companyInfo}>
                <h4>{job?.job_title}</h4>
                <div className={styles.loaction}>
                  <h5 style={{ marginRight: "3px" }}>
                    {job?.company?.company_name}
                  </h5>
                  <img
                    style={{
                      marginBottom: "5px",
                      paddingRight: "2px",
                    }}
                    src={streetLoactaionIcon}
                    alt="streetLoactaionIcon"
                  />
                  <h5>
                    {job?.job_location === "Other"
                      ? job?.other_location
                      : job?.job_location}
                  </h5>
                  {job?.is_remote && <h5> / Remote</h5>}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Row justify="end">
              <div className={styles.jobType}>
                <h6
                  className={classNames({
                    [styles.jobTypeNamePartTime]: job?.job_type === "PART_TIME",
                    [styles.jobTypeNameFullTime]: job?.job_type === "FULL_TIME",
                    [styles.jobTypeNameFreelance]:
                      job?.job_type === "FREELANCING",
                    [styles.jobTypeNameContract]: job?.job_type === "CONTRACT",
                  })}
                >
                  {job?.job_type.replace("_", " ")}
                </h6>
                <h6 className={styles.jobSalaryRange}>
                  {job?.salary_type === "NEGOTIABLE"
                    ? "NEGOTIABLE"
                    : `¥ ${convertSalaryToMillion(
                        job?.salary_min
                      )} - ${convertSalaryToMillion(job?.salary_max)}`}
                </h6>
              </div>
            </Row>
          </Col>
        </Row>
      </Link>
    </Card>
  );
};

export default SimpleJobCard;
