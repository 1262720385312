import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  activateAccount,
  changePassword,
  getProfileDetails,
  getCompanyProfileDetails,
  login,
  passwordResetRequest,
  resetPassword,
  signup,
  updateAccount,
  updateAdminAccount,
  updateCompanyProfile,
  uploadData,
  uploadDataAdmin,
  uploadDataCompany,
  verifyToken,
} from "api/shared/auth/index";
import {
  AccountActivationData,
  LoginPostData,
  PostChangePasswordData,
  RecursivePartial,
  SignupPostData,
} from "api/shared/auth/types";

export const loginAsync = createAsyncThunk(
  "login",
  async (loginData: LoginPostData) => {
    const response = await login(loginData);
    return response;
  }
);

export const signupAsync = createAsyncThunk(
  "signup",
  async (loginData: SignupPostData) => {
    const response = await signup(loginData);
    return response;
  }
);
export const passwordResetRequestAsync = createAsyncThunk(
  "password-reset-request",
  async (email: string) => {
    const response = await passwordResetRequest(email);
    return response;
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "reset-password",
  async (activationData: AccountActivationData) => {
    const response = await resetPassword(activationData);
    return response;
  }
);

export const activateAccountAsync = createAsyncThunk(
  "activate-account",
  async (activationData: AccountActivationData) => {
    const response = await activateAccount(activationData);
    return response;
  }
);

export const verifyTokenAsync = createAsyncThunk(
  "token-verify",
  async (token: string) => {
    const response = await verifyToken(token);
    return response;
  }
);

export const changePasswordAsync = createAsyncThunk(
  `user/changePassword`,
  async (data: PostChangePasswordData, { rejectWithValue }) => {
    try {
      const response = await changePassword(data);
      return response;
    } catch (e) {
      return rejectWithValue(e.error_data);
    }
  }
);

export const updateAccountAsync = createAsyncThunk(
  `superadmin/updateAccount`,
  async ({
    data,
    id,
  }: {
    data: RecursivePartial<SignupPostData>;
    id: number;
  }) => {
    const response = await updateAccount(data, id);
    return response;
  }
);

export const uploadDataAsync = createAsyncThunk(
  `superadmin/uploadData`,
  async ({ data, id }: { data: FormData; id: number }) => {
    const response = await uploadData(data, id);
    return response;
  }
);

export const updateAdminAccountAsync = createAsyncThunk(
  `admin/updateAccount`,
  async ({
    data,
    id,
  }: {
    data: RecursivePartial<SignupPostData>;
    id: number;
  }) => {
    const response = await updateAdminAccount(data, id);
    return response;
  }
);

export const uploadAdminDataAsync = createAsyncThunk(
  `admin/uploadData`,
  async ({ data, id }: { data: FormData; id: number }) => {
    const response = await uploadDataAdmin(data, id);
    return response;
  }
);

export const uploadCompanyDataAsync = createAsyncThunk(
  `company/uploadData`,
  async ({ data, id }: { data: FormData; id: number }) => {
    const response = await uploadDataCompany(data, id);
    return response;
  }
);

export const getProfileAsync = createAsyncThunk(`getProfile`, async () => {
  const response = await getProfileDetails();
  return response;
});

export const getCompanyProfileAsync = createAsyncThunk(
  `getCompanyProfile`,
  async () => {
    const response = await getCompanyProfileDetails();
    return response;
  }
);

export const updateCompanyAccountAsync = createAsyncThunk(
  `admin/updateCompanyAccount`,
  async ({
    data,
    id,
  }: {
    data: RecursivePartial<SignupPostData>;
    id: number;
  }) => {
    const response = await updateCompanyProfile(data, id);
    return response;
  }
);
