import React from "react";
import { Outlet, Route } from "react-router-dom";
import { ROUTES } from "shared/routes/constants";
import Companies from "uicontainers/Public/Companies";
import CreateCompany from "uicontainers/Public/CreateCompany";
import HomeJob from "uicontainers/Public/HomeJob";
import Content from "uicontainers/Layout/Content";
import Login from "uicontainers/Public/Login";
import CompanyDetails from "uicontainers/Public/CompanyDetails";
import JobDetails from "uicontainers/Public/JobDetails.tsx";
import ForgotPassword from "uicontainers/Public/ForgotPassword";
import Footer from "uicontainers/_shared/Footer";
import JobList from "uicontainers/Public/JobList";
import ResetPassword from "uicontainers/Public/RestPassword";

const HomeLayout = () => {
  return (
    <Content footer={<Footer />}>
      <Outlet />
    </Content>
  );
};

const Home = () => {
  return (
    <Route path={ROUTES.PATHS.PUBLIC.ROOT} element={<HomeLayout />}>
      <Route index element={<HomeJob />} />
      <Route path={ROUTES.PATHS.PUBLIC.LOGIN} element={<Login />} />
      <Route path={ROUTES.PATHS.PUBLIC.JOBS} element={<HomeJob />} />
      <Route path={ROUTES.PATHS.PUBLIC.JOB_LIST} element={<JobList />} />
      <Route path={ROUTES.PATHS.PUBLIC.JOB_DETAILS} element={<JobDetails />} />
      <Route
        path={ROUTES.PATHS.PUBLIC.JOB_DETAILS_LIST}
        element={<JobDetails />}
      />
      <Route path={ROUTES.PATHS.PUBLIC.COMPANIES} element={<Companies />} />
      <Route path={ROUTES.PATHS.PUBLIC.SIGN_UP} element={<CreateCompany />} />
      <Route
        path={ROUTES.PATHS.PUBLIC.FORGOT_PASSWORD}
        element={<ForgotPassword />}
      />
      <Route
        path={ROUTES.PATHS.PUBLIC.RESET_PASSWORD}
        element={<ResetPassword />}
      />
      <Route
        path={ROUTES.PATHS.PUBLIC.COMPANY_DETAILS}
        element={<CompanyDetails />}
      />
    </Route>
  );
};

export default Home;
