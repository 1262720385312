import { Col, Row } from "antd";
import DashboardCard from "components/DashboardCard";
import React, { useEffect } from "react";
import classNames from "classnames";
import styles from "./summary.module.scss";
import { useUnwrapAsyncThunk } from "store/hooks";
import { getSummaryAsync } from "../../../../store/admin/Dashboard/summary/thunks";
import { adminSummarySelector } from "store/admin/Dashboard/summary/selectors";
import { useAppSelector } from "store/hooks";
const Summary = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getSummaryAsync());
  }, []);
  const { summary } = useAppSelector(adminSummarySelector);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 32 }, 0]}>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Total Jobs Posted" isActive={true}>
              <h1
                className={classNames(
                  styles.statValue,
                  styles.total_jobs_posted
                )}
              >
                {summary?.total_jobs_posted}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Jobs Posted This Month" isActive={false}>
              <h1
                className={classNames(styles.statValue, styles.jobs_post_time)}
              >
                {summary?.job_posted_this_month}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Job Applicants This Month" isActive={false}>
              <h1
                className={classNames(styles.statValue, styles.jobs_post_time)}
              >
                {summary?.job_applicants_this_month}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Job Queries This Month" isActive={false}>
              <h1
                className={classNames(styles.statValue, styles.jobs_post_time)}
              >
                {summary?.job_queries_this_month}
              </h1>
            </DashboardCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Summary;
