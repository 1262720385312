import { createSlice } from "@reduxjs/toolkit";

import { getRecentApplicantsAsync } from "./thunks";
import { RecentApplicantsState } from "./types";

const initialState: RecentApplicantsState = {
  recentApplicants: [],
  recentApplicantsRequestStatus: "initial",
};

export const getRecentApplicantsSlice = createSlice({
  name: "admin/recentApplicants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRecentApplicantsAsync.pending, (state) => {
      state.recentApplicantsRequestStatus = "started";
    });
    builder.addCase(getRecentApplicantsAsync.fulfilled, (state, action) => {
      state.recentApplicantsRequestStatus = "done";
      state.recentApplicants = action.payload.results;
    });
    builder.addCase(getRecentApplicantsAsync.rejected, (state) => {
      state.recentApplicantsRequestStatus = "failed";
    });
  },
});

export default getRecentApplicantsSlice;
