import { createSlice } from "@reduxjs/toolkit";

import { getSummaryAsync } from "./thunks";
import { adminSummaryState } from "./types";

const initialState: adminSummaryState = {
  summary: null,
  getSummaryStatus: "initial",
};
export const getSummary = createSlice({
  name: "admin/summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSummaryAsync.pending, (state) => {
      state.getSummaryStatus = "started";
    });
    builder.addCase(getSummaryAsync.fulfilled, (state, action) => {
      state.summary = action.payload.results;
      state.getSummaryStatus = "done";
    });
    builder.addCase(getSummaryAsync.rejected, (state) => {
      state.getSummaryStatus = "failed";
    });
  },
});

export default getSummary;
