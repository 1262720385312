import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAdminJobs,
  getAdminJobDetails,
  PostJobs,
  PutJobs,
  DeleteAdminJob,
  updateAdminJobs,
  getAdminJobTitle,
} from "api/admin/jobs";

import {
  JobsDataPost,
  JobsRequestParams,
  JobTitleRequestParams,
  UpdateAdminJobsData,
} from "api/admin/jobs/types";

export const getJobsAsync = createAsyncThunk(
  "admin/jobs/getJobs",
  async (params?: JobsRequestParams) => {
    const response = await getAdminJobs(params);
    return response;
  }
);

export const getJobTitleAsync = createAsyncThunk(
  "admin/jobs/getJobTitle",
  async (params?: JobTitleRequestParams) => {
    const response = await getAdminJobTitle(params);
    return response;
  }
);

export const getJobDetailsAsync = createAsyncThunk(
  "admin/jobs/getJobDetails",
  async (jobId: string) => {
    const response = await getAdminJobDetails(jobId);
    return response;
  }
);

export const postJobsAsync = createAsyncThunk(
  "admin/post-jobs",
  async (data: JobsDataPost) => {
    const response = await PostJobs(data);
    return response;
  }
);

export const putJobAsync = createAsyncThunk(
  "admin/put-jobs",
  async ({ jobId, data }: { jobId: string; data: JobsDataPost }) => {
    const response = await PutJobs(jobId, data);
    return response;
  }
);

export const deleteJobAsync = createAsyncThunk(
  "admin/delete-job",
  async (jobId: number) => {
    const response = await DeleteAdminJob(jobId);
    return response;
  }
);

export const updateAdminJobsAsync = createAsyncThunk(
  "admin/jobs/updateAdminJobs",
  async (data: UpdateAdminJobsData) => {
    const response = await updateAdminJobs(data, data.jobsId);
    return response;
  }
);
