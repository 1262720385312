import React, { useEffect, useState } from "react";
import { Spin, Row, Col } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import Button from "components/Button";
import Input from "components/Input";
import styles from "./account.module.scss";
import { PersonalInfoType } from "./types";
import { useUpdatePersonalInfo, useUploadData } from "./hooks";
import { getProfileAsync } from "store/shared/auth/thunks";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import { getProfileData } from "store/shared/auth/selectors";
import { selectPersonalInfo } from "./selectors";
import ProfilePicture from "uicontainers/_shared/ProfilePicture/ProfilePicture";

const Account = () => {
  const updateAccount = useUpdatePersonalInfo();
  const upload = useUploadData();
  const isLoading = false;
  const unwrap = useUnwrapAsyncThunk();
  const data = useAppSelector(selectPersonalInfo);
  const p_data = useAppSelector(getProfileData)[0];
  const { profile_picture } = p_data || {};
  const [form] = useForm();
  const [changedValues, setChangedValues] = useState<
    PersonalInfoType | undefined
  >(undefined);

  useEffect(() => {
    unwrap(getProfileAsync());
  }, []);

  useEffect(() => {
    const tempValues = {
      email: changedValues?.email || data?.email || p_data?.email,
      job_title:
        changedValues?.job_title || data?.job_title || p_data?.job_title,
      user_name:
        changedValues?.user_name || data?.user_name || p_data?.user_name,
    };
    form.setFieldsValue(tempValues);
  }, [data, p_data]);

  return (
    <div className={styles.bodyContainer}>
      <h1 className={styles.heading}>User Profile</h1>
      <h3 className={styles.heading_two}>User Information</h3>
      <Form
        className={styles.form}
        onFinish={(values: PersonalInfoType) => {
          const { email, job_title, user_name, ...othhers } = values;
          updateAccount({
            ...othhers,
            email: email,
            job_title: job_title,
            user_name: user_name,
          });
          setChangedValues(undefined);
        }}
        onValuesChange={(changedValues) => {
          setChangedValues((prevState) => {
            return { ...prevState, ...changedValues };
          });
        }}
        form={form}
      >
        <Spin spinning={isLoading}>
          <ProfilePicture
            profile_picture={profile_picture}
            updateAccount={updateAccount}
            upload={upload}
          />

          <div className={styles.nameContainer}>
            <Row gutter={[24, 0]}>
              <Col xs={24} lg={12}>
                <FormItem
                  name="user_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your User Name!",
                    },
                  ]}
                >
                  <Input label="User Name" />
                </FormItem>
                <FormItem
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email!",
                    },
                  ]}
                >
                  <Input label="User Email" />
                </FormItem>
                <FormItem name="job_title">
                  <Input label="Job Title" placeholder="Type here" />
                </FormItem>
              </Col>
            </Row>
          </div>
          <Button
            className={styles.button}
            type="ghost"
            isBordered={true}
            htmlType="submit"
          >
            <div className={styles.textContainer}>
              <span className={styles.buttonTextGhost}>Submit</span>
            </div>
          </Button>
        </Spin>
      </Form>
    </div>
  );
};

export default Account;
