import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleApplyNow } from "api/public/apply-now";
import { applyNow } from "api/public/apply-now/types";

export const handleApplyNowAsync = createAsyncThunk(
  "public/apply-now",
  async (data: applyNow) => {
    const response = await handleApplyNow(data);
    return response;
  }
);
