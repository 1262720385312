import { createSelector } from "reselect";

import { transformAdminQueriesToTableData } from "./helpers";

import { RootState } from "store/types";

export const adminQueriesTableData = (state: RootState) =>
  state.resources.admin.queries.queries;

export const createAdminQueriesTableData = createSelector(
  adminQueriesTableData,
  transformAdminQueriesToTableData
);

export const selectTotalCount = (state: RootState) =>
  state.resources.admin.queries.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.admin.queries.currentPage;

export const selectPageSize = (state: RootState) =>
  state.resources.admin.queries.pageSize;
