import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getQueriesAsync, updateQueriesAsync } from "./thunks";
import { QueriesState } from "./types";

const initialState: QueriesState = {
  queries: [],
  totalCount: 0,
  currentPage: 1,
  pageSize: 10,
  queriesRequestStatus: "initial",
  updateQueriesReply: null,
  patchupdateQueriesReplyStatus: "initial",
};

export const getQueriesSlice = createSlice({
  name: "admin/queries",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQueriesAsync.pending, (state) => {
      state.queriesRequestStatus = "started";
    });
    builder.addCase(getQueriesAsync.fulfilled, (state, action) => {
      state.queriesRequestStatus = "done";
      state.totalCount = action.payload.count;
      state.queries = action.payload.results;
    });
    builder.addCase(getQueriesAsync.rejected, (state) => {
      state.queriesRequestStatus = "failed";
    });
    builder.addCase(updateQueriesAsync.pending, (state) => {
      state.patchupdateQueriesReplyStatus = "started";
    });
    builder.addCase(updateQueriesAsync.fulfilled, (state) => {
      state.patchupdateQueriesReplyStatus = "done";
    });
    builder.addCase(updateQueriesAsync.rejected, (state) => {
      state.patchupdateQueriesReplyStatus = "failed";
    });
  },
});

export default getQueriesSlice;
