import React, { FC } from "react";
import styles from "./login.module.scss";
import toryGate from "../../../assets/images/tory_gate.svg";
import { Divider, Form, Row, Col, Modal, Typography } from "antd";
import classNames from "classnames";
import { Checkbox as AntCheckbox } from "antd";
import Input from "components/Input";
import Checkbox from "components/CheckBox";
import Button from "components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useAppSelector,
  useUnwrapAsyncThunk,
  useAppDispatch,
} from "store/hooks";
import {
  selectIsAuthenticated,
  selectIsNewAccount,
  selectLoginData,
} from "store/shared/auth/selectors";
import { setIsNewAccount } from "store/shared/auth/actions";
import { USER_NAME } from "./constants";
import { ROUTES } from "shared/routes/constants";
import { LoginPostData } from "api/shared/auth/types";
import { loginAsync } from "store/shared/auth/thunks";
import { useIsMobile, useIsTab, useLaptop } from "shared/hooks";
const { Title } = Typography;

const Login: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userName = localStorage.getItem(USER_NAME);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const unwrap = useUnwrapAsyncThunk();
  const [isRequired, setIsRequired] = React.useState(userName ? true : false);
  const loginData = useAppSelector(selectLoginData);
  const location = useLocation();
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const isLaptop = useLaptop();
  const isNewAccount = useAppSelector(selectIsNewAccount);
  // @ts-ignore
  const from = location.state?.from?.pathname;
  React.useEffect(() => {
    if (isAuthenticated && loginData) {
      if (from) {
        // navigate(from, { replace: true });
        window.location.href = from;
      } else if (loginData.is_superuser === true) {
        // navigate(ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_HOME);
        window.location.href = ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_HOME;
      } else if (loginData.is_superuser === false) {
        // navigate(ROUTES.PATHS.ADMIN.ADMIN_HOME);
        window.location.href = ROUTES.PATHS.ADMIN.ADMIN_HOME;
      }
    }
  }, [isAuthenticated, loginData, navigate, from]);

  const onFinish = (values: LoginPostData) => {
    unwrap(loginAsync(values))
      .then(() => {
        isRequired
          ? localStorage.setItem(USER_NAME, values.email)
          : localStorage.setItem(USER_NAME, "");
      })
      .catch((e) => {});
  };

  return (
    <div className={styles.main}>
      <Row
        justify="center"
        align="middle"
        className={classNames({
          [styles.row]: true,
          [styles["is-mobile"]]: isMobile,
          [styles["is-tab"]]: isTab,
        })}
      >
        <Col xs={0} sm={0} md={10} lg={10} xl={10}>
          <div className={styles.firstContainer}>
            <h5
              className={classNames({
                [styles.paragraph]: true,
                [styles["is-tab"]]: isTab,
              })}
            >
              Sign up FREE to present your job and business opportunities to a
              wide range of talented people in Japan
            </h5>
            <img
              className={classNames({
                [styles.imageLogo]: true,
                [styles["is-tab"]]: isTab,
                [styles["is-laptop"]]: isLaptop,
              })}
              src={toryGate}
              alt="toryGate"
            />
            <h5
              className={classNames({
                [styles.paragraph]: true,
                [styles["is-tab"]]: isTab,
              })}
            >
              Expand your options by joining our diverse network of good people
              doing Business In Japan
            </h5>
          </div>
        </Col>
        <Col xs={0} sm={0} md={2} lg={2} xl={2}>
          <Row justify="center" align="middle">
            <Divider
              className={classNames({
                [styles.divider]: true,
                [styles["is-tab"]]: isTab,
              })}
              type="vertical"
            />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <Row
            justify="start"
            align="middle"
            className={classNames({
              [styles.rowContianer]: true,
              [styles["is-mobile"]]: isMobile,
              [styles["is-tab"]]: isTab,
            })}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              className={styles.fromContainer}
            >
              <h4 className={styles.title}>Log in to your Company Account</h4>
              <Form
                onFinish={onFinish}
                initialValues={{ email: userName }}
                size="large"
                layout="vertical"
              >
                <Row gutter={[16, 0]}>
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                      ]}
                    >
                      <Input inputType="password" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24}>
                    <Row justify="space-between">
                      <Col lg={12} md={12} sm={12} xs={24}>
                        <Form.Item>
                          <AntCheckbox.Group>
                            <Checkbox
                              onChange={() => setIsRequired(!isRequired)}
                              checked={isRequired}
                            >
                              Remember Me
                            </Checkbox>
                          </AntCheckbox.Group>
                        </Form.Item>
                      </Col>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={24}
                        className={classNames({
                          [styles.forgetPasswordContainer]: isMobile,
                        })}
                      >
                        <h4
                          onClick={() =>
                            navigate(ROUTES.PATHS.PUBLIC.FORGOT_PASSWORD)
                          }
                          className={styles.forgetPassword}
                        >
                          Forgot Password
                        </h4>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="gutter-row" span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      danger
                      shape="round"
                      //   isBordered={true}
                      className={styles.homeButton}
                    >
                      Login
                    </Button>
                  </Col>
                  <Col className="gutter-row" span={24}>
                    <Row
                      justify="space-between"
                      align="middle"
                      className={styles.signupContainer}
                    >
                      <Col lg={16} md={16} sm={16} xs={24}>
                        <h5 className={styles.accountColor}>
                          Don’t have an account?
                        </h5>
                      </Col>
                      <Col
                        lg={8}
                        md={8}
                        sm={8}
                        xs={24}
                        className={classNames({
                          [styles.signupButtonContainer]: isMobile,
                        })}
                      >
                        <Button
                          isBordered={true}
                          className={styles.signupButton}
                          onClick={() => navigate(ROUTES.PATHS.PUBLIC.SIGN_UP)}
                        >
                          Sign Up
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        centered
        visible={isNewAccount}
        closable={false}
        className={styles.filterModal}
        footer={[]}
      >
        <div className={styles.modalContainer}>
          <Title level={4}>
            Your submission is still under review. Please wait until your
            account get approved.
          </Title>
          <Button onClick={() => dispatch(setIsNewAccount(false))} type="ghost">
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
