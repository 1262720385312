import React from "react";
import icon from "../../../assets/images/icon_svg.svg";
import { Queries } from "api/admin/queries/types";
import ReplyModal from "./ReplyModal";
import { SuperAdminQueries } from "api/superadmin/queries/types";
import styles from "./queries.module.scss";

type Props = {
  isSuperAdmin: boolean;
  query: Queries | SuperAdminQueries;
};

const ActionButton = (props: Props) => {
  const { isSuperAdmin, query } = props;
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <div className={styles.messageBoxContainer}>
        {!query.reply && <span className={styles.messageNewIcon}>NEW!</span>}
        <img src={icon} alt="Button Icon" onClick={() => setVisible(true)} />
      </div>
      {visible && (
        <ReplyModal
          isSuperAdmin={isSuperAdmin}
          query={query}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </>
  );
};

export default ActionButton;
