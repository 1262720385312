import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobApplicationsReportCategory, JobApplicationsState } from "./types";

const initialState: JobApplicationsState = {
  category: "all_year",
};

const jobApplicationsChartsSlice = createSlice({
  name: "jobApplications",
  initialState,
  reducers: {
    selectCategory(
      state,
      action: PayloadAction<JobApplicationsReportCategory>
    ) {
      state.category = action.payload;
    },
  },
});

export default jobApplicationsChartsSlice;
