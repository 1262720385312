import React from "react";
import { SuperAdminTags } from "api/superadmin/tags/types";
import { Props as TextProps } from "components/tables/cells/TextCell/types";
import TagEditActionButton from "./TagEditActionButton";

export interface TagTableDataType {
  key: string;
  job_tag: TextProps;
}

export const transformSuperAdminTagsToTableData = (
  tags: SuperAdminTags[]
): TagTableDataType[] => {
  return tags.map((tag) => ({
    key: tag.id.toString(),
    job_tag: {
      text: tag.job_tag,
    },

    three_dot_btn: {
      buttons: [
        {
          icon: <TagEditActionButton tag={tag} />,
          onClick: () => {},
        },
      ],
    },
  }));
};
