import { createSelector } from "reselect";

import { transformSuperAdminApplicantsToTableData } from "./helpers";

import { RootState } from "store/types";

export const superAdminApplicantsData = (state: RootState) =>
  state.resources.superAdmin.superAdminApplicants.superadmin_applicants;

export const createAdminApplicantsTableData = createSelector(
  superAdminApplicantsData,
  transformSuperAdminApplicantsToTableData
);

export const selectTotalCount = (state: RootState) =>
  state.resources.superAdmin.superAdminApplicants.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.superAdmin.superAdminApplicants.currentPage;

export const selectPageSize = (state: RootState) =>
  state.resources.superAdmin.superAdminApplicants.pageSize;
