import React from "react";
import styles from "./forgotPassword.module.scss";
import toryGate from "../../../assets/images/tory_gate.svg";
import classNames from "classnames";
import { Divider, Form, Row, Col, Result, Spin } from "antd";
import Input from "components/Input";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "shared/routes/constants";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectForgotPasswordRequestStatus } from "store/shared/auth/selectors";
import { passwordResetRequestAsync } from "store/shared/auth/thunks";
import { useIsMobile, useIsTab, useLaptop } from "shared/hooks";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isForgotPasswordLoading =
    useAppSelector(selectForgotPasswordRequestStatus) === "started";
  const isForgotPasswordSuccess =
    useAppSelector(selectForgotPasswordRequestStatus) === "done";
  const onFinish = (email: string) => {
    dispatch(passwordResetRequestAsync(email));
  };
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const isLaptop = useLaptop();
  return (
    <div className={styles.main}>
      {isForgotPasswordSuccess && (
        <Result
          status="success"
          title="Successfully sent reset link!"
          subTitle="Please check your email and click the reset link to reset your password"
        />
      )}
      {!isForgotPasswordSuccess && (
        <Row
          justify="center"
          align="middle"
          className={classNames({
            [styles.row]: true,
            [styles["is-mobile"]]: isMobile,
            [styles["is-tab"]]: isTab,
          })}
        >
          <Col xs={0} sm={0} md={10} lg={10} xl={10}>
            <div className={styles.firstContainer}>
              <h5
                className={classNames({
                  [styles.paragraph]: true,
                  [styles["is-tab"]]: isTab,
                })}
              >
                An account gives access to Free job posting in BiJ job portal to
                reach a wide range of Talents in Japan
              </h5>
              <img
                className={classNames({
                  [styles.imageLogo]: true,
                  [styles["is-tab"]]: isTab,
                  [styles["is-laptop"]]: isLaptop,
                })}
                src={toryGate}
                alt="toryGate"
              />
            </div>
          </Col>
          <Col xs={0} sm={0} md={2} lg={2} xl={2}>
            <Row justify="center" align="middle">
              <Divider
                className={classNames({
                  [styles.divider]: true,
                  [styles["is-tab"]]: isTab,
                })}
                type="vertical"
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Spin spinning={isForgotPasswordLoading}>
              <Row
                justify="start"
                align="middle"
                className={classNames({
                  [styles.rowContianer]: true,
                  [styles["is-tab"]]: isTab,
                  [styles["is-mobile"]]: isMobile,
                })}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  className={styles.fromContainer}
                >
                  <h4 className={styles.title}>Forgot your Password</h4>
                  <h5 className={styles.subTitle}>
                    Can’t remeber your password? Don’t worry a email will be
                    sent to your address to reset the password.
                  </h5>
                  <Form onFinish={onFinish} size="large" layout="vertical">
                    <Row gutter={[16, 0]}>
                      <Col className="gutter-row" span={24}>
                        <Form.Item
                          name="email"
                          label="Email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your email!",
                            },
                          ]}
                        >
                          <Input placeholder="" type="email" />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={24}>
                        <Button
                          type="primary"
                          danger
                          shape="round"
                          htmlType="submit"
                          className={styles.homeButton}
                        >
                          Submit
                        </Button>
                      </Col>
                      <Col className="gutter-row" span={24}>
                        <Row
                          justify="space-between"
                          align="middle"
                          className={styles.signupContainer}
                        >
                          <Col lg={12}>
                            <h5 className={styles.accountColor}>
                              Remember your password?
                            </h5>
                          </Col>
                          <Col lg={12}>
                            <Button
                              isBordered={true}
                              className={styles.signupButton}
                              onClick={() =>
                                navigate(ROUTES.PATHS.PUBLIC.LOGIN)
                              }
                            >
                              Log In
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ForgotPassword;
