enum ChartSeriesType {
  APPLICATIONS = "Applications",
  VIEWS = "Views",
}

type ChartData = {
  month: string;
  count: number;
  type: ChartSeriesType;
};

export const getSortByMonth = (data: ChartData[]) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const sorter = (a: ChartData, b: ChartData) => {
    return months.indexOf(a.month) - months.indexOf(b.month);
  };
  return data.sort(sorter);
};
