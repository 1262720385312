import { Spin } from "antd";
import MasterTable from "components/tables/MasterTable";
import React, { useEffect } from "react";
import { adminRecentApplicants } from "store/admin/Dashboard/recent-applicants/selectors";
import { getRecentApplicantsAsync } from "store/admin/Dashboard/recent-applicants/thunks";

import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import Card from "uicontainers/_shared/Card";
import { COLUMNS } from "./constants";
import { transformRecentApplicantsToTableData } from "./helpers";
const RecenetApplicants = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getRecentApplicantsAsync());
  }, []);

  

  const tableData = transformRecentApplicantsToTableData(
    useAppSelector(adminRecentApplicants).recentApplicants
  );

  return (
    <Spin spinning={false}>
      <Card title="Recent Applicants" hasPadding={false}>
        <MasterTable data={[...tableData]} columns={COLUMNS} />
      </Card>
    </Spin>
  );
};

export default RecenetApplicants;
