import {
  getSuperAdminJobsAsync,
  getSuperAdminJobsCategoryAsync,
  getSuperAdminJobsTitleAsync,
  updateSuperAdminJobsAsync,
} from "./thunks";
import slice from "./slice";

const {
  setCurrentPage,
  setPageSize,
  setSuperadminJobTitleToEmpty,
} = slice.actions;
export {
  getSuperAdminJobsAsync,
  setCurrentPage,
  setPageSize,
  setSuperadminJobTitleToEmpty,
  getSuperAdminJobsCategoryAsync,
  getSuperAdminJobsTitleAsync,
  updateSuperAdminJobsAsync,
};
