import { createSelector } from "reselect";

import { transformAdminApplicantsToTableData } from "./helpers";

import { RootState } from "store/types";

export const adminApplicants = (state: RootState) =>
  state.resources.admin.applicants.applicants;

export const createSuperAdminApplicantsTableData = createSelector(
  adminApplicants,
  transformAdminApplicantsToTableData
);
export const selectTotalCount = (state: RootState) =>
  state.resources.admin.applicants.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.admin.applicants.currentPage;

export const selectPageSize = (state: RootState) =>
  state.resources.admin.applicants.pageSize;
