import { createSlice } from "@reduxjs/toolkit";

import { getSuperadminSummaryAsync } from "./thunks";
import { SuperAdminState } from "./types";

const initialState: SuperAdminState = {
  adminSummary: {
    id: 0,
    total_companies: 0,
    total_jobs_posted: 0,
    total_applicants: 0,
    total_queries: 0,
    new_companies_this_month: 0,
    job_posted_this_month: 0,
    job_applicants_this_month: 0,
    job_queries_this_month: 0,
  },
  adminSummaryStatus: "initial",
};

export const getSuperAdminSlice = createSlice({
  name: "superadmin/summary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuperadminSummaryAsync.pending, (state) => {
      state.adminSummaryStatus = "started";
    });
    builder.addCase(getSuperadminSummaryAsync.fulfilled, (state, action) => {
      state.adminSummaryStatus = "done";
      state.adminSummary = action.payload.results;
    });
    builder.addCase(getSuperadminSummaryAsync.rejected, (state) => {
      state.adminSummaryStatus = "failed";
    });
  },
});

export default getSuperAdminSlice;
