import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getSuperAdminCompaniesAsync,
  getSuperAdminCompanyNameAsync,
  updateSuperAdminCompaniesAsync,
} from "./thunks";
import { SuperAdminCompaniesState } from "./types";

const initialState: SuperAdminCompaniesState = {
  superadmin_companies: [],
  superadmin_company_names: [],
  totalCount: 0,
  currentPage: 1,
  pageSize: 10,
  superadmin_companiesRequestStatus: "initial",
  superadminCompanyNameRequestStatus: "initial",
  updateSuperAdminCompanies: null,
  updateSuperAdminCompaniesRequestStatus: "initial",
};

export const getSuperAdminCompaniesSlice = createSlice({
  name: "superadmin/companies",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSuperAdminCompaniesAsync.pending, (state) => {
      state.superadmin_companiesRequestStatus = "started";
    });
    builder.addCase(getSuperAdminCompaniesAsync.fulfilled, (state, action) => {
      state.superadmin_companiesRequestStatus = "done";
      state.totalCount = action.payload.count;
      state.superadmin_companies = action.payload.results;
    });
    builder.addCase(getSuperAdminCompaniesAsync.rejected, (state) => {
      state.superadmin_companiesRequestStatus = "failed";
    });
    builder.addCase(getSuperAdminCompanyNameAsync.pending, (state) => {
      state.superadminCompanyNameRequestStatus = "started";
    });
    builder.addCase(
      getSuperAdminCompanyNameAsync.fulfilled,
      (state, action) => {
        state.superadminCompanyNameRequestStatus = "done";
        state.totalCount = action.payload.count;
        state.superadmin_company_names = action.payload.results;
      }
    );
    builder.addCase(getSuperAdminCompanyNameAsync.rejected, (state) => {
      state.superadminCompanyNameRequestStatus = "failed";
    });
    builder.addCase(updateSuperAdminCompaniesAsync.pending, (state) => {
      state.updateSuperAdminCompaniesRequestStatus = "started";
    });
    builder.addCase(
      updateSuperAdminCompaniesAsync.fulfilled,
      (state, action) => {
        state.updateSuperAdminCompaniesRequestStatus = "done";
        state.superadmin_companies = state.superadmin_companies
          .map((item) => {
            if (item.id === action.payload.results.id) {
              return action.payload.results;
            }
            return item;
          })
          .filter((data) => data.active_status !== "ARCHIVE");
      }
    );
    builder.addCase(updateSuperAdminCompaniesAsync.rejected, (state) => {
      state.updateSuperAdminCompaniesRequestStatus = "failed";
    });
  },
});

export default getSuperAdminCompaniesSlice;
