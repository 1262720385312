import React from "react";
import { SuperAdminQueries } from "api/superadmin/queries/types";
import { queriesDataType } from "./types";
import { dateTimeGenerator2 } from "shared/helpers/helperFunctions";
import ActionButton from "uicontainers/Admin/Queries/ActionButton";
import ActionButtonTwo from "uicontainers/Admin/Queries/ActionButtonTwo";

export const transformSuperAdminQueriesToTableData = (
  queries: SuperAdminQueries[]
): queriesDataType[] => {
  return queries.map((query) => ({
    key: query.id.toString(),
    full_name: {
      text: query.full_name,
    },
    email: {
      text: query.email,
    },
    company_name: {
      text: query.company_name,
    },
    job_title: {
      text: query.job_title,
    },
    created_at: {
      text: `${dateTimeGenerator2(query.created_at)}`,
    },
    three_dot_btn: {
      buttons: [
        {
          icon: <ActionButton isSuperAdmin={true} query={query} />,
          onClick: () => {},
        },
        {
          icon: <ActionButtonTwo isSuperAdmin={true} query={query} />,
          onClick: () => {},
        },
      ],
    },
  }));
};
