import {
  UserOutlined,
  UsergroupAddOutlined,
  AuditOutlined,
  SettingOutlined,
  DashboardOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import React from "react";
import { ROUTES } from "shared/routes/constants";

export const getSideBarMenu = () => [
  {
    title: "Dashboard",
    icon: <DashboardOutlined />,
    link: ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_DASHBOARD,
  },
  {
    title: "Companies",
    icon: <UsergroupAddOutlined />,
    link: ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_COMPANIES,
  },
  {
    title: "Jobs",
    icon: <AuditOutlined />,
    link: ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_JOBS,
  },
  {
    title: "Applicants",
    icon: <UserOutlined />,
    link: ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_JOB_APPLICATANS,
  },
  {
    title: "Queries",
    icon: <QuestionCircleOutlined />,
    link: ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_JOB_QUERIES,
  },
  {
    title: "Setting",
    icon: <SettingOutlined />,
    link: ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_TAGS,
  },
  {
    title: "User Profile",
    icon: <UserOutlined />,
    link: ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_PROFILE,
  },
];
