import React from "react";
import styles from "./home.module.scss";
import { Col, Row } from "antd";
import Summary from "../Summary";
import JobApplications from "../JobApplications";
import RecenetJobPosts from "../RecentJobPosts";
import RecentApplicants from "../RecentApplicants";

const Home = () => {
  return (
    <div className={styles.bodyContainer}>
      <Row gutter={[0, { xs: 8, sm: 8, md: 16, lg: 16 }]}>
        <Col span={24}>
          <Summary />
        </Col>
        <Col span={24}>
          <JobApplications />
        </Col>
        <Col span={24}>
          <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 24 }, 0]}>
            <Col xs={24} sm={24} md={24} lg={12}>
              <RecenetJobPosts />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <RecentApplicants />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
