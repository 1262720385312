import React, { FC, useState } from "react";
import { Menu, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Button from "components/Button";

import { useUpdateSuperAdminBasicInfo } from "./hooks";
import { MenuActionsProps } from "./types";
import { useAppDispatch } from "store/hooks";
import styles from "./companies.module.scss";

const MenuActions: FC<MenuActionsProps> = (props) => {
  const dispatch = useAppDispatch();
  const { company, setVisible } = props;
  const updateCompanies = useUpdateSuperAdminBasicInfo();

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState(
    <>
      <span style={{ color: "red" }}>
        <b>Warning:</b>
      </span>
      {
        " Archived company will not appear in the list. Are you sure to continue?"
      }
    </>
  );

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    updateCompanies({ active_status: "ARCHIVE" }, company.id)
      .then(() => {
        setOpen(false);
      })
      .catch((error) => {
        setModalText(<>Failed to archive the company</>);
      });
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
  };

  // @ts-ignore
  const handleStatusChange = (info: MenuInfo) => {
    setVisible(false);
    if (info.key === "active") {
      updateCompanies({ active_status: "ACTIVE" }, company.id);
    }
    if (info.key === "inactive") {
      updateCompanies({ active_status: "INACTIVE" }, company.id);
    }
    if (info.key === "archive") {
      showModal();
    }
  };

  return (
    <Menu onClick={handleStatusChange}>
      {(company?.active_status === "INACTIVE" ||
        company?.active_status === "NEW") && (
        <Menu.Item key="active">ACTIVE</Menu.Item>
      )}
      <Menu.Divider />
      {(company?.active_status === "ACTIVE" ||
        company?.active_status === "NEW") && (
        <Menu.Item key="inactive">INACTIVE</Menu.Item>
      )}
      <Menu.Divider />
      {(company?.active_status === "ACTIVE" ||
        company?.active_status === "INACTIVE" ||
        company?.active_status === "NEW") && (
        <Menu.Item key="archive">ARCHIVE</Menu.Item>
      )}

      <Modal
        centered
        visible={open}
        closable={false}
        title={
          <>
            {" "}
            <ExclamationCircleOutlined /> Archive Company
          </>
        }
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            className={styles.cancelButton}
          >
            Cancel
          </Button>,
          <Button key="submit" type="ghost" onClick={handleOk}>
            Okay
          </Button>,
        ]}
      >
        <p>{modalText}</p>
      </Modal>
    </Menu>
  );
};

export default MenuActions;
