import slice from "./slice";
import {
  getJobsAsync,
  getMoreJobsAsync,
  homeOpenJobsAsync,
  homeOpenCompaniesAsync,
} from "./thunks";

const { setCurrentPage } = slice.actions;
export {
  getJobsAsync,
  getMoreJobsAsync,
  homeOpenJobsAsync,
  homeOpenCompaniesAsync,
  setCurrentPage,
};
