import { Card } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import styles from './card.module.scss';
import { Props } from './types';

const StatisticsCard: FC<Props> = (props) => {
  const { title, children, className = '', hasPadding = true, rightHeader } = props;

  return (
    <Card
      className={classNames({
        [styles.largeCard]: true,
        [className]: Boolean(classNames),
      })}
      title={title}
      extra={rightHeader}
    >
      <div
        className={classNames({
          [styles.hasPadding]: hasPadding,
        })}
      >
        {children}
      </div>
    </Card>
  );
};

export default StatisticsCard;
