import { createSlice } from "@reduxjs/toolkit";

import { getSuperAdminRecentApplicantsChartAsync } from "./thunks";
import { JobApplicationsChartState } from "./types";

const initialState: JobApplicationsChartState = {
  jobApplicationsChart: {
    applications: {
      this_month: [],
      last_month: [],
      all_year: [],
    },
    views: {
      this_month: [],
      last_month: [],
      all_year: [],
    },
  },
  adminRecentApplicantsChartStatus: "initial",
};

export const getRecentApplicantsChartSlice = createSlice({
  name: "admin/jobApplicationsChart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getSuperAdminRecentApplicantsChartAsync.pending,
      (state) => {
        state.adminRecentApplicantsChartStatus = "started";
      }
    );
    builder.addCase(
      getSuperAdminRecentApplicantsChartAsync.fulfilled,
      (state, action) => {
        state.adminRecentApplicantsChartStatus = "done";
        state.jobApplicationsChart = action.payload.results;
      }
    );
    builder.addCase(
      getSuperAdminRecentApplicantsChartAsync.rejected,
      (state) => {
        state.adminRecentApplicantsChartStatus = "failed";
      }
    );
  },
});

export default getRecentApplicantsChartSlice;
