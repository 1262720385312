import { createSlice } from "@reduxjs/toolkit";

import { getProfilePercentageAsync } from "./thunks";
import { ProfilePercentageState } from "./types";

const initialState: ProfilePercentageState = {
  percentageCompanyProfile: {
    company_profile_percentage: 0,
  },
  profilePercentagRequestStatus: "initial",
};

export const getProfilePercentageSlice = createSlice({
  name: "admin/dashboard/getProfilePercentage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfilePercentageAsync.pending, (state) => {
      state.profilePercentagRequestStatus = "started";
    });
    builder.addCase(getProfilePercentageAsync.fulfilled, (state, action) => {
      state.profilePercentagRequestStatus = "done";
      state.percentageCompanyProfile = action.payload.results;
    });
    builder.addCase(getProfilePercentageAsync.rejected, (state) => {
      state.profilePercentagRequestStatus = "failed";
    });
  },
});

export default getProfilePercentageSlice;
