//public routes

export const ROOT = "/";
const JOBS = "/jobs";
const JOB_DETAILS = "/jobs/:id";
const JOB_LIST = "/jobslists";
const JOB_DETAILS_LIST = "/jobslists/:id";
const COMPANIES = "/companies";
const COMPANY_DETAILS = "/companies/:id";
const SERVICES = "/services";
const BLOG = "/blog";
const SIGN_UP = "/signup";
const LOGIN = "/login";
const ACTIVATE_ACCOUNT = "/activate-account";
const FORGOT_PASSWORD = "/forgot-password";
const RESET_PASSWORD = "/reset-password";

// admin
const ADMIN_HOME = "/admin";
const ADMIN_DASHBOARD = `${ADMIN_HOME}/dashboard`;
const ADMIN_JOBS = `${ADMIN_HOME}/jobs`;
const ADMIN_JOB_APPLICATANS = `${ADMIN_HOME}/applicants`;
const ADMIN_JOB_QUERIES = `${ADMIN_HOME}/queries`;
const ADMIN_PROFILE = `${ADMIN_HOME}/profile`;
const ADMIN_CREATE_JOBS = `${ADMIN_HOME}/create-jobs`;
const ADMIN_EDIT_JOBS = `${ADMIN_HOME}/edit-jobs/:jobId`;

//superadmin
const SUPER_ADMIN_HOME = "/superadmin";
const SUPER_ADMIN_DASHBOARD = `${SUPER_ADMIN_HOME}/dashboard`;
const SUPER_ADMIN_COMPANIES = `${SUPER_ADMIN_HOME}/companies`;
const SUPER_ADMIN_JOBS = `${SUPER_ADMIN_HOME}/jobs`;
const SUPER_ADMIN_JOB_APPLICATANS = `${SUPER_ADMIN_HOME}/applicants`;
const SUPER_ADMIN_JOB_QUERIES = `${SUPER_ADMIN_HOME}/queries`;
const SUPER_ADMIN_TAGS = `${SUPER_ADMIN_HOME}/tags`;
const SUPER_ADMIN_PROFILE = `${SUPER_ADMIN_HOME}/profile`;

export const ROUTES = {
  PATHS: {
    PUBLIC: {
      ROOT,
      LOGIN,
      SIGN_UP,
      JOBS,
      JOB_DETAILS,
      JOB_LIST,
      JOB_DETAILS_LIST,
      COMPANIES,
      COMPANY_DETAILS,
      SERVICES,
      BLOG,
      ACTIVATE_ACCOUNT,
      FORGOT_PASSWORD,
      RESET_PASSWORD,
    },

    ADMIN: {
      ADMIN_HOME,
      ADMIN_DASHBOARD,
      ADMIN_JOBS,
      ADMIN_JOB_APPLICATANS,
      ADMIN_JOB_QUERIES,
      ADMIN_PROFILE,
      ADMIN_CREATE_JOBS,
      ADMIN_EDIT_JOBS,
    },
    SUPER_ADMIN: {
      SUPER_ADMIN_HOME,
      SUPER_ADMIN_DASHBOARD,
      SUPER_ADMIN_COMPANIES,
      SUPER_ADMIN_JOBS,
      SUPER_ADMIN_JOB_APPLICATANS,
      SUPER_ADMIN_JOB_QUERIES,
      SUPER_ADMIN_TAGS,
      SUPER_ADMIN_PROFILE,
    },
  },
  COMPANY_DETAILS_ID: (id: string) => COMPANY_DETAILS.replace(":id", id),
  JOB_DETAILS_ID: (id: string) => JOB_DETAILS.replace(":id", id),
  JOB_DETAILS_LIST_ID: (id: string) => JOB_DETAILS_LIST.replace(":id", id),
  ADMIN_JOB_EDIT_ID: (id: string) => ADMIN_EDIT_JOBS.replace(":jobId", id),
};
