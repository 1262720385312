import React, { FC, useState } from "react";
import styles from "./jobs.module.scss";
import TableHeader from "uicontainers/_shared/TableHeader";
import MasterTable from "components/tables/MasterTable";
import { COLUMNS } from "./constants";
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from "store/hooks";
import {
  getSuperAdminJobsAsync,
  getSuperAdminJobsCategoryAsync,
} from "store/superadmin/jobs/thunks";
import {
  selectTotalCount,
  selectCurrentPage,
  selectPageSize,
  createSuperAdminCJobsTableData,
} from "./selectors";
import { setCurrentPage, setPageSize } from "store/superadmin/jobs/actions";
import { OptionType } from "./types";
import { superAdminJobs } from "store/superadmin/jobs/selectors";
import { PaginationProps } from "antd";
import { getSuperAdminCompanyNameAsync } from "store/superadmin/companies/thunks";
import { superAdminCompanyNames } from "store/superadmin/companies/selectors";
// import { transformTripsToTableData } from "./helpers";

const Jobs: FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedSecondValue, setSelectedSecondValue] = useState("");
  const [selectedThirdValue, setSelectedThirdValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const pageSize = useAppSelector(selectPageSize);
  const dispatch = useAppDispatch();

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSecondSelect = (value: string) => {
    setSelectedSecondValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleThirdSelect = (value: string) => {
    setSelectedThirdValue(value);
    dispatch(setCurrentPage(1));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRangeChange = (_: any, dateStr: any) => {
    setStartDate(dateStr[0]);
    setEndDate(dateStr[1]);
    dispatch(setCurrentPage(1));
  };

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const unwrap = useUnwrapAsyncThunk();
  React.useEffect(() => {
    unwrap(getSuperAdminCompanyNameAsync());
    unwrap(getSuperAdminJobsCategoryAsync());
  }, []);

  const data = useAppSelector(superAdminJobs).superadmin_jobs_categories;
  const companyNameData = useAppSelector(superAdminCompanyNames);
  const options: OptionType[] = companyNameData.map((company) => ({
    name: company.company_name,
    value: company.id.toString(),
  }));
  const extraOptions: OptionType[] = data.map((job) => ({
    name: job.name,
    value: job.id.toString(),
  }));

  const secondExtrapOptions: OptionType[] = [
    { name: "FULL TIME", value: "FULL_TIME" },
    { name: "PART TIME", value: "PART_TIME" },
    { name: "CONTRACT", value: "CONTRACT" },
    { name: "FREELANCING", value: "FREELANCING" },
  ];

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, company: selectedValue };
    }
    if (selectedSecondValue) {
      searchParams = {
        ...searchParams,
        job_category: selectedSecondValue,
      };
    }
    if (selectedThirdValue) {
      searchParams = { ...searchParams, job_type: selectedThirdValue };
    }
    if (startDate) {
      searchParams = { ...searchParams, start_date: startDate };
    }
    if (endDate) {
      searchParams = { ...searchParams, end_date: endDate };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    if (pageSize) {
      searchParams = { ...searchParams, page_size: pageSize };
    }
    dispatch(getSuperAdminJobsAsync(searchParams));
  }, [
    searchValue,
    selectedValue,
    selectedSecondValue,
    selectedThirdValue,
    currentPage,
    pageSize,
    endDate,
  ]);
  const tableData = useAppSelector(createSuperAdminCJobsTableData);

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    currentPage,
    pageSize
  ) => {
    dispatch(setCurrentPage(currentPage));
    dispatch(setPageSize(pageSize));
  };
  return (
    <div className={styles.root}>
      <>
        <TableHeader
          title="Jobs"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Jobs"
          options={options}
          onChange={handleRangeChange}
          extraOptions={extraOptions}
          secondExtrapOptions={secondExtrapOptions}
          oneThirdSelectChange={handleThirdSelect}
          onSecondSelectChange={handleSecondSelect}
          placeholder="Company"
          extraPlaceholder="Job Category"
          secondExtraPlaceholder="Job Type"
          firstShowSearch={true}
        />
        <MasterTable
          data={[...tableData]}
          columns={COLUMNS}
          pagination={{
            currentPage: currentPage,
            count: totalCount,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            onChange,
          }}
        />
      </>
    </div>
  );
};

export default Jobs;
