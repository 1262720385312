import React, { FC } from "react";
import { Menu } from "antd";

import { useUpdateSuperAdminBasicInfo } from "./hooks";
import { MenuActionsProps } from "./types";
import DetailsJobModal from "components/JobDetails/DetailsJobModal/DetailsJobModal";

const MenuActions: FC<MenuActionsProps> = (props) => {
  const { job, setVisible } = props;
  const updateJobs = useUpdateSuperAdminBasicInfo();
  const [isShowJobDetailsModal, setIsShowJobDetailsModal] = React.useState<
    boolean
  >(false);
  // @ts-ignore
  const handleStatusChange = (info: MenuInfo) => {
    setVisible(false);
    if (info.key === "PUBLISHED") {
      updateJobs({ publish_status: "PUBLISHED" }, job.id);
    }
    if (info.key === "UNPUBLISHED") {
      updateJobs({ publish_status: "UNPUBLISHED" }, job.id);
    }
    if (info.key === "PREVIEW") {
      setVisible(false);
      setIsShowJobDetailsModal(true);
    }
  };
  return (
    <>
      <Menu onClick={handleStatusChange}>
        {job?.publish_status !== "PUBLISHED" && (
          <Menu.Item key="PUBLISHED">PUBLISHED</Menu.Item>
        )}
        {job?.publish_status !== "UNPUBLISHED" && (
          <Menu.Item key="UNPUBLISHED">UNPUBLISHED</Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item key="PREVIEW">PREVIEW</Menu.Item>
        <Menu.Divider />
      </Menu>

      <DetailsJobModal
        job={job}
        isShowJobDetailsModal={isShowJobDetailsModal}
        setIsShowJobDetailsModal={setIsShowJobDetailsModal}
      />
    </>
  );
};

export default MenuActions;
