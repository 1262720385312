import { createAsyncThunk } from "@reduxjs/toolkit";
import { getJobLocations } from "api/admin/jobLocations";

export const getJobLocationsAsync = createAsyncThunk(
  "admin/jobLocations/getJobLocations",
  async () => {
    const response = await getJobLocations();
    if (response && response?.results.length > 0) {
      response?.results.push({ id: 0, location: "Outside Japan" });
    }
    return response;
  }
);
