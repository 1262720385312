import { createSlice } from "@reduxjs/toolkit";
import { updateAdminAccountAsync } from "store/shared/auth/thunks";
import { InfoState } from "./types";


const initialState: InfoState = {
  personalInfo: {
    email: "",
    profile_picture: "",
    job_title: "",
    user_name: "",
  },
  status: "initial",
};

export const personalInfoSlice = createSlice({
         name: "admin/profile/update",
         initialState,
         reducers: {},
         extraReducers: (builder) =>
           builder
             .addCase(updateAdminAccountAsync.pending, (state) => {
               state.status = "initial";
             })
             .addCase(updateAdminAccountAsync.fulfilled, (state, action) => {
               state.personalInfo = {
                 ...state.personalInfo,
                 ...action.payload,
               };
               state.status = "done";
             })

             .addCase(updateAdminAccountAsync.rejected, (state) => {
               state.status = "failed";
             }),
       });

export default personalInfoSlice;
