import { Column } from "components/tables/MasterTable/types";

// Objects

export const COLUMNS: Column[] = [
  {
    name: "Company Name",
    key: "company_name",
    type: "text",
  },
  // {
  //   name: "Contact",
  //   key: "contact_name",
  //   type: "text",
  // },
  {
    name: "Admin User Email",
    key: "admin_user_email",
    type: "text",
  },

  {
    name: "Employee",
    key: "number_of_employees",
    type: "num",
  },
  {
    name: "Location",
    key: "company_location",
    type: "text",
  },
  {
    name: "Jobs",
    key: "jobs_count",
    type: "num",
  },
  {
    name: "Signup date",
    key: "date_joined",
    type: "text",
  },
  {
    name: "Status",
    key: "active_status",
    type: "health",
  },
  {
    name: "",
    key: "three_dot_btn",
    type: "action-button-cell",
  },
];
