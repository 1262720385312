import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getApplicantsAsync } from "./thunks";
import { ApplicantsState } from "./types";

const initialState: ApplicantsState = {
  applicants: [],
  totalCount: 0,
  currentPage: 1,
  pageSize: 10,
  applicantsRequestStatus: "initial",
};

export const getApplicantsSlice = createSlice({
  name: "admin/applicants",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplicantsAsync.pending, (state) => {
      state.applicantsRequestStatus = "started";
    });
    builder.addCase(getApplicantsAsync.fulfilled, (state, action) => {
      state.applicantsRequestStatus = "done";
      state.totalCount = action.payload.count;
      state.applicants = action.payload.results;
    });
    builder.addCase(getApplicantsAsync.rejected, (state) => {
      state.applicantsRequestStatus = "failed";
    });
  },
});

export default getApplicantsSlice;
