import {
  loginAsync,
  signupAsync,
  updateAccountAsync,
  getProfileAsync,
} from "./thunks";
import slice from "./slice";

const {
  setAccessToken,
  setUserData,
  setIsAuthenticated,
  setIsNewAccount,
  setSignupStatus,
} = slice.actions;

export {
  loginAsync,
  signupAsync,
  setAccessToken,
  setUserData,
  setIsAuthenticated,
  setIsNewAccount,
  setSignupStatus,
  updateAccountAsync,
  getProfileAsync,
};
