import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getJobById,
  getJobs,
  homeOpenCompanies,
  homeOpenJobs,
  postJobViewById,
} from "api/public/jobs";
import {
  JobsRequestParams,
  HomeJobsRequestParams,
} from "api/public/jobs/types";

export const getJobsAsync = createAsyncThunk(
  "public/jobs/get_jobs",
  async (params?: JobsRequestParams | HomeJobsRequestParams) => {
    const response = await getJobs(params);
    return response;
  }
);
export const getMoreJobsAsync = createAsyncThunk(
  "public/jobs/get_more_jobs",
  async (params?: JobsRequestParams) => {
    const response = await getJobs(params);
    return response;
  }
);
export const homeOpenCompaniesAsync = createAsyncThunk(
  "public/jobs/home_open_companies",
  async () => {
    const response = await homeOpenCompanies();
    return response;
  }
);
export const GetJobById = createAsyncThunk(
  "public/jobs/get_job_by_id",
  async (id?: number | string) => {
    const response = await getJobById(id);
    return response;
  }
);

export const homeOpenJobsAsync = createAsyncThunk(
  "public/jobs/home_open_jobs",
  async () => {
    const response = await homeOpenJobs();
    return response;
  }
);

export const PostJobViewById = createAsyncThunk(
  "public/jobs/post_job_view_by_id",
  async (id: number | string) => {
    const response = await postJobViewById(id);
    return response;
  }
);
