import {
  adminAPI,
  authAPI,
  profileAPI,
  publicJobsAPI,
  silentAPI,
  superadminAPI,
} from "api/base";
import {
  AccountActivationData,
  ActivateAccount,
  PostPasswordReset,
  PostLogin,
  PostTokenVerify,
  PostChangePassword,
  UpdateAccount,
  UploadData,
  GetProfileDetails,
} from "./types";

export const login: PostLogin = (loginData) => {
  return authAPI.post("/token/", loginData);
};
export const refreshToken: ActivateAccount = (
  activationData: AccountActivationData
) => {
  const { securityCode, refresh } = activationData;
  return authAPI.post(`/refresh/${securityCode}/${refresh}/`);
};

export const signup: PostLogin = (signupData) => {
  return publicJobsAPI.post("/company-registration/", signupData);
};
export const passwordResetRequest: PostPasswordReset = (email: string) => {
  return authAPI.post("/password-reset/", email);
};
export const resetPassword: ActivateAccount = (
  activationData: AccountActivationData
) => {
  return authAPI.post(`/password-reset-done/`, activationData);
};

export const activateAccount: ActivateAccount = (
  activationData: AccountActivationData
) => {
  const { securityCode, token } = activationData;
  return authAPI.get(`/activate/${securityCode}/${token}/`);
};

export const verifyToken: PostTokenVerify = (verificationData) => {
  return silentAPI.post("/token-verify/", { token: verificationData });
};

export const changePassword: PostChangePassword = (data) => {
  return authAPI.post(`/change-password/`, data);
};

export const updateAccount: UpdateAccount = (data, id) => {
  return superadminAPI.patch(`/dashboard-account/${id}/`, data);
};

export const uploadData: UploadData = (data, id) => {
  return superadminAPI.patch(`/dashboard-account/${id}/`, data);
};

export const updateAdminAccount: UpdateAccount = (data, id) => {
  return adminAPI.patch(`/dashboard-user-profile/${id}/`, data);
};

export const uploadDataAdmin: UploadData = (data, id) => {
  return adminAPI.patch(`/dashboard-user-profile/${id}/`, data);
};
export const getProfileDetails: GetProfileDetails = () => {
  return profileAPI.get(`/profile-details/`);
};
export const getCompanyProfileDetails: GetProfileDetails = () => {
  return adminAPI.get(`/dashboard-company-profile/`);
};
export const updateCompanyProfile: UpdateAccount = (data, id) => {
  return adminAPI.patch(`/dashboard-company-profile/${id}/`, data);
};
export const uploadDataCompany: UploadData = (data, id) => {
  return adminAPI.patch(`/dashboard-company-profile/${id}/`, data);
};
