import { JobApplicationsChart } from "api/superadmin/Dashboard/job-applications-chart/types";
import { getSortByMonth } from "shared/helpers/chartHelper";
import { ChartSeriesType, JobApplicationsReportCategory } from "./types";

export const prepareChartData = (
  jobchartdata: JobApplicationsChart,
  reportCategory: JobApplicationsReportCategory
) => {
  if (reportCategory === "all_year") {
    const allYearApplications = jobchartdata?.applications.all_year.map(
      (item) => {
        return {
          month: item.month,
          count: item.count,
          type: ChartSeriesType.APPLICATIONS,
        };
      }
    );
    const allYearViews = jobchartdata?.views.all_year.map((item) => {
      return {
        month: item.month,
        count: item.count,
        type: ChartSeriesType.VIEWS,
      };
    });
    return [
      ...getSortByMonth(allYearApplications),
      ...getSortByMonth(allYearViews),
    ];
  } else if (reportCategory === "last_month") {
    const lastMonthApplications = jobchartdata?.applications.last_month.map(
      (item) => {
        return {
          date: item.date,
          count: item.count,
          type: ChartSeriesType.APPLICATIONS,
        };
      }
    );
    const lastMonthViews = jobchartdata?.views.last_month.map((item) => {
      return {
        date: item.date,
        count: item.count,
        type: ChartSeriesType.VIEWS,
      };
    });
    return [...lastMonthApplications, ...lastMonthViews];
  } else if (reportCategory === "this_month") {
    const thisMonthApplications = jobchartdata?.applications.this_month.map(
      (item) => {
        return {
          date: item.date,
          count: item.count,
          type: ChartSeriesType.APPLICATIONS,
        };
      }
    );
    const thisMonthViews = jobchartdata?.views.this_month.map((item) => {
      return {
        date: item.date,
        count: item.count,
        type: ChartSeriesType.VIEWS,
      };
    });
    return [...thisMonthApplications, ...thisMonthViews];
  }

  return [];
};
