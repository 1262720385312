import { createAsyncThunk } from "@reduxjs/toolkit";

import { getAdminSummary } from "api/superadmin/Dashboard/summary";

export const getSuperadminSummaryAsync = createAsyncThunk(
  "superadmin/summary",
  async () => {
    const response = await getAdminSummary();
    return response;
  }
);
