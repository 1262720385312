import { createSlice } from "@reduxjs/toolkit";
import { handleAskMoreAsync } from "./thunks";
import { AskMoreState } from "./types";


const initialState: AskMoreState = {
  askMore: null,
  AskMoreCreateStatus: "initial",
};

export const askMoreSlice = createSlice({
  name: "apply-now",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleAskMoreAsync.pending, (state) => {
        state.AskMoreCreateStatus = "started";
      })
      .addCase(handleAskMoreAsync.fulfilled, (state, action) => {
        state.AskMoreCreateStatus = "done";
        state.askMore = action.payload.results;
      })
      .addCase(handleAskMoreAsync.rejected, (state) => {
        state.AskMoreCreateStatus = "failed";
      });
  },
});

export default askMoreSlice;