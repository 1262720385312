import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "shared/routes/constants";
import slackIcon from "assets/images/slack.svg";
import clubHouseIcon from "assets/images/clubhouse.svg";
import twitterIcon from "assets/images/social/twitter.svg";
import facebookIcon from "assets/images/social/facebook.svg";
import instagramIcon from "assets/images/social/instagram.svg";
import linkedinIcon from "assets/images/social/linkedin.svg";
import Styles from "./navigationFooter.module.scss";

const NavigationFooter: React.FC = () => {
  return (
    <div className={Styles.navigationFooterContainer}>
      <Row gutter={[8, 0]}>
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <h2>Services</h2>
          <ul>
            <li>
              <a href="https://www.businessinjapan.com/strategicpartners/company-setup/">
                Company Setup
              </a>
            </li>
            <li>
              <a href="https://www.businessinjapan.com/strategicpartners/accounting-tax/">
                Accounting and tax
              </a>
            </li>
            <li>
              <a href="https://www.businessinjapan.com/strategicpartners/translation/">
                Translation/Interpretation
              </a>
            </li>
            <li>
              <a href="https://www.businessinjapan.com/strategicpartners/it-services/">
                IT Services
              </a>
            </li>
            <li>
              <a href="https://www.businessinjapan.com/strategicpartners/web-development/">
                Web Development
              </a>
            </li>
            <li>
              <a href="https://www.businessinjapan.com/strategicpartners/market-entry-research/">
                Market Entry & Research
              </a>
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <h2>Jobs</h2>
          <ul>
            <li>
              <Link to={ROUTES.PATHS.PUBLIC.JOBS}>Find Jobs</Link>
            </li>
            <li>
              <Link to={ROUTES.PATHS.PUBLIC.LOGIN}>Post a Job</Link>
            </li>
            <li>
              <Link to={ROUTES.PATHS.PUBLIC.COMPANIES}>Companies</Link>
            </li>
            <li>
              <Link to={ROUTES.PATHS.PUBLIC.LOGIN}>Login</Link>
            </li>
            <li>
              <Link to={ROUTES.PATHS.PUBLIC.SIGN_UP}>Sign up</Link>
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <h2>Business in Japan</h2>
          <ul>
            <li>
              <a href="https://www.businessinjapan.com/about/">About</a>
            </li>
            <li>
              <a href="https://www.businessinjapan.com/blog/">Blog</a>
            </li>
            <li>
              <a href="https://www.businessinjapan.com/bij-events/">Events</a>
            </li>
            {/* <li>
              <a href="#">Partnership</a>
            </li> */}
            <li>
              <a href="https://www.businessinjapan.com/contact/">Contact</a>
            </li>
          </ul>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
          <div className={Styles.eventsSection}>
            <h2>Find BIJ Events or Rooms</h2>
            <Row align="middle">
              <img src={slackIcon} alt="Slack" />
              <span className={Styles.messageChannel} id={Styles.slack}>
                Slack
              </span>
            </Row>
            <Row align="middle" style={{ flexWrap: "nowrap" }}>
              <a
                href="https://www.clubhouse.com/club/business-in-japan"
                target="_blank"
                rel="noreferrer"
              >
                <img src={clubHouseIcon} alt="Clubhouse" />
                <span className={Styles.messageChannel}>Clubhouse</span>
              </a>
            </Row>
            <p className={Styles.socialMediaTile}>Connect on Social Media</p>
            <Row>
              <a
                href="https://twitter.com/bijwebportal"
                target="_blank"
                rel="noreferrer"
              >
                <div className={Styles.socialMediaIcon}>
                  <img src={twitterIcon} alt="Twitter" />
                </div>
              </a>
              <a
                href="https://www.facebook.com/businessinjapan"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={Styles.socialMediaIcon}
                  id={Styles.facebookIcon}
                >
                  <img src={facebookIcon} alt="Facebook" />
                </div>
              </a>
              <a
                href="https://www.instagram.com/goodpeople/"
                target="_blank"
                rel="noreferrer"
              >
                <div className={Styles.socialMediaIcon}>
                  <img src={instagramIcon} alt="Instagram" />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/groups/54168"
                target="_blank"
                rel="noreferrer"
              >
                <div className={Styles.socialMediaIcon}>
                  <img src={linkedinIcon} alt="Linkedin" />
                </div>
              </a>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NavigationFooter;
