import React, { FC } from "react";
import classnames from "classnames";

import styles from "./numberCell.module.scss";
import { Props } from "./types";

export const NumberCell: FC<Props> = (props) => {
  return (
    <div
      className={classnames({
        [styles.container]: true,
        [styles.bold]: props.fontWeight === "bold",
      })}
    >
      {Array.isArray(props.num)
        ? props.num.map((num, index) => (
            <div className={styles.row} key={`${num}-${index}`}>
              {num}
            </div>
          ))
        : props.num}
    </div>
  );
};

export default NumberCell;
