import { FC, useEffect } from "react";
import React from "react";
import { Progress } from "antd";
import { Row, Col } from "antd";
import { Space } from "antd";
import styles from "./companyProfile.module.scss";
import { getProfilePercentageAsync } from "../../../../store/admin/Dashboard/profile-percentage/actions";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import { adminProfilePercentage } from "store/admin/Dashboard/profile-percentage/selectors";
const CompanyProfile: FC = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getProfilePercentageAsync());
  }, []);
  const { percentageCompanyProfile } = useAppSelector(adminProfilePercentage);
  return (
    <div className={styles.container}>
      <Row>
        <Col xs={8} sm={8} md={4} lg={4} xl={3}>
          <Row justify="center" align="middle" style={{ height: "100%" }}>
            <Progress
              type="circle"
              percent={percentageCompanyProfile.company_profile_percentage}
              width={85}
              strokeWidth={12}
              strokeLinecap="square"
              showInfo={false}
              trailColor="#0000008A"
              strokeColor="#BC012D"
            />
          </Row>
        </Col>
        <Col xs={16} sm={16} md={20} lg={20} xl={21}>
          <Space
            direction="vertical"
            style={{ display: "flex", padding: "20px 20px" }}
          >
            <h6 className={styles.title}>Company Profile</h6>

            <h1 className={styles.process}>
              {percentageCompanyProfile.company_profile_percentage}% Compelete
            </h1>
            {percentageCompanyProfile.company_profile_percentage < 100 && (
              <p className={styles.information}>
                Please fill out the remaining information in the Account Section
                to complete your Company Profile
              </p>
            )}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyProfile;
