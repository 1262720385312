import { createAsyncThunk } from "@reduxjs/toolkit";
import { getJobApplicantionsChart } from "api/superadmin/Dashboard/job-applications-chart";

export const getSuperAdminRecentApplicantsChartAsync = createAsyncThunk(
  "super-admin/recent-applicant-chart",
  async () => {
    const response = await getJobApplicantionsChart();
    return response;
  }
);
