import {
  getCompanyProfileAsync,
  updateCompanyAccountAsync,
} from "./../../../store/shared/auth/thunks";
import { useCallback } from "react";
import { useUnwrapAsyncThunk } from "store/hooks";
import { COMPANY_ID } from "api/base/constants";
import { message } from "antd";
import { SignupPostData } from "api/shared/auth/types";

export const useUpdateCompanyInfo = () => {
  const unwrap = useUnwrapAsyncThunk();
  return useCallback((companyInfo: Partial<SignupPostData>) => {
    const userId = localStorage.getItem(COMPANY_ID);
    if (userId) {
      unwrap(
        updateCompanyAccountAsync({
          data: companyInfo,
          id: parseInt(userId, 10),
        })
      ).then(() => {
        unwrap(getCompanyProfileAsync()).then(() => {
          message.success("Company profile updated successfully", 0.5);
        });
      });
    }
  }, []);
};
