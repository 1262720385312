import TextCell from "../cells/TextCell";
import HealthCell from "../cells/HealthCell";
import ActionButtonCell from "../cells/ActionButtonCell";

import { Cells } from "./types";
import NumberCell from "../cells/NumberCell";

export const CELLS: Cells = {
  text: TextCell,
  health: HealthCell,
  "action-button-cell": ActionButtonCell,
  num: NumberCell,
};

export const DEFAULT_PAGE_SIZE = 10;
