import { createSlice } from "@reduxjs/toolkit";
import { updateAccountAsync } from "store/shared/auth/thunks";
import { InfoState } from "./types";

const initialState: InfoState = {
  personalInfo: {
    email: "",
    profile_picture: "",
    job_title: "",
    user_name: "",
  },
  status: "initial",
};

export const personalInfoSlice = createSlice({
  name: "super-admin/profile/update",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(updateAccountAsync.fulfilled, (state, action) => {
        state.personalInfo = {
          ...state.personalInfo,
          ...action.payload,
        };
        state.status = "done";
      })

      .addCase(updateAccountAsync.rejected, (state) => {
        state.status = "failed";
      }),
});

export default personalInfoSlice;
