import { job_type, publish_status } from "api/admin/jobs/types";
import { HealthTagProps } from "components/tables/cells/HealthCell/types";
import { Column } from "components/tables/MasterTable/types";

export const COLUMNS: Column[] = [
  {
    name: "Job Title",
    key: "job_title",
    type: "text",
  },
  {
    name: "Job Category",
    key: "job_category_name",
    type: "text",
  },
  {
    name: "Location",
    key: "job_location",
    type: "text",
  },
  {
    name: "Type",
    key: "job_type",
    type: "health",
  },
  {
    name: "Salary",
    key: "salary",
    type: "text",
  },
  {
    name: "Publish Date",
    key: "publish_date",
    type: "text",
  },
  {
    name: "Status",
    key: "publish_status",
    type: "health",
  },
  {
    name: "",
    key: "three_dot_btn",
    type: "action-button-cell",
  },
];

export const JOB_PUBLISH_STATUS_TAG_PROPS: Record<
  publish_status,
  HealthTagProps
> = {
  PUBLISHED: {
    name: "PUBLISHED",
    color: "green",
  },
  UNPUBLISHED: {
    name: "UNPUBLISHED",
    color: "default",
  },
};

export const JOB_TYPES_TAG_PROPS: Record<job_type, HealthTagProps> = {
  FULL_TIME: {
    name: "FULL_TIME",
    color: "#A68DFF",
  },
  PART_TIME: {
    name: "PART_TIME",
    color: "#FFB94D",
  },
  CONTRACT: {
    name: "CONTRACT",
    color: "#FF8AC1",
  },
  FREELANCING: {
    name: "FREELANCING",
    color: "#76B0FF",
  },
};
