import React, { FC } from "react";
import { Menu } from "antd";
import { useUpdateSuperAdminBasicInfo, useDeleteAdminJob } from "./hooks";
import { MenuActionsProps } from "./types";
import { Link } from "react-router-dom";
import { ROUTES } from "shared/routes/constants";
import DeleteConfirmationModal from "components/Modals/DeleteConfirmationModal";
import DetailsJobModal from "components/JobDetails/DetailsJobModal/DetailsJobModal";

const MenuActions: FC<MenuActionsProps> = (props) => {
  const [isShowDeleteModal, setIsShowDeleteModal] = React.useState<boolean>(
    false
  );
  const [isShowJobDetailsModal, setIsShowJobDetailsModal] = React.useState<
    boolean
  >(false);
  const { job, setVisible } = props;
  const updateJobs = useUpdateSuperAdminBasicInfo();
  const deleteJob = useDeleteAdminJob();

  const jobDeleteClickHandler = () => {
    deleteJob(job?.id);
    setIsShowDeleteModal(false);
    setVisible(false);
  };

  // @ts-ignore
  const handleStatusChange = (info: MenuInfo) => {
    if (info.key === "PUBLISHED") {
      setVisible(false);
      updateJobs({ publish_status: "PUBLISHED" }, job.id);
    }
    if (info.key === "UNPUBLISHED") {
      setVisible(false);
      updateJobs({ publish_status: "UNPUBLISHED" }, job.id);
    }
    if (info.key === "PREVIEW") {
      setVisible(false);
      setIsShowJobDetailsModal(true);
    }
    if (info.key === "DELETE") {
      setVisible(false);
      setIsShowDeleteModal(!isShowDeleteModal);
    }
  };

  return (
    <>
      <Menu onClick={handleStatusChange}>
        {job?.publish_status !== "PUBLISHED" && (
          <Menu.Item key="PUBLISHED">PUBLISHED</Menu.Item>
        )}
        {job?.publish_status !== "UNPUBLISHED" && (
          <Menu.Item key="UNPUBLISHED">UNPUBLISHED</Menu.Item>
        )}
        <Menu.Divider />
        <Menu.Item key="PREVIEW">PREVIEW</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="EDIT">
          <Link to={ROUTES.ADMIN_JOB_EDIT_ID(job?.id.toString())}>EDIT</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="DELETE">DELETE</Menu.Item>
        <Menu.Divider />
      </Menu>

      <DetailsJobModal
        job={job}
        isShowJobDetailsModal={isShowJobDetailsModal}
        setIsShowJobDetailsModal={setIsShowJobDetailsModal}
      />

      <DeleteConfirmationModal
        isShowDeleteModal={isShowDeleteModal}
        setIsShowDeleteModal={setIsShowDeleteModal}
        deleteClickHandler={jobDeleteClickHandler}
      />
    </>
  );
};

export default MenuActions;
