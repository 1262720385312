import React from "react";
import { SuperAdminCompanies } from "api/superadmin/companies/types";
import { dateTimeGenerator } from "shared/helpers/helperFunctions";
import ActionButton from "./ActionButton";

import { companyType } from "./types";

const getTitleCase = (str: string) => {
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

export const transformSuperAdminCompaniesToTableData = (
  companies: SuperAdminCompanies[]
): companyType[] => {
  return companies.map((company) => ({
    key: company.id.toString(),
    company_name: {
      text: company.company_name,
    },
    contact_name: {
      text: company.contact_name,
    },
    industry: {
      text: company.industry,
    },
    contact_email: {
      text: company.contact_email,
    },
    number_of_employees: {
      num: Number(company.number_of_employees)
        ? Number(company.number_of_employees)
        : 0,
    },
    admin_user_email: {
      text: company.admin_user_email,
    },
    company_location: {
      text: company.company_location,
    },
    date_joined: {
      text: `${dateTimeGenerator(company.date_joined)}`,
    },
    jobs_count: {
      num: Number(company.jobs_count) ? Number(company.jobs_count) : 0,
    },
    active_status: {
      tags: [
        {
          name: getTitleCase(company.active_status),
          color:
            company.active_status === "ACTIVE"
              ? "green"
              : company.active_status === "NEW"
              ? "blue"
              : "red",
        },
      ],
    },
    three_dot_btn: {
      buttons: [
        {
          icon: <ActionButton company={company} />,
          onClick: () => {},
        },
      ],
    },
  }));
};
