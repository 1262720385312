import { createSelector } from "reselect";

import { transformAdminJobsToTableData } from "./helpers";

import { RootState } from "store/types";

export const adminJobsTableData = (state: RootState) =>
  state.resources.admin.adminJobs.jobs;

export const createAdminJobsTableData = createSelector(
  adminJobsTableData,
  transformAdminJobsToTableData
);
export const selectTotalCount = (state: RootState) =>
  state.resources.admin.adminJobs.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.admin.adminJobs.currentPage;

export const selectPageSize = (state: RootState) =>
  state.resources.admin.adminJobs.pageSize;
