import { Modal } from "antd";
import Button from "components/Button";
import { FC } from "react";
import { Props } from "./types";
import styles from "./deleteConfirmationModal.module.scss";

const DeleteConfirmationModal: FC<Props> = ({
  isShowDeleteModal,
  setIsShowDeleteModal,
  deleteClickHandler,
}) => {
  return (
    <Modal
      centered
      visible={isShowDeleteModal}
      mask={false}
      onCancel={() => setIsShowDeleteModal(false)}
      title={<>Delete Job</>}
      footer={[
        <Button
          key="back"
          isBordered={true}
          onClick={() => setIsShowDeleteModal(false)}
        >
          Cancel
        </Button>,
        <Button
          key="1"
          type="ghost"
          htmlType="submit"
          form="applyNowForm"
          onClick={deleteClickHandler}
        >
          Delete
        </Button>,
      ]}
    >
      <h6 className={styles.modalMessage}>Are you sure to delete?</h6>
    </Modal>
  );
};

export default DeleteConfirmationModal;
