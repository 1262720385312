import { Column } from "components/tables/MasterTable/types";

export const COLUMNS: Column[] = [
  {
    name: "Tag Name",
    key: "job_tag",
    type: "text",
  },
  {
    name: "",
    key: "three_dot_btn",
    type: "action-button-cell",
  },
];
