import React, { FC } from "react";
import { Menu, message } from "antd";
import { MenuActionsProps } from "./types";
import TagModal from "./TagModal";
import { useUnwrapAsyncThunk } from "store/hooks";
import {
  getSuperAdminTagsAsync,
  deleteSuperAdminTagAsync,
} from "store/superadmin/tags/thunks";
import DeleteConfirmationModal from "components/Modals/DeleteConfirmationModal";

const MenuActions: FC<MenuActionsProps> = (props) => {
  const [isShowEditModal, setIsShowEditModal] = React.useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = React.useState<boolean>(
    false
  );
  const unwrap = useUnwrapAsyncThunk();
  const { tag, setDropdownVisible } = props;

  const tagDeleteClickHandler = () => {
    unwrap(deleteSuperAdminTagAsync(tag.id)).then(() => {
      message.success("Job Tag Successfully Deleted", 1);
      unwrap(getSuperAdminTagsAsync());
    });
    setIsShowDeleteModal(false);
    setDropdownVisible(false);
  };

  // @ts-ignore
  const handleStatusChange = (info: MenuInfo) => {
    if (info.key === "EDIT") {
      setIsShowEditModal(true);
    }
    if (info.key === "DELETE") {
      setIsShowDeleteModal(!isShowDeleteModal);
    }
  };

  return (
    <>
      <Menu onClick={handleStatusChange}>
        <Menu.Item key="EDIT">EDIT</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="DELETE">DELETE</Menu.Item>
        <Menu.Divider />
      </Menu>

      <DeleteConfirmationModal
        isShowDeleteModal={isShowDeleteModal}
        setIsShowDeleteModal={setIsShowDeleteModal}
        deleteClickHandler={tagDeleteClickHandler}
      />
      {isShowEditModal && (
        <TagModal
          visible={isShowEditModal}
          setVisible={setIsShowEditModal}
          tag={tag}
          setDropdownVisible={setDropdownVisible}
        />
      )}
    </>
  );
};

export default MenuActions;
