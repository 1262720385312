import { FC } from "react";
import { Props } from "./types";
import { Line } from "@ant-design/plots";

const LineChart: FC<Props> = (props) => {
  const layout: any = "horizontal";
  const position: any = "bottom";
  const symbol: any = "circle";
  const fill: any = "rgba(0,0,0,0.65)";
  const {
    data,
    yField,
    xField = "",
    xFieldAlias = "",
    yFieldAlias = "",
  } = props;

  const config = {
    data: data,
    autoFit: true,
    isStack: false,
    xField: xField,
    yField: yField,
    seriesField: "type",
    smooth: true,
    legend: {
      layout: layout,
      position: position,
      marker: {
        symbol: symbol,
        radius: 5,
      },
    },

    columnWidthRatio: 0.8,
    color: ["#17C7C2", "#FFB94D"],
    xAxis: {
      nice: true,
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
      },
    },
    meta: {
      [xField]: {
        alias: xFieldAlias,
      },
      [yField]: {
        alias: yFieldAlias,
      },
    },

    interactions: [
      {
        type: "active-region",
        enable: false,
      },
    ],
  };
  return <Line {...config} />;
};

export default LineChart;
