import {
  getProfileAsync,
  getCompanyProfileAsync,
  updateAdminAccountAsync,
  uploadAdminDataAsync,
  uploadCompanyDataAsync,
} from "./../../../store/shared/auth/thunks";
import { useCallback } from "react";
import { useUnwrapAsyncThunk } from "store/hooks";
import { PersonalInfoType } from "./types";
import { USER_ID, COMPANY_ID } from "api/base/constants";
import { nanoid } from "@reduxjs/toolkit";
import { UploadFile } from "antd/lib/upload/interface";
import { message } from "antd";

export const useUpdatePersonalInfo = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback((personalInfo: Partial<PersonalInfoType>) => {
    const userId = localStorage.getItem(USER_ID);

    if (userId) {
      unwrap(
        updateAdminAccountAsync({
          data: personalInfo,
          id: parseInt(userId, 10),
        })
      ).then(() => {
        unwrap(getProfileAsync()).then(() => {
          message.success("Profile updated successfully", 0.5);
        });
      });
    }
  }, []);
};
export const useUploadData = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback(async (data: FormData) => {
    const userId = localStorage.getItem(USER_ID);

    if (userId) {
      unwrap(uploadAdminDataAsync({ data, id: parseInt(userId, 10) })).then(
        () => {
          unwrap(getProfileAsync()).then(() => {
            message.success("Profile Image Upload successfully", 0.5);
          });
        }
      );
    }
  }, []);
};

export const useCompanyUploadData = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback(async (data: FormData) => {
    const userId = localStorage.getItem(COMPANY_ID);
    if (userId) {
      unwrap(uploadCompanyDataAsync({ data, id: parseInt(userId, 10) })).then(
        () => {
          unwrap(getCompanyProfileAsync()).then(() => {
            message.success("Company profile updated successfully", 0.5);
          });
        }
      );
    }
  }, []);
};

export const getFileName = (url: string): string => {
  return url.substr(url.lastIndexOf("/") + 1).replace(/[#?].*$/, "");
};

export const transformToUploadFile = (data: string | null): UploadFile[] => {
  if (data) {
    return [
      {
        uid: nanoid(5),
        name: getFileName(data),
        status: "done",
        url: data,
      },
    ];
  }
  return [];
};
