export type JobApplicationsReportCategory =
  | "this_month"
  | "last_month"
  | "all_year";
export interface JobApplicationsState {
  category: JobApplicationsReportCategory;
}
export interface IState {
  data: {
    month: string;
    earning: number;
    type: string;
  }[];
}

export enum ChartSeriesType {
  APPLICATIONS = "applications",
  VIEWS = "views",
}
