import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSuperAdminApplicants } from "api/superadmin/applicants";
import { SuperAdminApplicantsRequestParams } from "api/superadmin/applicants/types";

export const getSuperAdminApplicantsAsync = createAsyncThunk(
         "superadmin/getapplicants",
         async (params?: SuperAdminApplicantsRequestParams) => {
           const response = await getSuperAdminApplicants(params);
           return response;
         }
       );
