import { createAsyncThunk } from "@reduxjs/toolkit";
import { getQueries, updateQueriesReply } from "api/admin/queries";
import {
  QueriesRequestParams,
  UpdateAdminQueriesReply,
} from "api/admin/queries/types";

export const getQueriesAsync = createAsyncThunk(
  "admin/queries/getQueries",
  async (params?: QueriesRequestParams) => {
    const response = await getQueries(params);
    return response;
  }
);

export const updateQueriesAsync = createAsyncThunk(
  "admin/queries/queriesReply",
  async (data: UpdateAdminQueriesReply) => {
    const response = await updateQueriesReply(data, data.replyId);
    return response;
  }
);
