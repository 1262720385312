import React from "react";
import Button from "components/Button";
import { Col, Form, message, Modal, Row } from "antd";
import infoIcon from "../../../assets/images/info.svg";
import styles from "./queries.module.scss";
import { Queries } from "api/admin/queries/types";
import TextArea from "antd/lib/input/TextArea";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import {
  getQueriesAsync,
  updateQueriesAsync,
} from "store/admin/queries/thunks";
import { SuperAdminQueries } from "api/superadmin/queries/types";
import { adminQueries } from "store/admin/queries/selectors";
import { selectCurrentPage } from "./selectors";
import { getSuperAdminQueriesAsync } from "store/superadmin/queries/thunks";
import { selectCurrentPage as superAdminSelectCurrentPage } from "uicontainers/SuperAdmin/Queries/selectors";

type Props = {
  isSuperAdmin: boolean;
  query: Queries | SuperAdminQueries;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReplyModal = (props: Props) => {
  const { isSuperAdmin, query, visible, setVisible } = props;
  const id = query.id;
  const adminQueryReplayStatus = useAppSelector(adminQueries)
    .patchupdateQueriesReplyStatus;
  const unwrap = useUnwrapAsyncThunk();
  const adminQueryCurrentPage = useAppSelector(selectCurrentPage);
  const superAdminQueryCurrentPage = useAppSelector(
    superAdminSelectCurrentPage
  );

  const handleCancel = () => {
    setVisible(false);
  };

  const handleQueriesReply = (values: Queries) => {
    unwrap(updateQueriesAsync({ ...values, replyId: id }))
      .then(() => {
        message.success("Successfully Replied", 1).then(() => {
          setVisible(false);
        });
      })
      .then(() => {
        if (isSuperAdmin) {
          unwrap(
            getSuperAdminQueriesAsync({ page: superAdminQueryCurrentPage })
          );
        } else {
          unwrap(getQueriesAsync({ page: adminQueryCurrentPage }));
        }
      })
      .catch((e) => {
        // message.error(e.message);
      });
  };

  const getFooterButtons = () => {
    return query.reply
      ? [
          <Button
            key="back"
            onClick={handleCancel}
            className={styles.cancelButton}
          >
            Cancel
          </Button>,
        ]
      : [
          <Button
            key="back"
            onClick={handleCancel}
            className={styles.cancelButton}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="ghost"
            htmlType="submit"
            form="replyForm"
            loading={adminQueryReplayStatus === "started" || false}
          >
            Reply
          </Button>,
        ];
  };

  return (
    <Modal
      centered
      visible={visible}
      closable={false}
      title={
        <>
          <img src={infoIcon} alt="Info" style={{ marginRight: "10px" }} />
          <span style={{ marginTop: "10px" }}> {query.full_name}</span>
        </>
      }
      footer={getFooterButtons()}
    >
      <div className={styles.wrapper}>
        <p className={styles.queriesContainer}>{query.queries}</p>
        {query && query.reply ? (
          <>
            <h3>Reply</h3>
            <p className={styles.replyContainer}>{query.reply}</p>
          </>
        ) : (
          <Form
            onFinish={handleQueriesReply}
            id="replyForm"
            size="large"
            layout="vertical"
          >
            <Row gutter={[16, 0]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="reply"
                  label="Reply"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your reply",
                    },
                  ]}
                >
                  <TextArea
                    placeholder=""
                    showCount
                    maxLength={500}
                    style={{ height: 120 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default ReplyModal;
