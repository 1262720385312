import { createSelector } from "reselect";

import { transformSuperAdminJobsToTableData } from "./helpers";

import { RootState } from "store/types";

export const superAdminJobsData = (state: RootState) =>
  state.resources.superAdmin.superAdminJobs.superadmin_jobs;

export const createSuperAdminCJobsTableData = createSelector(
  superAdminJobsData,
  transformSuperAdminJobsToTableData
);

export const selectTotalCount = (state: RootState) =>
  state.resources.superAdmin.superAdminJobs.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.superAdmin.superAdminJobs.currentPage;

export const selectPageSize = (state: RootState) =>
  state.resources.superAdmin.superAdminJobs.pageSize;
