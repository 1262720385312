import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSuperAdminQueriesAsync } from "./thunks";
import { SuperAdminQueriesState } from "./types";

const initialState: SuperAdminQueriesState = {
  superadmin_queries: [],
  totalCount: 0,
  currentPage: 1,
  pageSize: 10,
  superadmin_quereiesRequestStatus: "initial",
};

export const getSuperAdminQueriesSlice = createSlice({
  name: "superadmin/queries",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSuperAdminQueriesAsync.pending, (state) => {
      state.superadmin_quereiesRequestStatus = "started";
    });
    builder.addCase(getSuperAdminQueriesAsync.fulfilled, (state, action) => {
      state.superadmin_quereiesRequestStatus = "done";
      state.totalCount = action.payload.count;
      state.superadmin_queries = action.payload.results;
    });
    builder.addCase(getSuperAdminQueriesAsync.rejected, (state) => {
      state.superadmin_quereiesRequestStatus = "failed";
    });
  },
});

export default getSuperAdminQueriesSlice;
