import { Col, Row, Tag } from "antd";
import DashboardCard from "components/DashboardCard";
import React, { FC } from "react";
import styles from "./companiesCard.module.scss";
import companyLogo from "../../assets/images/companyLogo.png";
import streetLoactaionIcon from "../../assets/images/streetLoactaionIcon.svg";
import peopleLogo from "../../assets/images/peopleLogo.svg";
import { Props } from "./types";
import { Link } from "react-router-dom";
import { Comapnaies } from "../../api/public/companies/types";
import { useIsExtraExtraSmall } from "shared/hooks";
import classNames from "classnames";
import Image from "components/Image/Image";

const CompaniesCard: FC<Props> = (props) => {
  const { companies } = props;
  const isExtraExtraSmall = useIsExtraExtraSmall();

  const getCardHeight = (company: Comapnaies) => {
    return company?.overview ? { height: "210px" } : {};
  };

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 32 }, 0]}>
          {companies.map((company) => (
            <Col lg={12} md={12} sm={24} xs={24} key={company?.id}>
              <Link to={`/companies/${company.id}`}>
                <DashboardCard
                  title=""
                  isActive={false}
                  className={styles.dasboardCardBody}
                  style={getCardHeight(company)}
                >
                  <Row key={company.id} style={{ width: "100%" }}>
                    <Col lg={2} md={2} sm={2} xs={3}>
                      <div>
                        <Image
                          src={
                            company.profile_picture === null
                              ? ""
                              : company.profile_picture
                          }
                          defaultSrc={companyLogo}
                          alt={company.company_name || "company logo"}
                          style={styles.imageLogo}
                        />
                      </div>
                    </Col>
                    <Col lg={22} md={22} sm={22} xs={21}>
                      <div
                        className={classNames({
                          [styles.content]: true,
                          [styles.isExtraExtra]: isExtraExtraSmall,
                        })}
                      >
                        <div className={styles.comapnyTitle}>
                          <h3
                            className={classNames({
                              [styles.companyName]: true,
                              [styles.isExtraExtra]: isExtraExtraSmall,
                            })}
                          >
                            {company.company_name}
                          </h3>
                          <Tag className={styles.tag}>
                            {company.jobs_count}{" "}
                            {company.jobs_count > 1 ? "Jobs" : "Job"}
                          </Tag>
                        </div>
                        <div className={styles.subTititle}>
                          <img
                            className={styles.companyLocationLogo}
                            src={streetLoactaionIcon}
                            alt="streetLoactaionIcon"
                          />

                          <h6 className={styles.companyLocation}>
                            {company.company_location === "Other"
                              ? company.other_location
                              : company.company_location}
                          </h6>
                          <img
                            className={styles.companyPeopleLogo}
                            src={peopleLogo}
                            alt="peopleLogo"
                          />
                          {company?.number_of_employees && (
                            <h6 className={styles.companyLocation}>
                              {company.number_of_employees}
                            </h6>
                          )}
                        </div>
                        {company?.overview && (
                          <div className={styles.companyDefination}>
                            <p className={styles.companyAbout}>
                              {company.overview}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </DashboardCard>
              </Link>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default CompaniesCard;
