import { Tabs as AntdTabs } from "antd";
import { Input as AntdInput } from "antd";
import { Radio as AntdRadio } from "antd";
import { Form as AntdForm } from "antd";
import { Steps as AntdSteps } from "antd";
import { Menu as AntdMenu } from "antd";
import { Dropdown as AntdDropdown } from "antd";
import { Layout as AntdLayout } from "antd";
import { Breadcrumb as AntdBreadcrumb } from "antd";
import { Popover as AntdPopover } from "antd";
import { Tooltip as AntdTooltip } from "antd";
import { Select as AntdSelect } from "antd";
import { DatePicker as AntdDatePicker } from "antd";
import { InputNumber as AntdInputNumber } from "antd";
import { Checkbox as AntdCheckbox } from "antd";
import { List as AntdList } from "antd";

export const Tabs = AntdTabs;
export const TabPane = Tabs.TabPane;

export const Steps = AntdSteps;
export const Step = Steps.Step;

export const Select = AntdSelect;
export const SelectOption = Select.Option;

export const Layout = AntdLayout;
export const Header = Layout.Header;
export const Sider = Layout.Sider;
export const Content = Layout.Content;
export const Footer = Layout.Footer;

export const Menu = AntdMenu;
export const SubMenu = Menu.SubMenu;
export const MenuItem = Menu.Item;

export const Breadcrumb = AntdBreadcrumb;
export const BreadcrumbItem = Breadcrumb.Item;

export const OptGroup = Select.OptGroup;
export const Option = Select.Option;

export const Form = AntdForm;
export const FormItem = AntdForm.Item;
export const useForm = AntdForm.useForm;

export const Popover = AntdPopover;

export const Tooltip = AntdTooltip;
export const Radio = AntdRadio;
export const RadioGroup = AntdRadio.Group;

export const Input = AntdInput;
export const Search = AntdInput.Search;

export const DatePicker = AntdDatePicker;

export const InputNumber = AntdInputNumber;

export const Checkbox = AntdCheckbox;

export const List = AntdList;
export const ListItem = AntdList.Item;
export const ListItemMeta = AntdList.Item.Meta;

export const Dropdown = AntdDropdown;
