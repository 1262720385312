import Sidebar from "components/Sidebar";
import React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";
import { ROUTES } from "shared/routes/constants";
import Dashboard from "uicontainers/SuperAdmin/Dashboard/Home";
import Jobs from "uicontainers/SuperAdmin/Jobs";
import Content from "uicontainers/Layout/Content";
import { getSideBarMenu } from "./helpers";
import Companies from "uicontainers/SuperAdmin/Companies";
import Queries from "uicontainers/SuperAdmin/Queries";
import Account from "uicontainers/SuperAdmin/Account";
import SuperAdminApplicants from "uicontainers/SuperAdmin/Applicants";
import SuperAdminTags from "uicontainers/SuperAdmin/Tags/Tags";

const SuperAdminLayout = () => {
  return (
    <Content leftSection={<Sidebar menuItems={getSideBarMenu()} />}>
      <Outlet />
    </Content>
  );
};
const SuperAdmin = () => {
  return [
    <Route
      path={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_HOME}
      element={
        <PrivateRoute>
          <SuperAdminLayout />
        </PrivateRoute>
      }
    >
      <Route
        index
        element={
          <Navigate
            replace
            to={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_DASHBOARD}
          />
        }
      />
      <Route
        path={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_DASHBOARD}
        element={<Dashboard />}
      />
      <Route
        path={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_COMPANIES}
        element={<Companies />}
      />
      {/*  */}
      <Route
        path={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_JOBS}
        element={<Jobs />}
      />
      <Route
        path={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_JOB_APPLICATANS}
        element={<SuperAdminApplicants />}
      />
      <Route
        path={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_JOB_QUERIES}
        element={<Queries />}
      />
      <Route
        path={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_TAGS}
        element={<SuperAdminTags />}
      />
      <Route
        path={ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_PROFILE}
        element={<Account />}
      />
    </Route>,
  ];
};

export default SuperAdmin;
