import { RootState } from "store/types";

export const selectIsAuthenticated = (state: RootState) =>
  state.ui.auth.isAuthenticated;

export const selectIsNewAccount = (state: RootState) =>
  state.ui.auth.isNewAccount;

export const selectLoginData = (state: RootState) => state.ui.auth.loginData;

export const selectSignUpRequestStatus = (state: RootState) =>
  state.ui.auth.signupStatus;

export const selectForgotPasswordRequestStatus = (state: RootState) =>
  state.ui.auth.forgotPasswordStatus;

export const selectActivateAccountRequestStatus = (state: RootState) =>
  state.ui.auth.activateAccountStatus;

export const selectVerifyTokenRequestStatus = (state: RootState) =>
  state.ui.auth.verifyTokenStatus;

export const selectResetPasswordRequestStatus = (state: RootState) =>
  state.ui.auth.resetPasswordStatus;

export const getProfileData = (state: RootState) =>
  state.ui.auth.getProfileDetails;

export const getCompanyProfileData = (state: RootState) =>
  state.ui.auth.getCompanyProfileDetails;
