import moment from "moment";

export const dateGenerator = (date: string) => {
  if (date) {
    return moment(date).local().format("ll");
  }
  return "";
};

export const dateTimeGenerator = (date: string) => {
  if (date) {
    return moment.utc(date).local().format("ll");
  }
  return "";
};

export const dateTimeGenerator2 = (date: string) => {
  if (date) {
    return moment.utc(date).local().format("lll");
  }
  return "";
};

export const convertSalaryToMillion = (salary: number) => {
  if (salary >= 1000000) {
    if (salary % 1000000 === 0) {
      return salary / 1000000 + " M";
    } else {
      return (salary / 1000000).toFixed(2) + " M";
    }
  }
  return salary;
};

export const isImageExists = (imageUrl: string | null | undefined) => {
  if (imageUrl) {
    let http = new XMLHttpRequest();
    http.open("HEAD", imageUrl, false);
    http.send();
    return http.status != 404;
  }
  return false;
};
