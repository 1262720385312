import { Column } from "components/tables/MasterTable/types";
export const COLUMNS: Column[] = [
  {
    name: "Name",
    key: "full_name",
    type: "text",
  },
  {
    name: "Email",
    key: "email",
    type: "text",
  },
  {
    name: "Job Title",
    key: "job_title",
    type: "text",
  },
  {
    name: "Query Date",
    key: "created_at",
    type: "text",
  },
  {
    name: "Read & Reply",
    key: "three_dot_btn",
    type: "action-button-cell",
  },
];
