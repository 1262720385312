import React, { FC, useEffect, useState } from "react";
import { Props } from "./types";

const Image: FC<Props> = ({ src, alt, defaultSrc, style }) => {
  const [imgSrc, setImgSrc] = useState<string>(src as string);

  const handleFallback = () => {
    setImgSrc(defaultSrc);
  };

  useEffect(() => {
    setImgSrc(src as string);
  }, [src]);

  return (
    <img className={style} src={imgSrc} alt={alt} onError={handleFallback} />
  );
};

export default Image;
