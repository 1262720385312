import { createSlice } from "@reduxjs/toolkit";
import { handleApplyNowAsync } from "./thunks";
import { ApplyNowState } from "./types";


const initialState: ApplyNowState = {
  applyNow: null,
  applyNowCreateStatus: "initial",
};

export const applyNowSlice = createSlice({
  name: "apply-now",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleApplyNowAsync.pending, (state) => {
        state.applyNowCreateStatus = "started";
      })
      .addCase(handleApplyNowAsync.fulfilled, (state, action) => {
        state.applyNowCreateStatus = "done";
        state.applyNow = action.payload.results;
      })
      .addCase(handleApplyNowAsync.rejected, (state) => {
        state.applyNowCreateStatus = "failed";
      });
  },
});

export default applyNowSlice;