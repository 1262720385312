import React, { FC, useState } from "react";
import styles from "./queries.module.scss";
import TableHeader from "uicontainers/_shared/TableHeader";
import MasterTable from "components/tables/MasterTable";
import { COLUMNS } from "./constants";
import {
  useAppSelector,
  useAppDispatch,
  useUnwrapAsyncThunk,
} from "store/hooks";
import {
  selectTotalCount,
  selectCurrentPage,
  selectPageSize,
  createAdminQueriesTableData,
} from "./selectors";
import {
  getQueriesAsync,
  setCurrentPage,
  setPageSize,
} from "store/admin/queries/actions";
import { OptionType } from "./types";
import { PaginationProps } from "antd";
import { getJobTitleAsync } from "store/admin/jobs/thunks";
import { adminJobTitles } from "store/admin/jobs/selectors";
// import { transformTripsToTableData } from "./helpers";

const Queries: FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const pageSize = useAppSelector(selectPageSize);
  const dispatch = useAppDispatch();
  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRangeChange = (_: any, dateStr: any) => {
    setStartDate(dateStr[0]);
    setEndDate(dateStr[1]);
    dispatch(setCurrentPage(1));
  };
  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const unwrap = useUnwrapAsyncThunk();
  React.useEffect(() => {
    unwrap(getJobTitleAsync());
  }, []);
  const jobTitleData = useAppSelector(adminJobTitles);
  const options: OptionType[] = jobTitleData.map((job) => ({
    name: job.job_title,
    value: job.job_title,
  }));

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, jobs__job_title: selectedValue };
    }
    if (startDate) {
      searchParams = { ...searchParams, start_date: startDate };
    }
    if (endDate) {
      searchParams = { ...searchParams, end_date: endDate };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    if (pageSize) {
      searchParams = { ...searchParams, page_size: pageSize };
    }
    dispatch(getQueriesAsync(searchParams));
  }, [
    searchValue,
    selectedValue,
    currentPage,
    pageSize,
    getQueriesAsync,
    endDate,
    startDate,
  ]);
  const tableData = useAppSelector(createAdminQueriesTableData);

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    currentPage,
    pageSize
  ) => {
    dispatch(setCurrentPage(currentPage));
    dispatch(setPageSize(pageSize));
  };

  return (
    <div className={styles.root}>
      <>
        <TableHeader
          title="Queries"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Job Type"
          options={options}
          onChange={handleRangeChange}
          placeholder="Job Title"
          firstShowSearch={true}
        />
        <MasterTable
          data={[...tableData]}
          columns={COLUMNS}
          pagination={{
            currentPage: currentPage,
            count: totalCount,
            onChange,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
          }}
        />
      </>
    </div>
  );
};

export default Queries;
