import React, { FC, useEffect, useState } from "react";
import { Button as AntButton } from "antd";
import styles from "./profilePicture.module.scss";
import avatar from "../../../assets/images/avatar.svg";
import FormItem from "antd/lib/form/FormItem";
import FileUploader from "components/FileUploader";
import { transformToUploadFile } from "uicontainers/Admin/Account/hooks";
import { UploadFile } from "antd/lib/upload/interface";
import { Props } from "./types";
import Image from "components/Image/Image";

const ProfilePicture: FC<Props> = ({
  profile_picture = null,
  updateAccount,
  upload,
}) => {
  const [profilePicture, setProfilePicture] = useState<UploadFile[]>([]);

  useEffect(() => {
    setProfilePicture(transformToUploadFile(profile_picture));
  }, [profile_picture]);

  return (
    <div className={styles.contianner}>
      <div className={styles.profileContainer}>
        <Image
          src={profile_picture === null ? "" : profile_picture}
          defaultSrc={avatar}
          alt={"profile picture"}
          style={styles.avatarImage}
        />
        <div className={styles.buttonwrap}>
          <div className={styles.buttonList}>
            <FormItem className={styles.uploadFormWrap}>
              <FileUploader
                fileType="image/png"
                name="profile_picture"
                multiple={false}
                maxCount={1}
                onUpload={upload}
                fileList={profilePicture}
                isDragger={false}
                className={styles.uploader}
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                  removeIcon: false,
                }}
              >
                <AntButton className={styles.uploadButton}>
                  <span>Upload</span>
                </AntButton>
              </FileUploader>
            </FormItem>
            {profile_picture && (
              <AntButton
                className={styles.cancelButton}
                onClick={(e) => {
                  e.stopPropagation();
                  updateAccount({
                    profile_picture: null,
                  });
                }}
              >
                <span>Remove</span>
              </AntButton>
            )}
          </div>
          <div className={styles.text}>
            <span>Change or Remove your profile picture</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePicture;
