import { Col, Row } from 'antd';
import DashboardCard from 'components/DashboardCard';
import React, { useEffect } from 'react'
import classNames from "classnames";
import styles from "./summary.module.scss";
import { useAppSelector, useUnwrapAsyncThunk } from 'store/hooks';
import { getSuperadminSummaryAsync } from 'store/superadmin/Dashboard/summary/thunks';
import { superAdminSummary } from 'store/superadmin/Dashboard/summary/selectors';

const Summary = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getSuperadminSummaryAsync());
  }, []);

    const {
      adminSummary,
    } = useAppSelector(superAdminSummary);
    
  
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 32 }, 0]}>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Total Companies" isActive={true}>
              <h1
                className={classNames(
                  styles.statValue,
                  styles.total_jobs_posted
                )}
              >
                {adminSummary.total_companies}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Total Job Posts" isActive={true}>
              <h1
                className={classNames(
                  styles.statValue,
                  styles.total_jobs_posted
                )}
              >
                {adminSummary.total_jobs_posted}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Total Applicants" isActive={true}>
              <h1
                className={classNames(
                  styles.statValue,
                  styles.total_jobs_posted
                )}
              >
                {adminSummary.total_applicants}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Total Queries" isActive={true}>
              <h1
                className={classNames(
                  styles.statValue,
                  styles.total_jobs_posted
                )}
              >
                {adminSummary.total_queries}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="New Companies This Month" isActive={false}>
              <h1
                className={classNames(styles.statValue, styles.jobs_post_time)}
              >
                {adminSummary.new_companies_this_month}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Jobs Posted This Month" isActive={false}>
              <h1
                className={classNames(styles.statValue, styles.jobs_post_time)}
              >
                {adminSummary.job_posted_this_month}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Job Applicants This Month" isActive={false}>
              <h1
                className={classNames(styles.statValue, styles.jobs_post_time)}
              >
                {adminSummary.job_applicants_this_month}
              </h1>
            </DashboardCard>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <DashboardCard title="Job Queries This Month" isActive={false}>
              <h1
                className={classNames(styles.statValue, styles.jobs_post_time)}
              >
                {adminSummary.job_queries_this_month}
              </h1>
            </DashboardCard>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Summary