import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRecentQueries } from "api/admin/Dashboard/recent-queries";

export const getRecentQueriesAsync = createAsyncThunk(
  "admin/recent-queries/getQueries",
  async () => {
    const response = await getRecentQueries();
    return response;
  }
);
