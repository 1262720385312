import { authAPI, publicJobsAPI } from "api/base";

import {
  GetCompanies,
  getCompanyDetails,
  GetIndustry,
  GetMoreCompanies,
} from "./types";

export const getHomeCompanies: GetCompanies = () => {
  return publicJobsAPI.get("/home-companies/");
};

export const getHomeCompanyDetails: getCompanyDetails = (id) => {
  return publicJobsAPI.get(`/home-companies/${id}/`);
};

export const getIndustry: GetIndustry = () => {
  return authAPI.get(`/company-industries/`);
};

export const getMoreCompanies: GetMoreCompanies = (params) => {
  return publicJobsAPI.get("/home-companies/", { params });
};
