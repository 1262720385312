import {
  getCompaniesAsync,
  GetCompanyDetailsAsync,
  getIndustryAsync,
  getMoreCompaniesAsync,
} from "./thunks";
import slice from "./slice";
const { setCurrentPage } = slice.actions;
export {
  getCompaniesAsync,
  GetCompanyDetailsAsync,
  setCurrentPage,
  getIndustryAsync,
  getMoreCompaniesAsync,
};
