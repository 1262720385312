import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getJobsAsync,
  getMoreJobsAsync,
  GetJobById,
  homeOpenJobsAsync,
  homeOpenCompaniesAsync,
} from "./thunks";
import { PublicJobsState } from "./types";

const initialState: PublicJobsState = {
  jobs: [],
  job: null,
  totalCount: 0,
  getJobsRequestStatus: "initial",
  getJobSingleJobRequestStatus: "initial",
  homeOperJobs: [],
  homeOpenCompanies: [],
  homeOpenJobsRequestStatus: "initial",
  homeCompaniesOpenRequestStatus: "initial",
  currentPage: 1,
  next: null,
};

export const getJobsSlice = createSlice({
  name: "public/jobs",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobsAsync.pending, (state) => {
      state.getJobsRequestStatus = "started";
    });
    builder.addCase(getJobsAsync.fulfilled, (state, action) => {
      state.jobs = action.payload.results;
      state.totalCount = action.payload.count;
      state.next = action.payload.next;
      state.currentPage = 1;
      state.getJobsRequestStatus = "done";
    });
    builder.addCase(getJobsAsync.rejected, (state) => {
      state.getJobsRequestStatus = "failed";
    });
    builder.addCase(getMoreJobsAsync.pending, (state) => {
      state.getJobsRequestStatus = "started";
    });
    builder.addCase(getMoreJobsAsync.fulfilled, (state, action) => {
      state.jobs = [...state.jobs, ...action.payload.results];
      state.totalCount = action.payload.count;
      state.next = action.payload.next;
      state.getJobsRequestStatus = "done";
    });
    builder.addCase(getMoreJobsAsync.rejected, (state) => {
      state.getJobsRequestStatus = "failed";
    });
    builder.addCase(GetJobById.pending, (state) => {
      state.getJobsRequestStatus = "started";
    });
    builder.addCase(GetJobById.fulfilled, (state, action) => {
      state.job = action.payload.results;
      state.getJobsRequestStatus = "done";
    });
    builder.addCase(GetJobById.rejected, (state) => {
      state.getJobsRequestStatus = "failed";
    });
    builder.addCase(homeOpenJobsAsync.pending, (state) => {
      state.homeOpenJobsRequestStatus = "started";
    });
    builder.addCase(homeOpenJobsAsync.fulfilled, (state, action) => {
      state.homeOperJobs = action.payload.results;
      state.homeOpenJobsRequestStatus = "done";
    });
    builder.addCase(homeOpenJobsAsync.rejected, (state) => {
      state.homeOpenJobsRequestStatus = "failed";
    });
    builder.addCase(homeOpenCompaniesAsync.pending, (state) => {
      state.homeCompaniesOpenRequestStatus = "started";
    });
    builder.addCase(homeOpenCompaniesAsync.fulfilled, (state, action) => {
      state.homeOpenCompanies = action.payload.results;
      state.homeCompaniesOpenRequestStatus = "done";
    });
    builder.addCase(homeOpenCompaniesAsync.rejected, (state) => {
      state.homeCompaniesOpenRequestStatus = "failed";
    });
  },
});

export default getJobsSlice;
