import { createSlice } from "@reduxjs/toolkit";
import { SidebarInitialState } from "./types";

const initialState: SidebarInitialState = {
  isShowSidebar: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isShowSidebar = !state.isShowSidebar;
    },
    setSidebarToHidden: (state) => {
      state.isShowSidebar = false;
    },
  },
});

export default sidebarSlice;
