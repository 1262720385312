import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSuperAdminTagsAsync, getSuperAdminJobTagsAsync } from "./thunks";
import { SuperAdminTagsState } from "./types";

const initialState: SuperAdminTagsState = {
  superadmin_tags: [],
  totalCount: 0,
  currentPage: 1,
  pageSize: 10,
  superadminTagsRequestStatus: "initial",
};

export const superAdminTagsSlice = createSlice({
  name: "superadmin/tags",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSuperAdminTagsAsync.pending, (state) => {
      state.superadminTagsRequestStatus = "started";
    });
    builder.addCase(getSuperAdminTagsAsync.fulfilled, (state, action) => {
      state.superadminTagsRequestStatus = "done";
      state.totalCount = action.payload.count;
      state.superadmin_tags = action.payload.results;
    });
    builder.addCase(getSuperAdminTagsAsync.rejected, (state) => {
      state.superadminTagsRequestStatus = "failed";
    });
    builder.addCase(getSuperAdminJobTagsAsync.pending, (state) => {
      state.superadminTagsRequestStatus = "started";
    });
    builder.addCase(getSuperAdminJobTagsAsync.fulfilled, (state, action) => {
      state.superadminTagsRequestStatus = "done";
      state.superadmin_tags = action.payload.results;
    });
    builder.addCase(getSuperAdminJobTagsAsync.rejected, (state) => {
      state.superadminTagsRequestStatus = "failed";
    });
  },
});

export default superAdminTagsSlice;
