import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";
import { Layout } from "antd";
import styles from "./app.module.scss";
import NotFound from "uicontainers/NotFound";
import Admin from "routes/Admin";
import SuperAdmin from "routes/SuperAdmin";
import Home from "routes/Home";
import { useAppDispatch } from "store/hooks";
import { verifyTokenAsync } from "store/shared/auth/thunks";
import { ACCESS_TOKEN_NAME } from "api/base/constants";
import { isAdminUser, isSuperUser } from "shared/helpers/authHelper";

const App = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem(ACCESS_TOKEN_NAME) || "";
  React.useEffect(() => {
    dispatch(verifyTokenAsync(token));
  }, []);

  return (
    <HelmetProvider>
      <Layout className={styles.layout}>
        <Routes>
          {Home()}
          {isAdminUser() && Admin()}
          {isSuperUser() && SuperAdmin()}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </HelmetProvider>
  );
};

export default App;
