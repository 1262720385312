import { createSelector } from "reselect";

import { transformSuperAdminCompaniesToTableData } from "./helpers";

import { RootState } from "store/types";

export const superAdminCompaniesData = (state: RootState) =>
  state.resources.superAdmin.superAdminCompanies.superadmin_companies;

export const createSuperAdminCompaniesTableData = createSelector(
  superAdminCompaniesData,
  transformSuperAdminCompaniesToTableData
);

export const selectTotalCount = (state: RootState) =>
  state.resources.superAdmin.superAdminCompanies.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.superAdmin.superAdminCompanies.currentPage;

export const selectPageSize = (state: RootState) =>
  state.resources.superAdmin.superAdminCompanies.pageSize;
