import React, { FC } from "react";
import { Card, Row } from "antd";
import classNames from "classnames";

import { Props } from "./types";
import styles from "./dashboardCard.module.scss";

const DashboardCard: FC<Props> = (props) => {
  const { title, children, className = "", isActive, style } = props;
  return (
    <Card
      className={classNames({
        [styles.dashCard]: true,
        [styles.active]: isActive,
        [className]: Boolean(className),
      })}
      style={style}
    >
      <p>{title}</p>
      {children}
    </Card>
  );
};

export default DashboardCard;
