import { createAsyncThunk } from "@reduxjs/toolkit";
import { getHomeCompanies, getHomeCompanyDetails, getIndustry, getMoreCompanies } from "api/public/companies";
import { companiesRequestParams } from "api/public/companies/types";


export const getCompaniesAsync = createAsyncThunk(
  "public/companies/getCompanies",
  async () => {
    const response = await getHomeCompanies();
    return response;
  }
);

export const GetCompanyDetailsAsync = createAsyncThunk(
  "public/companies/getCompanyDetails",
  async (id: number | string) => {
    const response = await getHomeCompanyDetails(id);
    return response;
  }
);


export const getIndustryAsync = createAsyncThunk(
  "public/getIndustry",
  async () => {
    const response = await getIndustry();
    return response;
  }
);

export const getMoreCompaniesAsync = createAsyncThunk(
  "public/companies/getMoreCompanies",
  async (params?: companiesRequestParams) => {
    const response = await getMoreCompanies(params);
    return response;
  }
)