import React, { FC, useEffect, useState } from "react";
import styles from "./companies.module.scss";
import TableHeader from "uicontainers/_shared/TableHeader";
import MasterTable from "components/tables/MasterTable";
import { COLUMNS } from "./constants";
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from "store/hooks";
import { getSuperAdminCompaniesAsync } from "store/superadmin/companies/thunks";

import {
  createSuperAdminCompaniesTableData,
  selectCurrentPage,
  selectPageSize,
  selectTotalCount,
} from "./selectors";
import { OptionType } from "./types";
import { getJobLocationsAsync } from "store/admin/jobLocations/thunks";
import { jobLocations } from "store/admin/jobLocations/selectors";
import { getIndustryAsync } from "store/public/companies/thunks";
import { getPublicCompaniesApi } from "store/public/companies/selectors";
import {
  setCurrentPage,
  setPageSize,
} from "store/superadmin/companies/actions";
import { PaginationProps } from "antd";

const Companies: FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedSecondValue, setSelectedSecondValue] = useState("");
  const [selectedThirdValue, setSelectedThirdValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const pageSize = useAppSelector(selectPageSize);
  const dispatch = useAppDispatch();

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSecondSelect = (value: string) => {
    setSelectedSecondValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleThirdSelect = (value: string) => {
    setSelectedThirdValue(value);
    dispatch(setCurrentPage(1));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRangeChange = (_: any, dateStr: any) => {
    setStartDate(dateStr[0]);
    setEndDate(dateStr[1]);
    dispatch(setCurrentPage(1));
  };

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getJobLocationsAsync());
    unwrap(getIndustryAsync());
  }, []);

  const jobLocationsData = useAppSelector(jobLocations).jobLocations;
  const industryData = useAppSelector(getPublicCompaniesApi).industry;
  const options: OptionType[] = industryData.map((industry) => ({
    name: industry.industry,
    value: industry.industry,
  }));

  const extraOptions: OptionType[] = jobLocationsData.map((jobLocation) => ({
    name: jobLocation.location,
    value: jobLocation.location,
  }));

  const secondExtrapOptions = [
    { name: "NEW", value: "NEW" },
    { name: "ACTIVE", value: "ACTIVE" },
    { name: "INACTIVE", value: "INACTIVE" },
  ];
  const tableData = useAppSelector(createSuperAdminCompaniesTableData);
  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, industry: selectedValue };
    }
    if (selectedSecondValue) {
      searchParams = { ...searchParams, company_location: selectedSecondValue };
    }
    if (selectedThirdValue) {
      searchParams = { ...searchParams, active_status: selectedThirdValue };
    }
    if (startDate) {
      searchParams = { ...searchParams, start_date: startDate };
    }
    if (endDate) {
      searchParams = { ...searchParams, end_date: endDate };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    if (pageSize) {
      searchParams = { ...searchParams, page_size: pageSize };
    }
    dispatch(getSuperAdminCompaniesAsync(searchParams));
  }, [
    searchValue,
    selectedValue,
    selectedSecondValue,
    selectedThirdValue,
    currentPage,
    pageSize,
    endDate,
    startDate,
  ]);

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    currentPage,
    pageSize
  ) => {
    dispatch(setCurrentPage(currentPage));
    dispatch(setPageSize(pageSize));
  };

  return (
    <div className={styles.root}>
      <>
        <TableHeader
          title="Companies"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Companies"
          options={options}
          onChange={handleRangeChange}
          extraOptions={extraOptions}
          secondExtrapOptions={secondExtrapOptions}
          oneThirdSelectChange={handleThirdSelect}
          onSecondSelectChange={handleSecondSelect}
          placeholder="Industry"
          extraPlaceholder="Location"
          secondExtraPlaceholder="Status"
        />
        <MasterTable
          data={[...tableData]}
          columns={COLUMNS}
          pagination={{
            currentPage: currentPage,
            count: totalCount,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            onChange,
          }}
        />
      </>
    </div>
  );
};

export default Companies;
