import {
  getSuperAdminCompaniesAsync,
  updateSuperAdminCompaniesAsync,
} from "./thunks";
import slice from "./slice";
const { setCurrentPage, setPageSize } = slice.actions;
export {
  getSuperAdminCompaniesAsync,
  setCurrentPage,
  setPageSize,
  updateSuperAdminCompaniesAsync,
};
