import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSuperAdminTags,
  getSuperAdminJobTags,
  postSuperAdminTags,
  putSuperAdminTags,
  deleteSuperAdminTags,
} from "api/superadmin/tags";
import {
  SuperAdminTags,
  SuperAdminTagsQueryParams,
} from "api/superadmin/tags/types";

export const getSuperAdminTagsAsync = createAsyncThunk(
  "superadmin/getTags",
  async (params?: SuperAdminTagsQueryParams) => {
    const response = await getSuperAdminTags(params);
    return response;
  }
);

export const getSuperAdminJobTagsAsync = createAsyncThunk(
  "superadmin/getJobTags",
  async () => {
    const response = await getSuperAdminJobTags();
    return response;
  }
);

export const postSuperAdminTagAsync = createAsyncThunk(
  "superadmin/postTag",
  async (data: SuperAdminTags) => {
    const response = await postSuperAdminTags(data);
    return response;
  }
);

export const putSuperAdminTagAsync = createAsyncThunk(
  "superadmin/putTag",
  async ({ tagId, data }: { tagId: number; data: SuperAdminTags }) => {
    const response = await putSuperAdminTags(tagId, data);
    return response;
  }
);

export const deleteSuperAdminTagAsync = createAsyncThunk(
  "superadmin/deleteTag",
  async (tagId: number) => {
    const response = await deleteSuperAdminTags(tagId);
    return response;
  }
);
