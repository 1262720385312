import { RecentApplicantsProps } from "./types";
import { dateGenerator } from "shared/helpers/helperFunctions";
import { SuperAdminRecentApplicants } from "api/superadmin/Dashboard/recent-applicants/types";

type RecentApplicants = Pick<
  SuperAdminRecentApplicants,
  "id" | "created_at" | "full_name" | "email"
>;


export const transformRecentApplicantsToTableData = (
  recentApplicants: RecentApplicants[]
): RecentApplicantsProps[] => {
  return recentApplicants.map((recent_applicants) => ({
    key: recent_applicants.id.toString(),
    full_name: {
      text: recent_applicants.full_name,
    },
    email: {
      text: recent_applicants.email,
    },
    created_at: {
      text: `${dateGenerator(recent_applicants?.created_at)}`,
    },
  }));
};
