import React from "react";
import {
  convertSalaryToMillion,
  dateTimeGenerator,
} from "shared/helpers/helperFunctions";
import { JOB_TYPES_TAG_PROPS } from "./constants";
import { Jobs } from "api/admin/jobs/types";
import { jobDataType } from "./types";
import { JOB_PUBLISH_STATUS_TAG_PROPS } from "uicontainers/SuperAdmin/Jobs/constants";
import ActionButton from "./ActionButton";

export const transformAdminJobsToTableData = (jobs: Jobs[]): jobDataType[] => {
  return jobs.map((job) => ({
    key: job.id.toString(),
    job_title: {
      text: job.job_title,
    },
    job_category_name: {
      text: job.job_category_name,
    },
    job_location: {
      text: job.job_location,
    },
    job_type: {
      tags: [
        {
          name: JOB_TYPES_TAG_PROPS[job.job_type].name?.replace("_", " "),
          color: JOB_TYPES_TAG_PROPS[job.job_type].color,
        },
      ],
    },
    salary: {
      text:
        job.salary_type === "NEGOTIABLE"
          ? "NEGOTIABLE"
          : `¥ ${convertSalaryToMillion(
              Number(job.salary_min)
            )} - ${convertSalaryToMillion(Number(job.salary_max))}`,
    },
    publish_date: {
      text: `${dateTimeGenerator(job.publish_date)}`,
    },
    publish_status: {
      tags: [
        {
          name: JOB_PUBLISH_STATUS_TAG_PROPS[job.publish_status].name,
          color: JOB_PUBLISH_STATUS_TAG_PROPS[job.publish_status].color,
        },
      ],
    },
    three_dot_btn: {
      buttons: [
        {
          icon: <ActionButton job={job} />,
          onClick: () => {},
        },
      ],
    },
  }));
};
