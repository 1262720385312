import { RecentQueries } from "api/admin/Dashboard/recent-queries/types";
import { dateGenerator } from "shared/helpers/helperFunctions";

import { RecentQueriesTableData } from "./types";

export const transformRecentQueriesToTableData = (
  recentQueries: RecentQueries[]
): RecentQueriesTableData[] => {
  return recentQueries.map((recent_queries) => ({
    key: recent_queries.id.toString(),
    full_name: {
      text: recent_queries.full_name,
    },
    email: {
      text: recent_queries.email,
    },
    created_at: {
      text: `${dateGenerator(recent_queries.created_at)}`,
    },
  }));
};
