import { createSlice } from "@reduxjs/toolkit";
import { getSuperAdminRecentApplicantsAsync } from "./thunks";
import { SuperAdminRecentApplicantsState } from "./types";

const initialState: SuperAdminRecentApplicantsState = {
  SuperAdminRecentApplicants: [],
  SuperAdminRecentApplicantsRequestStatus: "initial",
};

export const getgetSuperAdminRecentApplicantsSlice = createSlice({
  name: "superadmin/applicants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuperAdminRecentApplicantsAsync.pending, (state) => {
      state.SuperAdminRecentApplicantsRequestStatus = "started";
    });
    builder.addCase(getSuperAdminRecentApplicantsAsync.fulfilled, (state, action) => {
      state.SuperAdminRecentApplicantsRequestStatus = "done";
      state.SuperAdminRecentApplicants = action.payload.results;
    });
    builder.addCase(getSuperAdminRecentApplicantsAsync.rejected, (state) => {
      state.SuperAdminRecentApplicantsRequestStatus = "failed";
    });
  },
});

export default getgetSuperAdminRecentApplicantsSlice;
