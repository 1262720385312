import { FC } from "react";
import { Select, Typography } from "antd";
import classNames from "classnames";
import { Props } from "./types";
import styles from "./selectInput.module.scss";

const SelectInput: FC<Props> = (props) => {
  const {
    title,
    placeholder,
    options,
    onChange,
    className = "",
    defaultValue,
    bordered,
    allowClear = true,
    onSearch,
    showSearch,
    notFoundContent,
    filterOption,
    onSelect,
    value,
    dropdownStyle = {},
    style = {},
    disabled = false,
  } = props;
  const { Option } = Select;
  const { Text } = Typography;
  return (
    <div
      className={classNames({
        [styles.select]: true,
        [className]: Boolean(classNames),
      })}
    >
      {!!title ? (
        <div className={styles.title}>
          <Text strong>{title}</Text>
        </div>
      ) : null}
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        bordered={bordered}
        allowClear={allowClear}
        onSearch={onSearch}
        showSearch={showSearch}
        notFoundContent={notFoundContent}
        filterOption={filterOption}
        onSelect={onSelect}
        value={value}
        dropdownStyle={dropdownStyle}
        style={style}
        dropdownMatchSelectWidth={false}
        disabled={disabled}
      >
        {options.map(({ name, value }, index) => (
          <Option value={value} key={index}>
            {name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SelectInput;
