import React, { useEffect } from "react";
import { Typography } from "antd";
import styles from "./companies.module.scss";
import CompaniesCard from "components/CompaniesCard";
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from "store/hooks";
import {
  getCompaniesAsync,
  getMoreCompaniesAsync,
} from "store/public/companies/thunks";
import {
  getPublicCompaniesApi,
  selectCurrentPage,
  selectNextPage,
} from "store/public/companies/selectors";
import Button from "components/Button";
import { setCurrentPage } from "store/public/companies/actions";
const { Title } = Typography;

const Companies = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getCompaniesAsync());
  }, []);
  const companies = useAppSelector(getPublicCompaniesApi).companies;
  let currentPage = useAppSelector(selectCurrentPage);
  const nextPage = useAppSelector(selectNextPage);
  const dispatch = useAppDispatch();

  const loadMoreCompanyClickHandler = () => {
    if (nextPage) {
      currentPage = currentPage + 1;
      dispatch(setCurrentPage(currentPage));
      dispatch(getMoreCompaniesAsync({ page: currentPage }));
    }
  };

  return (
    <>
      <div className={styles.main}>
        <Title level={3} className={styles.title}>
          Top Companies Employing for Japan
        </Title>
        <div className={styles.container}>
          <CompaniesCard companies={companies} />
        </div>
        <div className={styles.loadMoreButtonContainer}>
          {nextPage && (
            <Button onClick={loadMoreCompanyClickHandler} type="ghost">
              Load more companies
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Companies;
