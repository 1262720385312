import { Column } from "components/tables/MasterTable/types";

export const COLUMNS: Column[] = [
  {
    name: "Job Title",
    key: "job_title",
    type: "text",
    width: "50%",
  },
  {
    name: "Date",
    key: "created_at",
    type: "text",
    width: "50%",
  },
];
