import { adminAPI } from "api/base";

import {
  GetJobs,
  GetJobTitle,
  GetJobDetails,
  PostJob,
  PutJob,
  UpdateAdminJobs,
} from "./types";

export const getAdminJobs: GetJobs = (params) => {
  return adminAPI.get("/jobs/", { params });
};

export const getAdminJobTitle: GetJobTitle = (params) => {
  return adminAPI.get("/jobs-title/", { params });
};

export const getAdminJobDetails: GetJobDetails = (jobId: string) => {
  return adminAPI.get(`/jobs/${jobId}/`);
};

export const PostJobs: PostJob = (data) => {
  return adminAPI.post(`/jobs/`, data);
};

export const PutJobs: PutJob = (jobId, data) => {
  return adminAPI.put(`/jobs/${jobId}/`, data);
};

export const DeleteAdminJob = (jobId: number) => {
  return adminAPI.delete(`/jobs/${jobId}/`);
};

export const updateAdminJobs: UpdateAdminJobs = (data, id) => {
  return adminAPI.patch(`/jobs/${id}/`, data);
};
