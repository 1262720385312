import { createAsyncThunk } from "@reduxjs/toolkit";
import { getApplicants } from "api/admin/applicants";
import { ApplicantsRequestParams } from "api/admin/applicants/types";

export const getApplicantsAsync = createAsyncThunk(
  "admin/applicant/getApplicants",
  async (params?: ApplicantsRequestParams) => {
    const response = await getApplicants(params);
    return response;
  }
);
