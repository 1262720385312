import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSuperAdminCompaniesList,
  getSuperAdminCompanyNamesList,
  updateSuperAdminCompanies,
} from "api/superadmin/companies";
import {
  superadminCompaniesParams,
  superadminCompanyNameParams,
  UpdateSuperAdminCompaniesData,
} from "api/superadmin/companies/types";

export const getSuperAdminCompaniesAsync = createAsyncThunk(
  "superadmin/companies/getCompanies",
  async (params?: superadminCompaniesParams) => {
    const response = await getSuperAdminCompaniesList(params);
    return response;
  }
);

export const getSuperAdminCompanyNameAsync = createAsyncThunk(
  "superadmin/companies/getCompanyNames",
  async (params?: superadminCompanyNameParams) => {
    const response = await getSuperAdminCompanyNamesList(params);
    return response;
  }
);

export const updateSuperAdminCompaniesAsync = createAsyncThunk(
  "superadmin/companies/updateSuperAdminCompanies",
  async (data: UpdateSuperAdminCompaniesData) => {
    const response = await updateSuperAdminCompanies(data, data.companyId);
    return response;
  }
);
