import { SuperAdminRecentJobPosts } from "api/superadmin/Dashboard/recent-job-posts/types";
import { dateGenerator } from "shared/helpers/helperFunctions";
import { RecentJobsPostsProps } from "./types";

export const transformRecentJobsPostsToTableData = (
  recentJobsPosts: SuperAdminRecentJobPosts[]
): RecentJobsPostsProps[] => {
  return recentJobsPosts.map((recent_jobs_posts) => ({
    key: recent_jobs_posts.id.toString(),
    job_title: {
      text: recent_jobs_posts.job_title,
    },

    created_at: {
      text: `${dateGenerator(recent_jobs_posts?.created_at)}`,
    },
  }));
};
