import React, { FC, useState } from "react";
import styles from "./applicants.module.scss";
import TableHeader from "uicontainers/_shared/TableHeader";
import MasterTable from "components/tables/MasterTable";
import { COLUMNS } from "./constants";
import {
  useAppSelector,
  useAppDispatch,
  useUnwrapAsyncThunk,
} from "store/hooks";
import { superAdminApplicants } from "store/superadmin/applicants/selectors";
import { getSuperAdminApplicantsAsync } from "store/superadmin/applicants/thunks";
import {
  createAdminApplicantsTableData,
  selectPageSize,
  selectTotalCount,
} from "./selectors";
import { OptionType } from "./types";
import {
  setCurrentPage,
  setPageSize,
} from "store/superadmin/applicants/actions";
import { PaginationProps } from "antd";
import { getSuperAdminCompanyNameAsync } from "store/superadmin/companies/thunks";
import { superAdminCompanyNames } from "store/superadmin/companies/selectors";
import { getSuperAdminJobsTitleAsync } from "store/superadmin/jobs/thunks";
import { superAdminJobsTitle } from "store/superadmin/jobs/selectors";
import { setSuperadminJobTitleToEmpty } from "store/superadmin/jobs/actions";

const SuperAdminApplicants: FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedSecondValue, setSelectedSecondValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const totalCount = useAppSelector(selectTotalCount);
  // const currentPage = useAppSelector(selectCurrentPage);
  const unwrap = useUnwrapAsyncThunk();

  const currentPage = useAppSelector(superAdminApplicants).currentPage;
  const pageSize = useAppSelector(selectPageSize);

  const dispatch = useAppDispatch();

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
    if (value) {
      unwrap(getSuperAdminJobsTitleAsync(value));
    } else {
      dispatch(setSuperadminJobTitleToEmpty());
    }
  };
  const handleSecondSelect = (value: string) => {
    setSelectedSecondValue(value);
    dispatch(setCurrentPage(1));
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRangeChange = (_: any, dateStr: any) => {
    setStartDate(dateStr[0]);
    setEndDate(dateStr[1]);
    dispatch(setCurrentPage(1));
  };

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  React.useEffect(() => {
    unwrap(getSuperAdminCompanyNameAsync());
    dispatch(setSuperadminJobTitleToEmpty());
  }, []);

  const companyNameData = useAppSelector(superAdminCompanyNames);
  const jobTitleData = useAppSelector(superAdminJobsTitle);
  const options: OptionType[] = companyNameData.map((company) => ({
    name: company.company_name,
    value: company.id.toString(),
  }));
  const extraOptions: OptionType[] = jobTitleData
    .map((job) => ({
      name: job.job_title,
      value: job.job_title,
    }))
    .filter(
      (a, i) => jobTitleData.findIndex((s) => a.name === s.job_title) === i
    );
  const tableData = useAppSelector(createAdminApplicantsTableData);
  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, company: selectedValue };
    }
    if (selectedSecondValue) {
      searchParams = { ...searchParams, job_title: selectedSecondValue };
    }
    if (startDate) {
      searchParams = { ...searchParams, start_date: startDate };
    }
    if (endDate) {
      searchParams = { ...searchParams, end_date: endDate };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    if (pageSize) {
      searchParams = { ...searchParams, page_size: pageSize };
    }
    dispatch(getSuperAdminApplicantsAsync(searchParams));
  }, [
    searchValue,
    selectedValue,
    selectedSecondValue,
    currentPage,
    pageSize,
    endDate,
    startDate,
  ]);

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    currentPage,
    pageSize
  ) => {
    dispatch(setCurrentPage(currentPage));
    dispatch(setPageSize(pageSize));
  };

  return (
    <div className={styles.root}>
      <>
        <TableHeader
          title="Applicants"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Applicants"
          options={options}
          onChange={handleRangeChange}
          extraOptions={extraOptions}
          onSecondSelectChange={handleSecondSelect}
          placeholder="Company Name"
          extraPlaceholder="Job Title"
          firstShowSearch={true}
          secondShowSearch={true}
          selectedValue={selectedValue}
          cascading={true}
        />
        <MasterTable
          data={[...tableData]}
          columns={COLUMNS}
          pagination={{
            currentPage: currentPage,
            count: totalCount,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            onChange,
          }}
        />
      </>
    </div>
  );
};

export default SuperAdminApplicants;
