import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRecentApplicants } from "api/admin/Dashboard/recent-applicants";

export const getRecentApplicantsAsync = createAsyncThunk(
  "admin/recent-applicant/getApplicants",
  async () => {
    const response = await getRecentApplicants();
    return response;
  }
);
