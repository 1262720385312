import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./companyDetails.module.scss";
// import WorkStyle from "./WorkStyle";
import { Breadcrumb, Row, Col } from "antd";
import { Divider, Anchor } from "antd";
import companyLogo from "../../../assets/images/companyLogo.png";
import streetLoactaionIcon from "../../../assets/images/streetLoactaionIcon.svg";
import Button from "components/Button";
import { AppLink } from "shared/helpers/urlHelper";
import { useUnwrapAsyncThunk, useAppSelector } from "store/hooks";
import { getPublicCompaniesApi } from "store/public/companies/selectors";
import { GetCompanyDetailsAsync } from "store/public/companies/thunks";
import SimpleJobCard from "components/SimpleJobCard";
import classNames from "classnames";
import Image from "components/Image/Image";
const { Link } = Anchor;

const CompanyDetails = () => {
  const [activeLink, setActiveLink] = useState<string>("");
  const unwrap = useUnwrapAsyncThunk();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      unwrap(GetCompanyDetailsAsync(id));
    }
  }, [id]);
  const companyDetails = useAppSelector(getPublicCompaniesApi).companyDetails;

  const handleClick = (
    e: React.MouseEvent<HTMLElement>,
    link: {
      title: React.ReactNode;
      href: string;
    }
  ) => {
    e.preventDefault();
    setActiveLink(link.href);
  };

  const onChange = (link: string) => {
    setActiveLink(link);
  };

  return (
    <div className={styles.main}>
      <div className={styles.breadCrumbContainer}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <AppLink to={`/login`} className={styles.LinkHover}>
              Home
            </AppLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <AppLink to={`/companies`} className={styles.LinkHover}>
              Company List
            </AppLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Company Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={styles.container}>
        <div className={styles.containerBody}>
          <Row
            justify="space-between"
            align="middle"
            style={{ padding: "10px 10px" }}
          >
            <Col lg={12} md={12}>
              <div className={styles.companyInfo}>
                <div>
                  <Image
                    src={
                      companyDetails && companyDetails?.profile_picture !== null
                        ? companyDetails?.profile_picture
                        : ""
                    }
                    defaultSrc={companyLogo}
                    alt={companyDetails?.company_name || "company logo"}
                    style={styles.companyLogo}
                  />
                </div>
                <div className={styles.companyTitleContainer}>
                  <h1>{companyDetails?.company_name}</h1>
                  <div className={styles.companyLocationContainer}>
                    <img
                      className={styles.companyLocationImage}
                      src={streetLoactaionIcon}
                      alt="streetLoactaionIcon"
                    />
                    <h4 style={{ color: "#6d6e70" }}>
                      {companyDetails?.company_location === "Other"
                        ? companyDetails?.other_location
                        : companyDetails?.company_location}
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={12} md={12}>
              <div className={styles.jobButton}>
                <Button type="primary" className={styles.buttonStyle}>
                  {companyDetails?.jobs_count} Open{" "}
                  {companyDetails && companyDetails?.jobs_count > 1
                    ? "Jobs"
                    : "job"}
                </Button>
              </div>
            </Col>
          </Row>
          <Row justify="start" align="middle">
            <Col span={24} style={{ width: "100%" }}>
              <Anchor affix={false} onClick={handleClick} onChange={onChange}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  {companyDetails?.overview && (
                    <Link href="#about" title="About" />
                  )}
                  {companyDetails?.work_style &&
                    companyDetails?.important_links !== "<p><br></p>" && (
                      <Link href="#workStyle" title="Work Style"></Link>
                    )}
                  {companyDetails?.diversity &&
                    companyDetails?.important_links !== "<p><br></p>" && (
                      <Link href="#diversity" title="Diversity"></Link>
                    )}
                  {companyDetails?.mission &&
                    companyDetails?.important_links !== "<p><br></p>" && (
                      <Link href="#mission" title="Mission"></Link>
                    )}
                  {companyDetails?.jobs && companyDetails?.jobs.length > 0 && (
                    <Link href="#jobs" title="Jobs"></Link>
                  )}
                  {companyDetails?.detailed_data &&
                    companyDetails?.important_links !== "<p><br></p>" && (
                      <Link href="#detailed_data" title="Details"></Link>
                    )}
                  {companyDetails?.benefit &&
                    companyDetails?.important_links !== "<p><br></p>" && (
                      <Link href="#benefit" title="Benefits"></Link>
                    )}
                  {companyDetails?.interview_process &&
                    companyDetails?.important_links !== "<p><br></p>" && (
                      <Link
                        href="#interview_process"
                        title="Interview Process"
                      ></Link>
                    )}
                  {companyDetails?.important_links &&
                    companyDetails?.important_links !== "<p><br></p>" && (
                      <Link
                        href="#important_links"
                        title="Important Links"
                      ></Link>
                    )}
                </div>
              </Anchor>
            </Col>
          </Row>
          <Row>
            <Col span={24} className={styles.dividerStyle}>
              <Divider style={{ margin: "-8px 0px 20px" }}></Divider>
            </Col>
          </Row>
          {companyDetails?.overview && (
            <Row>
              <Col span={24} className={styles.dividerStyle}>
                <div
                  className={classNames({
                    [styles.links]: true,
                    [styles.activeLink]: activeLink === "#about",
                  })}
                  id="about"
                >
                  <h1 className={styles.heading}>
                    About {companyDetails?.company_name}
                  </h1>
                  <p className={styles.descriptionText}>
                    {companyDetails?.overview}
                  </p>
                </div>
                <Divider></Divider>
              </Col>
            </Row>
          )}
          {companyDetails?.work_style &&
            companyDetails?.important_links !== "<p><br></p>" && (
              <Row>
                <Col span={24} className={styles.dividerStyle}>
                  <div
                    className={classNames({
                      [styles.links]: true,
                      [styles.activeLink]: activeLink === "#workStyle",
                    })}
                    id="workStyle"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h1 className={styles.heading}>Work Style</h1>
                    {companyDetails?.work_style && (
                      <div
                        className={styles.descriptionText}
                        dangerouslySetInnerHTML={{
                          __html: companyDetails?.work_style,
                        }}
                      />
                    )}
                  </div>
                  <Divider></Divider>
                </Col>
              </Row>
            )}
          {companyDetails?.diversity &&
            companyDetails?.important_links !== "<p><br></p>" && (
              <Row>
                <Col span={24} className={styles.dividerStyle}>
                  <div
                    className={classNames({
                      [styles.links]: true,
                      [styles.activeLink]: activeLink === "#diversity",
                    })}
                    id="diversity"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h1 className={styles.heading}>Diversity</h1>
                    {companyDetails?.diversity && (
                      <div
                        className={styles.descriptionText}
                        dangerouslySetInnerHTML={{
                          __html: companyDetails?.diversity,
                        }}
                      />
                    )}
                  </div>
                  <Divider></Divider>
                </Col>
              </Row>
            )}
          {companyDetails?.mission &&
            companyDetails?.important_links !== "<p><br></p>" && (
              <Row>
                <Col span={24} className={styles.dividerStyle}>
                  <div
                    className={classNames({
                      [styles.links]: true,
                      [styles.activeLink]: activeLink === "#mission",
                    })}
                    id="mission"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h1 className={styles.heading}>Mission</h1>
                    {companyDetails?.mission && (
                      <div
                        className={styles.descriptionText}
                        dangerouslySetInnerHTML={{
                          __html: companyDetails?.mission,
                        }}
                      />
                    )}
                  </div>
                  <Divider></Divider>
                </Col>
              </Row>
            )}
          {companyDetails?.jobs && companyDetails?.jobs.length > 0 && (
            <Row>
              <Col span={24} className={styles.dividerStyle}>
                <h1
                  id="jobs"
                  className={classNames({
                    [styles.heading]: true,
                    [styles.links]: true,
                    [styles.activeLink]: activeLink === "#jobs",
                  })}
                >
                  Jobs
                </h1>
                {companyDetails?.jobs?.map((job) => (
                  <SimpleJobCard job={job} />
                ))}
                <Divider></Divider>
              </Col>
            </Row>
          )}
          {companyDetails?.detailed_data &&
            companyDetails?.important_links !== "<p><br></p>" && (
              <Row>
                <Col span={24} className={styles.dividerStyle}>
                  <div
                    className={classNames({
                      [styles.links]: true,
                      [styles.activeLink]: activeLink === "#detailed_data",
                    })}
                    id="detailed_data"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h1 className={styles.heading}>Company Detail</h1>
                    {companyDetails?.detailed_data && (
                      <div
                        className={styles.descriptionText}
                        dangerouslySetInnerHTML={{
                          __html: companyDetails?.detailed_data,
                        }}
                      />
                    )}
                  </div>
                  <Divider></Divider>
                </Col>
              </Row>
            )}
          {companyDetails?.benefit &&
            companyDetails?.important_links !== "<p><br></p>" && (
              <Row>
                <Col span={24} className={styles.dividerStyle}>
                  <div
                    className={classNames({
                      [styles.links]: true,
                      [styles.activeLink]: activeLink === "#benefit",
                    })}
                    id="benefit"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h1 className={styles.heading}>Benefits</h1>
                    {companyDetails?.benefit && (
                      <div
                        className={styles.descriptionText}
                        dangerouslySetInnerHTML={{
                          __html: companyDetails?.benefit,
                        }}
                      />
                    )}
                  </div>
                  <Divider></Divider>
                </Col>
              </Row>
            )}
          {companyDetails?.interview_process &&
            companyDetails?.important_links !== "<p><br></p>" && (
              <Row>
                <Col span={24} className={styles.dividerStyle}>
                  <div
                    className={classNames({
                      [styles.links]: true,
                      [styles.activeLink]: activeLink === "#interview_process",
                    })}
                    id="interview_process"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h1 className={styles.heading}>Interview Process</h1>
                    {companyDetails?.interview_process && (
                      <div
                        className={styles.descriptionText}
                        dangerouslySetInnerHTML={{
                          __html: companyDetails?.interview_process,
                        }}
                      />
                    )}
                  </div>
                  <Divider></Divider>
                </Col>
              </Row>
            )}
          {companyDetails?.important_links &&
            companyDetails?.important_links !== "<p><br></p>" && (
              <Row>
                <Col span={24} className={styles.dividerStyle}>
                  <div
                    className={classNames({
                      [styles.links]: true,
                      // [styles.activeLink]: activeLink === "#important_links",
                    })}
                    id="important_links"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h1 className={styles.heading}>Important Links</h1>
                    {companyDetails?.important_links && (
                      <div
                        className={styles.descriptionText}
                        dangerouslySetInnerHTML={{
                          __html: companyDetails?.important_links,
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            )}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
