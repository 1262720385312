import { createSlice } from "@reduxjs/toolkit";

import { getSuperAdminRecentJobPostsAsync } from "./thunks";
import { SuperAdminRecentJobPostsState } from "./types";

const initialState: SuperAdminRecentJobPostsState = {
  superadminRecentJobPosts: [],
  superadminRecentJobPostsRequestStatus: "initial",
};

export const getSuperAdminRecentJobpostsSlice = createSlice({
  name: "superadmin/superadminRecentJobPosts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuperAdminRecentJobPostsAsync.pending, (state) => {
      state.superadminRecentJobPostsRequestStatus = "started";
    });
    builder.addCase(getSuperAdminRecentJobPostsAsync.fulfilled, (state, action) => {
      state.superadminRecentJobPostsRequestStatus = "done";
      state.superadminRecentJobPosts = action.payload.results;
    });
    builder.addCase(getSuperAdminRecentJobPostsAsync.rejected, (state) => {
      state.superadminRecentJobPostsRequestStatus = "failed";
    });
  },
});

export default getSuperAdminRecentJobpostsSlice;
