import { useMediaQuery } from "react-responsive";
import {
  EXTRA_EXTRA_SMALL_BREAKPOINT,
  BREAKPOINT,
  MOBILE_BREAKPOINT_END,
  TAB_BREAKPOINT,
} from "shared/constants/responsive";

export const useIsExtraExtraSmall = () =>
  useMediaQuery({ query: `(max-width: ${EXTRA_EXTRA_SMALL_BREAKPOINT}px)` });
export const useIsMobile = () =>
  useMediaQuery({ query: `(max-width: ${BREAKPOINT}px)` });
export const useIsTab = () =>
  useMediaQuery({ query: `(max-width: ${TAB_BREAKPOINT}px)` });
export const useLaptop = () =>
  useMediaQuery({ query: `(max-width: ${MOBILE_BREAKPOINT_END}px)` });
