import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSuperAdminApplicantsAsync } from "./thunks";
import { SuperAdminApplicantsState } from "./types";

const initialState: SuperAdminApplicantsState = {
  superadmin_applicants: [],
  totalCount: 0,
  currentPage: 1,
  pageSize: 10,
  superadmin_applicantsRequestStatus: "initial",
};

export const getSuperAdminApplicantsSlice = createSlice({
  name: "superadmin/applicants",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSuperAdminApplicantsAsync.pending, (state) => {
      state.superadmin_applicantsRequestStatus = "started";
    });
    builder.addCase(getSuperAdminApplicantsAsync.fulfilled, (state, action) => {
      state.superadmin_applicantsRequestStatus = "done";
      state.totalCount = action.payload.count;
      state.superadmin_applicants = action.payload.results;
    });
    builder.addCase(getSuperAdminApplicantsAsync.rejected, (state) => {
      state.superadmin_applicantsRequestStatus = "failed";
    });
  },
});

export default getSuperAdminApplicantsSlice;
