import { publicJobsAPI } from "api/base";

import { GetJobs, HomeOpenJobs, HomeOpenCompanies, GetJobById } from "./types";

export const getJobs: GetJobs = (params) => {
  return publicJobsAPI.get("/jobs/", { params });
};

export const getJobById: GetJobById = (id) => {
  return publicJobsAPI.get(`/jobs/${id}/`);
};
export const homeOpenJobs: HomeOpenJobs = () => {
  return publicJobsAPI.get("/home-open-jobs/");
};

export const homeOpenCompanies: HomeOpenCompanies = () => {
  return publicJobsAPI.get("/home-companies-open-jobs/");
};

export const postJobViewById = (id: number | string) => {
  return publicJobsAPI.post(`/job-view/`, { jobs: id });
};
