import { Form, message, Row, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styles from "./createJob.module.scss";
import classNames from "classnames";
import Input from "components/Input";
import Button from "components/Button";
import SelectInput from "components/SelectInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import RadioButton from "components/RadioButton";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  getJobDetailsAsync,
  postJobsAsync,
  putJobAsync,
} from "store/admin/jobs/thunks";
import { Col } from "antd";
import Checkbox from "components/CheckBox";
import { useIsMobile } from "shared/hooks";
import { ROUTES } from "shared/routes/constants";
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from "store/hooks";
import { superAdminJobs } from "store/superadmin/jobs/selectors";
import { getSuperAdminJobsCategoryAsync } from "store/superadmin/jobs/thunks";
import { IntValueOptionType, OptionType, PropsType } from "./types";
import { JobsDataPost } from "api/admin/jobs/types";
import { getJobLocationsAsync } from "store/admin/jobLocations/thunks";
import { jobLocations } from "store/admin/jobLocations/selectors";
import { getSuperAdminJobTagsAsync } from "store/superadmin/tags/thunks";
import { superAdminTags } from "store/superadmin/tags/selectors";
import { adminJobs } from "store/admin/jobs/selectors";
import { isValidEmail, isValidUrl } from "shared/helpers/urlHelper";

const Title = Typography.Title;

// const Text = Typography.Text;
const CreateJob: React.FC<PropsType> = ({ isEditPage = false }) => {
  const { jobId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [job_description, setJobDescription] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobExperience, setJobExperience] = useState("REQUIRED");
  const [salaryType, setSalaryType] = useState("SALARY_RANGE");
  const [jobTags, setJobTags] = useState<string[]>([]);
  const [isRemote, setIsRemote] = useState(false);
  const [isVisaSponsored, setIsVisaSponsored] = useState(false);
  const [isShowOtherLocation, setIsShowOtherLocation] = useState(false);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const unwrap = useUnwrapAsyncThunk();

  useEffect(() => {
    unwrap(getSuperAdminJobsCategoryAsync());
    unwrap(getSuperAdminJobTagsAsync());
    unwrap(getJobLocationsAsync());
  }, []);

  useEffect(() => {
    form.resetFields();
    setJobDescription("");
    setSalaryType("");
    setIsRemote(false);
    setIsVisaSponsored(false);
    setJobExperience("");
    setJobTags([]);
  }, [pathname]);

  useEffect(() => {
    if (jobId) {
      unwrap(getJobDetailsAsync(jobId)).then((response) => {
        form.setFieldsValue(response);
        setJobDescription(response.job_description);
        setSalaryType(response.salary_type);
        setIsRemote(response.is_remote);
        setIsVisaSponsored(response.is_visa_sponsored);
        setJobExperience(response.experience_type);
        setJobTags(response.job_tags);
        if (response?.job_location === "Outside Japan") {
          setIsShowOtherLocation(true);
        }
      });
    }
  }, [jobId]);

  const jobTagsData = useAppSelector(superAdminTags);
  const data = useAppSelector(superAdminJobs).superadmin_jobs_categories;
  const jobLocation = useAppSelector(jobLocations).jobLocations;
  const adminJobCreateStatus = useAppSelector(adminJobs).postJobsCreateStatus;
  const adminJobUpdateStatus = useAppSelector(adminJobs)
    .patchAdminRequestStatus;

  const handleTagChange = (tags: string[]) => {
    if (tags.length > 12) {
      form.setFieldsValue({ job_tags: jobTags });
      message.error("Tag can not be added more then 12", 1);
    } else {
      setJobTags(tags);
    }
  };
  const handleIsRemote = (e: CheckboxChangeEvent) => {
    setIsRemote(e.target.checked);
  };

  const handleVisaSposored = (e: CheckboxChangeEvent) => {
    setIsVisaSponsored(e.target.checked);
  };

  const locationOptions: OptionType[] = jobLocation
    .map((location) => ({
      name: location.location,
      value: location.location,
    }))
    .filter(
      (a, i) => jobLocation.findIndex((s) => a.name === s.location) === i
    );

  const onSelectJobLocation = (location: string) => {
    if (location === "Outside Japan") {
      setIsShowOtherLocation(true);
    } else {
      setIsShowOtherLocation(false);
      if (!isEditPage) {
        form.setFieldsValue({ other_location: "" });
      }
    }
  };

  const jobsSkillRelatedTags: OptionType[] = jobTagsData.map((job) => ({
    name: job.job_tag,
    value: job.job_tag,
  }));

  const RequiredOptions = [
    { name: "Required", value: "REQUIRED" },
    { name: "Not Required", value: "NOT_REQUIRED" },
  ];
  const EnglishReqired = [
    { name: "Required", value: "REQUIRED" },
    { name: "Not Required", value: "NOT_REQUIRED" },
  ];
  const publishStatus = [
    { name: "Published", value: "PUBLISHED" },
    { name: "Unpublished", value: "UNPUBLISHED" },
  ];

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["link"],
  ];

  useEffect(() => {
    setJobDescription(job_description);
  }, [job_description]);

  const onFinish = (values: JobsDataPost) => {
    const x = {
      ...values,
      job_description: job_description,
      job_tags: jobTags,
    };
    if (isEditPage && jobId) {
      dispatch(putJobAsync({ jobId: jobId, data: { ...x } })).then(
        (response) => {
          message.success("Job Successfully edited", 1);
          navigate(ROUTES.PATHS.ADMIN.ADMIN_JOBS);
        }
      );
    } else {
      dispatch(postJobsAsync({ ...x })).then((response) => {
        message.success("Job Successfully added", 1);
        navigate(ROUTES.PATHS.ADMIN.ADMIN_JOBS);
      });
    }
  };

  const jobCategoryOptions: IntValueOptionType[] = data.map((category) => ({
    name: category.name,
    value: category.id,
  }));

  React.useEffect(() => {
    form.setFields([
      {
        name: "job_tags",
        value: jobTags,
      },
      {
        name: "is_remote",
        value: isRemote,
      },
      {
        name: "is_visa_sponsored",
        value: isVisaSponsored,
      },
      {
        name: "experience_type",
        value: jobExperience,
      },
    ]);
  }, [jobTags, isRemote, isVisaSponsored, jobExperience]);

  React.useEffect(() => {
    form.setFields([
      {
        name: "salary_type",
        value: salaryType,
      },
      {
        name: "is_remote",
        value: false,
      },
      {
        name: "is_visa_sponsored",
        value: false,
      },
    ]);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.backIcon}
          onClick={() => navigate(ROUTES.PATHS.ADMIN.ADMIN_JOBS)}
        >
          <span style={{ padding: "0px 2px" }}>
            <ArrowLeftOutlined />
          </span>{" "}
          All Posts
        </div>

        <Title level={2}>{isEditPage ? "Update" : "Create New"} Job Post</Title>
        <Title level={4}>General information</Title>
        <Form
          form={form}
          onFinish={onFinish}
          className={classNames({
            [styles.form]: true,
            [styles["is-mobile"]]: isMobile,
          })}
          layout="vertical"
          scrollToFirstError={true}
        >
          <>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="job_title"
                label="Job Title"
                rules={[
                  {
                    required: true,
                    message: "Please enter job title!",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="job_location"
                label="Location"
                rules={[
                  {
                    required: true,
                    message: "Please choose job location",
                  },
                ]}
              >
                <SelectInput
                  options={locationOptions}
                  onSelect={onSelectJobLocation}
                />
              </Form.Item>
            </Col>
            {isShowOtherLocation && (
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="other_location"
                  extra="Please enter country location."
                  rules={[
                    {
                      required: true,
                      message: "Please enter country location",
                    },
                  ]}
                >
                  <Input placeholder="" type="text" />
                </Form.Item>
              </Col>
            )}
            <Row>
              <Col>
                <Form.Item name="is_remote">
                  <Checkbox onChange={handleIsRemote} checked={isRemote}>
                    Remote
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="is_visa_sponsored">
                  <Checkbox
                    onChange={handleVisaSposored}
                    checked={isVisaSponsored}
                  >
                    Visa Sponsored
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="job_category"
              label="Job Category"
              rules={[
                {
                  required: true,
                  message: "Please choose job category",
                },
              ]}
            >
              <SelectInput options={jobCategoryOptions} />
            </Form.Item>
            <Form.Item
              name="job_type"
              label="Job Type"
              rules={[{ required: true, message: "Please select job type" }]}
            >
              <RadioButton
                title=""
                options={[
                  { label: "Full Time", value: "FULL_TIME" },
                  { label: "Part Time", value: "PART_TIME" },
                  { label: "Contract", value: "CONTRACT" },
                  { label: "Freelancing", value: "FREELANCING" },
                ]}
                // defaultValue={jobType}
                value={jobType}
                onChange={(e) => setJobType(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="experience_type"
              label="Minimum Experience"
              rules={[
                {
                  required: true,
                  message: "Please choose experience type",
                },
              ]}
            >
              <RadioButton
                title=""
                options={[
                  { label: "Required", value: "REQUIRED" },
                  { label: "Not Required", value: "NOT_REQUIRED" },
                ]}
                defaultValue={"REQUIRED"}
                onChange={(e) => setJobExperience(e.target.value)}
                value={jobExperience}
              />
            </Form.Item>
            {jobExperience !== "NOT_REQUIRED" && (
              <Form.Item
                name="experience_year"
                rules={[
                  {
                    required: true,
                    message: "Please enter experience year",
                  },
                ]}
              >
                <Input addonBefore="Years" />
              </Form.Item>
            )}
            <Form.Item
              name="salary_type"
              label="Salary/Compensation"
              rules={[
                {
                  required: true,
                  message: "Please select salary type",
                },
              ]}
            >
              <RadioButton
                title=""
                options={[
                  { label: "Salary Range", value: "SALARY_RANGE" },
                  { label: "Negotiable", value: "NEGOTIABLE" },
                ]}
                defaultValue={salaryType}
                value={salaryType}
                onChange={(e) => setSalaryType(e.target.value)}
              />
            </Form.Item>

            {salaryType !== "NEGOTIABLE" && (
              <Form.Item
                extra="Annual Salary or Compensation in JPY"
                rules={[
                  {
                    required: true,
                    message: "Please enter Annual Salary or Compensation",
                  },
                ]}
              >
                <Row gutter={[24, 0]}>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name="salary_min"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Min Salary",
                        },
                      ]}
                    >
                      <Input placeholder="Min Salary" type="number" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name="salary_max"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Max Salary!",
                        },
                      ]}
                    >
                      <Input placeholder="Max Salary" type="number" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            )}

            <Title level={5}>Language Requirements</Title>
            <Form.Item>
              <Row gutter={[24, 0]}>
                <Col xs={24} lg={24}>
                  <Form.Item
                    name="japanese"
                    label="Japanese"
                    rules={[
                      {
                        required: true,
                        message: "Please choose an option",
                      },
                    ]}
                  >
                    <SelectInput options={RequiredOptions} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item>
              <Row gutter={[24, 0]}>
                <Col xs={24} lg={24}>
                  <Form.Item
                    name="english"
                    label="English"
                    rules={[
                      {
                        required: true,
                        message: "Please choose an option",
                      },
                    ]}
                  >
                    <SelectInput options={EnglishReqired} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Title level={4}>Job Description</Title>
            <>
              <ReactQuill
                modules={{ toolbar: toolbarOptions }}
                theme="snow"
                value={job_description || ""}
                onChange={(value) => setJobDescription(value)}
                className={styles.richTextEditor}
                style={{ marginBottom: "20px", background: "#fff" }}
                readOnly={false}
              />
            </>
            <Form.Item
              name="job_tags"
              label="Job Tags"
              extra="Enter some tag/keywords related to the job (max 12)."
              rules={[
                {
                  required: true,
                  message: "Please choose at least one tag",
                },
              ]}
              style={{ padding: "2px 0px" }}
            >
              <Select
                mode="multiple"
                allowClear
                options={jobsSkillRelatedTags}
                onChange={handleTagChange}
                defaultValue={jobTags}
                value={jobTags}
              />
            </Form.Item>
            <Title level={4}>Job Apply Methods</Title>
            <Form.Item
              extra="Enter the link to the job application form or write the email address where to send the application"
              name="apply_url"
              label="Apply URL/Email"
              rules={[
                {
                  required: false,
                  message:
                    "Please enter a valid url (http://example.com) or email (username@example.com)",
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    } else if (isValidUrl(value)) {
                      return Promise.resolve();
                    } else if (isValidEmail(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        "Please enter a valid url or email"
                      );
                    }
                  },
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Form.Item
              extra="Enter the email address where to send the queries about this job posts."
              name="ask_more"
              label="Ask More Email"
              rules={[
                {
                  required: false,
                  message: "Please enter ask more",
                },
              ]}
            >
              <Input placeholder="" />
            </Form.Item>
            <Title level={4}>Publish Information</Title>
            <Form.Item
              name="publish_status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please choose publish status",
                },
              ]}
            >
              <SelectInput options={publishStatus} />
            </Form.Item>
            <Button
              className={styles.button}
              type="primary"
              isBordered={true}
              htmlType="submit"
              style={{ marginRight: "30px" }}
              loading={
                adminJobCreateStatus === "started" ||
                adminJobUpdateStatus === "started" ||
                false
              }
            >
              {isEditPage ? "Update" : "Create"} job Post
            </Button>
            <Button
              className={styles.CancelButton}
              type="primary"
              isBordered={true}
              onClick={() => navigate(ROUTES.PATHS.ADMIN.ADMIN_JOBS)}
            >
              Cancel
            </Button>
          </>
        </Form>
      </div>
    </>
  );
};

export default CreateJob;
