import React from "react";
import classNames from "classnames";
import styles from "./navigation.module.scss";
import { Dropdown, Menu, Spin } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsTab } from "shared/hooks";
import { ROUTES } from "shared/routes/constants";
import { useAppSelector } from "store/hooks";
import { selectVerifyTokenRequestStatus } from "store/shared/auth/selectors";
import { selectLoginStatus } from "./selectors";
import Profile from "uicontainers/_shared/Profile";
import { isAdminUser, isSuperUser } from "shared/helpers/authHelper";

const Navigation = () => {
  const isCheckingToken =
    useAppSelector(selectVerifyTokenRequestStatus) === "started";
  const isLoggedIn = useAppSelector(selectLoginStatus);
  const navigate = useNavigate();
  const isTab = useIsTab();
  const location = useLocation();
  const isloginURL = location.pathname.includes(ROUTES.PATHS.PUBLIC.LOGIN);

  const pathMatchRoute = (route: string) => {
    if (route === location.pathname) {
      return true;
    }
  };
  // @ts-ignore
  const handleClick = (info: MenuInfo) => {
    if (info.key === "services") {
      window.location.href = "https://www.businessinjapan.com/services/";
    }
    if (info.key === "jobs") {
      navigate(ROUTES.PATHS.PUBLIC.JOBS);
    }
    if (info.key === "companies") {
      navigate(ROUTES.PATHS.PUBLIC.COMPANIES);
    }
    if (info.key === "postJob") {
      navigate(ROUTES.PATHS.PUBLIC.LOGIN);
    }
    if (info.key === "SignUp") {
      navigate(ROUTES.PATHS.PUBLIC.SIGN_UP);
    }
    if (info.key === "Login") {
      navigate(ROUTES.PATHS.PUBLIC.LOGIN);
    }
  };
  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="services">Services</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="jobs">Jobs</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="companies">Companies</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="postJob">Post Job</Menu.Item>
      <Menu.Divider />
      {isloginURL ? (
        <Menu.Item key="SignUp">Sign Up</Menu.Item>
      ) : (
        <Menu.Item key="Login">Login</Menu.Item>
      )}
    </Menu>
  );

  const isShowDashboardMenuItem = () => {
    return (
      isLoggedIn &&
      (location.pathname.includes(ROUTES.PATHS.PUBLIC.JOBS) ||
        location.pathname.includes(ROUTES.PATHS.PUBLIC.COMPANIES)) &&
      !location.pathname.includes(ROUTES.PATHS.ADMIN.ADMIN_JOBS) &&
      !location.pathname.includes(ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_JOBS) &&
      !location.pathname.includes(
        ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_COMPANIES
      )
    );
  };

  const getAdminUrl = () => {
    if (isAdminUser()) {
      return ROUTES.PATHS.ADMIN.ADMIN_HOME;
    }
    if (isSuperUser()) {
      return ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_HOME;
    }
    return "";
  };

  if (isCheckingToken) {
    return <Spin spinning={true} size="large"></Spin>;
  } else {
    return (
      <div
        className={classNames({
          [styles.container]: true,
          // [styles["is-mobile"]]: isMobile,
        })}
      >
        {!isTab && (
          <div
            className={classNames({
              [styles.btnContainer]: true,
            })}
          >
            <>
              {isShowDashboardMenuItem() && (
                <div
                  className={styles.allLink}
                  onClick={() => navigate(getAdminUrl())}
                >
                  <p
                    className={
                      pathMatchRoute(ROUTES.PATHS.PUBLIC.JOBS)
                        ? styles.navbarListItemNameActive
                        : styles.navbarListItemName
                    }
                  >
                    Dashboard
                  </p>
                </div>
              )}
              <div
                className={styles.allLink}
                onClick={() =>
                  (window.location.href =
                    "https://www.businessinjapan.com/services/")
                }
              >
                <p className={styles.navbarListItemName}>Services</p>
              </div>
              <div
                className={styles.allLink}
                onClick={() => navigate(ROUTES.PATHS.PUBLIC.JOBS)}
              >
                <p
                  className={
                    pathMatchRoute(ROUTES.PATHS.PUBLIC.JOBS)
                      ? styles.navbarListItemNameActive
                      : styles.navbarListItemName
                  }
                >
                  Jobs
                </p>
              </div>
              <div
                className={styles.allLink}
                onClick={() => navigate(ROUTES.PATHS.PUBLIC.COMPANIES)}
              >
                <p
                  className={
                    pathMatchRoute(ROUTES.PATHS.PUBLIC.COMPANIES)
                      ? styles.navbarListItemNameActive
                      : styles.navbarListItemName
                  }
                >
                  Companies
                </p>
              </div>
            </>
            {!isLoggedIn && (
              <>
                <div
                  className={styles.postJobLink}
                  onClick={() => navigate(ROUTES.PATHS.PUBLIC.LOGIN)}
                >
                  <p>Post Job</p>
                </div>
                {isloginURL ? (
                  <div
                    className={styles.allLink}
                    onClick={() => navigate(ROUTES.PATHS.PUBLIC.SIGN_UP)}
                  >
                    <p
                      className={
                        pathMatchRoute(ROUTES.PATHS.PUBLIC.SIGN_UP)
                          ? styles.navbarListItemNameActive
                          : styles.navbarListItemName
                      }
                    >
                      Sign Up
                    </p>
                  </div>
                ) : (
                  <div
                    className={styles.allLink}
                    onClick={() => navigate(ROUTES.PATHS.PUBLIC.LOGIN)}
                  >
                    <p
                      className={
                        pathMatchRoute(ROUTES.PATHS.PUBLIC.LOGIN)
                          ? styles.navbarListItemNameActive
                          : styles.navbarListItemName
                      }
                    >
                      Log In
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {isTab && !isLoggedIn && (
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <div
              className={classNames({
                [styles.container]: true,
                [styles["is-mobile"]]: isTab,
              })}
              onClick={() => {}}
            >
              <div className={styles.downIcon}>
                <MenuOutlined />
              </div>
            </div>
          </Dropdown>
        )}
        {isLoggedIn && <Profile isLoggedIn={isLoggedIn} />}
      </div>
    );
  }
};

export default Navigation;
