import Button from "components/Button";
import MasterTable from "components/tables/MasterTable";
import TableHeader from "uicontainers/_shared/TableHeader";
import React, { FC, useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from "store/hooks";
import {
  createSuperUserTagsTableData,
  selectCurrentPage,
  selectPageSize,
  selectTotalCount,
} from "store/superadmin/tags/selectors";
import { getSuperAdminTagsAsync } from "store/superadmin/tags/thunks";
import { setCurrentPage, setPageSize } from "store/superadmin/tags/actions";
import { COLUMNS } from "./constants";
import { PaginationProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TagModal from "./TagModal";

const SuperAdminTags: FC = () => {
  const [visible, setVisible] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const unwrap = useUnwrapAsyncThunk();
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const pageSize = useAppSelector(selectPageSize);
  const dispatch = useAppDispatch();

  useEffect(() => {
    unwrap(getSuperAdminTagsAsync());
  }, []);

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    if (pageSize) {
      searchParams = { ...searchParams, page_size: pageSize };
    }
    dispatch(getSuperAdminTagsAsync(searchParams));
  }, [searchValue, currentPage, pageSize]);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };

  const tableData = useAppSelector(createSuperUserTagsTableData);

  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    currentPage,
    pageSize
  ) => {
    dispatch(setCurrentPage(currentPage));
    dispatch(setPageSize(pageSize));
  };

  const button = (
    <Button
      onClick={() => setVisible(true)}
      type="primary"
      isBordered={true}
      icon={<PlusOutlined />}
    >
      <span>Create Tag</span>
    </Button>
  );

  return (
    <div style={{ padding: "1.5rem", marginTop: "20px" }}>
      <TableHeader button={button} title="Tags Setting" onSearch={handleSearch} />
      <MasterTable
        data={[...tableData]}
        columns={COLUMNS}
        pagination={{
          currentPage: currentPage,
          count: totalCount,
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          onChange,
        }}
      />
      {visible && <TagModal visible={visible} setVisible={setVisible} />}
    </div>
  );
};
export default SuperAdminTags;
