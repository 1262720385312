import React from "react";
import styles from "./home.module.scss";
import { Col, Row } from "antd";
import Summary from "../Summary";
import CompanyProfile from "../CompanyProfile";
import JobApplications from "../JobApplications";
import RecenetApplicants from "../RecentApplicants";
import RecentQueries from "../RecentQueries";

// const Summary = React.lazy(() => import("../Summary"));
// const CompanyProfile = React.lazy(() => import("../CompanyProfile"));

const Home = () => {
  return (
    <div className={styles.bodyContainer}>
      <Row gutter={[0, { xs: 8, sm: 8, md: 16, lg: 16 }]}>
        <Col span={24}>
          <CompanyProfile />
        </Col>
        <Col span={24}>
          <Summary />
        </Col>
        <Col span={24}>
          <JobApplications />
        </Col>
        <Col span={24}>
          <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 32 }, 0]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <RecenetApplicants />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <RecentQueries />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
