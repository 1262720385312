import { getJobsAsync, postJobsAsync, updateAdminJobsAsync } from "./thunks";
import slice from "./slice";
const { setCurrentPage, setPageSize } = slice.actions;
export {
  getJobsAsync,
  setCurrentPage,
  setPageSize,
  postJobsAsync,
  updateAdminJobsAsync,
};
