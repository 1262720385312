import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSuperAdminRecentApplicants } from "api/superadmin/Dashboard/recent-applicants";

export const getSuperAdminRecentApplicantsAsync = createAsyncThunk(
  "superadmin/recejob/recent-applicants",
  async () => {
    const response = await getSuperAdminRecentApplicants();
    return response;
  }
);
