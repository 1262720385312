import React, { FC } from "react";
import { Upload } from "antd";

import { UploadProps } from "antd/lib/upload/Upload";

import { Props } from "./types";
import styles from "./fileUploader.module.scss";

import { onChange, beforeUpload, onUploadHandler } from "./helpers";

const FileUploader: FC<Props> = (props) => {
  const { Dragger } = Upload;

  const {
    children,
    name,
    onUpload,
    fileType,
    isDragger,
    ...otherProps
  } = props;

  const fileUploadProps: UploadProps = {
    name,
    // beforeUpload: beforeUpload(fileType),
    onChange: onChange,
    customRequest: onUploadHandler(name, onUpload),
  };

  return (
    <div className={styles.uploader}>
      {
        isDragger ? (
        <Dragger {...otherProps} {...fileUploadProps}>
          {children}
        </Dragger>
      ) : (
        <Upload {...fileUploadProps} {...otherProps}>
              {
                children
            
              
              }
        </Upload>
      )}
    </div>
  );
};

export default FileUploader;
