import { Layout } from "antd";
import React, { FC } from "react";
import classNames from "classnames";

import styles from "./content.module.scss";
import { Props } from "./types";
import Header from "../Header";
import LeftSection from "../LeftSection";
import { useIsMobile } from "shared/hooks";
import { useAppSelector } from "store/hooks";
import { selectLoginStatus } from "../Navigation/selectors";
import { useLocation } from "react-router-dom";
import { ROOT, ROUTES } from "shared/routes/constants";
// import SocialTag from "uicontainers/_shared/SocialTag/SocialTag";

export const Content: FC<Props> = (props) => {
  const { children = true, footer, leftSection } = props;
  const isMobile = useIsMobile();
  const isLoggedIn = useAppSelector(selectLoginStatus);
  const location = useLocation();

  const getChildrenStyle = () => {
    return isLoggedIn
      ? isMobile
        ? {}
        : location.pathname === ROUTES.PATHS.PUBLIC.LOGIN ||
          location.pathname === ROOT ||
          location.pathname.includes(ROUTES.PATHS.PUBLIC.COMPANIES) ||
          location.pathname.includes(ROUTES.PATHS.PUBLIC.JOBS)
        ? location.pathname.includes(
            ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_JOBS
          ) ||
          location.pathname.includes(ROUTES.PATHS.ADMIN.ADMIN_JOBS) ||
          location.pathname.includes(
            ROUTES.PATHS.SUPER_ADMIN.SUPER_ADMIN_COMPANIES
          )
          ? { marginLeft: 200 }
          : {}
        : { marginLeft: 200 }
      : {};
  };

  return (
    <Layout.Content
      className={classNames({
        [styles.content]: true,
        [styles["is-mobile"]]: isMobile,
      })}
    >
      {/* <SocialTag
        title="Business In Japan"
        description="Find good work or reach GoodPeople in Japan"
        name="bij"
        type="website"
        image={process.env.REACT_APP_FRONT_END_BASE_URL + logoImagePath}
      /> */}
      <Header />
      <div className={styles.main}>
        {leftSection && <LeftSection>{leftSection}</LeftSection>}
        <div className={styles.children} style={getChildrenStyle()}>
          {children}
        </div>
      </div>
      {footer && <Layout.Footer>{footer}</Layout.Footer>}
    </Layout.Content>
  );
};

export default Content;
