import { useCallback } from "react";
import { message } from "antd";
import { useUnwrapAsyncThunk, useAppDispatch } from "store/hooks";

import { BasicInfo } from "./types";
import {
  getJobsAsync,
  updateAdminJobsAsync,
  deleteJobAsync,
} from "store/admin/jobs/thunks";

export const useUpdateSuperAdminBasicInfo = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback(
    (basicInfo: BasicInfo, id: number) => {
      return unwrap(updateAdminJobsAsync({ ...basicInfo, jobsId: id }))
        .then((response) => {
          message.success("Job Status Updated Successfully", 0.5);
        })
        .catch((e) => {
          // message.error(e.message);
        });
    },
    [updateAdminJobsAsync]
  );
};

export const useDeleteAdminJob = () => {
  const unwrap = useUnwrapAsyncThunk();
  const dispatch = useAppDispatch();

  return useCallback(
    (jobId: number) => {
      return unwrap(deleteJobAsync(jobId))
        .then((response) => {
          dispatch(getJobsAsync()).then(() => {
            message.success("Job deleted Successfully", 0.5);
          });
        })
        .catch((e) => {
          // message.error(e.message);
        });
    },
    [deleteJobAsync]
  );
};
