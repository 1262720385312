import React, { FC } from "react";
import styles from "./resetPassword.module.scss";
import toryGate from "../../../assets/images/tory_gate.svg";

import { Divider, Form, Row, Col, Spin, Result } from "antd";

import Input from "components/Input";

import Button from "components/Button";
import { useQuery } from "shared/hooks/hooks";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectResetPasswordRequestStatus } from "store/shared/auth/selectors";
import { resetPasswordValues } from "./types";
import { resetPasswordAsync } from "store/shared/auth/thunks";

const ResetPassword: FC = () => {
  const query = useQuery();
  const securityCode = query.get("securityCode");
  const token = query.get("token");
  const dispatch = useAppDispatch();
  const isResetPasswordLoading =
    useAppSelector(selectResetPasswordRequestStatus) === "started";
  const isResetPasswordSuccess =
    useAppSelector(selectResetPasswordRequestStatus) === "done";
  const onFinish = (values: resetPasswordValues) => {
    dispatch(
      resetPasswordAsync({
        securityCode,
        token,
        password: values.password,
      })
    );
  };

  return (
    <div className={styles.main}>
      {isResetPasswordSuccess && (
        <Result
          status="success"
          title="You successfully changed your password!"
          subTitle="You can now login using the new password"
        />
      )}
      {!isResetPasswordSuccess && (
        <Spin spinning={isResetPasswordLoading}>
          <Row justify="center" align="middle" className={styles.row}>
            <Col xs={0} sm={0} md={10} lg={10} xl={10}>
              <div className={styles.firstContainer}>
                <h4 className={styles.paragraph}>
                  An account gives access to Free job posting in BiJ job portal
                  to reach a wide range of Talents in Japan
                </h4>
                <img
                  className={styles.imageLogo}
                  src={toryGate}
                  alt="toryGate"
                />
              </div>
            </Col>
            <Col xs={0} sm={0} md={2} lg={2} xl={2}>
              <Row justify="center" align="middle">
                <Divider className={styles.divider} type="vertical" />
              </Row>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Row
                justify="start"
                align="middle"
                className={styles.rowContianer}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  className={styles.fromContainer}
                >
                  <h4 className={styles.title}>Password Reset</h4>
                  <h6 className={styles.subTitle}>
                    Enter your new password and repeat it.
                  </h6>
                  <Form onFinish={onFinish} size="large" layout="vertical">
                    <Row gutter={[16, 0]}>
                      <Col className="gutter-row" span={24}>
                        <Form.Item
                          name="password"
                          label="Password"
                          extra="Strong password contains at least 8 characters with uppper and lower case and one number or symbol"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password!",
                            },
                          ]}
                        >
                          <Input required inputType="password" />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={24}>
                        <Form.Item
                          name="confirm"
                          label="Confrim Password"
                          dependencies={["password"]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "The two passwords that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input required inputType="password" />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" span={24}>
                        <Button
                          type="primary"
                          danger
                          shape="round"
                          htmlType="submit"
                          className={styles.homeButton}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      )}
    </div>
  );
};

export default ResetPassword;
