import { Spin } from "antd";
import MasterTable from "components/tables/MasterTable";
import React, { useEffect } from "react";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import { superAdminRecentApplicants } from "store/superadmin/Dashboard/recent-applicants/selectors";
import { getSuperAdminRecentApplicantsAsync } from "store/superadmin/Dashboard/recent-applicants/thunks";
import Card from "uicontainers/_shared/Card";
import { COLUMNS } from "./constants";
import { transformRecentApplicantsToTableData } from "./helpers";
const RecentApplicants = () => {
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getSuperAdminRecentApplicantsAsync());
  }, []);

  const tableData = transformRecentApplicantsToTableData(
    useAppSelector(superAdminRecentApplicants).SuperAdminRecentApplicants
  );

  return (
    <Spin spinning={false}>
      <Card title="Recent Applicants" hasPadding={false}>
        <MasterTable data={[...tableData]} columns={COLUMNS} />
      </Card>
    </Spin>
  );
};

export default RecentApplicants;
