import { Column } from "components/tables/MasterTable/types";

export const COLUMNS: Column[] = [
  {
    name: "Applicants",
    key: "full_name",
    type: "text",
    width: "33.33%",
  },
  {
    name: "Email",
    key: "email",
    type: "text",
    width: "33.33%",
  },
  {
    name: "Date",
    key: "created_at",
    type: "text",
    width: "33.33%",
  },
];
