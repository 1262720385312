import LineChart from "components/LineChart";
import React, { useEffect } from "react";
import Card from "uicontainers/_shared/Card/Card";
import styles from "./jobApplications.module.scss";
import { Radio, RadioChangeEvent } from "antd";
import {
  useUnwrapAsyncThunk,
  useAppSelector,
  useAppDispatch,
} from "store/hooks";
import { superAdminApplicantsCharts } from "store/superadmin/Dashboard/job-applications-chart/selectors";
import { getSuperAdminRecentApplicantsChartAsync } from "store/superadmin/Dashboard/job-applications-chart/thunks";
import { selectCategory } from "./actions";
import { prepareChartData } from "./helpers";
import { selectcategory } from "./selectors";

const JobApplications = () => {
  const dispatch = useAppDispatch();
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getSuperAdminRecentApplicantsChartAsync());
  }, []);
  const { jobApplicationsChart } = useAppSelector(superAdminApplicantsCharts);
  const category = useAppSelector(selectcategory);

  const onChange = (e: RadioChangeEvent) => {
    dispatch(selectCategory(e.target.value));
  };
  const data = prepareChartData(jobApplicationsChart, category);
  const renderCategorySelector = () => (
    <Radio.Group onChange={onChange} defaultValue="all_year" value={category}>
      <Radio.Button value="this_month">This month</Radio.Button>
      <Radio.Button value="last_month">Last month</Radio.Button>
      <Radio.Button value="all_year">All year</Radio.Button>
    </Radio.Group>
  );
  return (
    <Card title="Job Applications" rightHeader={renderCategorySelector()}>
      <div className={styles.chart}>
        <LineChart
          data={data}
          xField={category === "all_year" ? "month" : "date"}
          yField="count"
        />
      </div>
    </Card>
  );
};

export default JobApplications;
