import React from "react";
import { Dropdown, Space } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Queries } from "api/admin/queries/types";
import MenuActions from "./MenuAction";
import { SuperAdminQueries } from "api/superadmin/queries/types";
//props: Props
type Props = {
  isSuperAdmin: boolean;
  query: Queries | SuperAdminQueries;
};

const ActionButtonTwo = (props: Props) => {
  const { isSuperAdmin, query } = props;
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  return (
    <Dropdown
      overlay={
        <MenuActions
          isSuperAdmin={isSuperAdmin}
          query={query}
          setDropdownVisible={setDropdownVisible}
        />
      }
      trigger={["click"]}
      placement="bottomCenter"
      visible={dropdownVisible}
      onVisibleChange={(visible) => setDropdownVisible(visible)}
    >
      <Space>
        <MoreOutlined onClick={() => setDropdownVisible(true)} />
      </Space>
    </Dropdown>
  );
};

export default ActionButtonTwo;
