import { superadminAPI } from "api/base";
import {
  GetSuperAdminCompaniesList,
  GetSuperAdminCompanyNameList,
  UpdateSuperAdminCompanes,
} from "./types";

export const getSuperAdminCompaniesList: GetSuperAdminCompaniesList = (
  params
) => {
  return superadminAPI.get("/companies/", { params });
};

export const getSuperAdminCompanyNamesList: GetSuperAdminCompanyNameList = (
  params
) => {
  return superadminAPI.get("/company-names/", { params });
};

export const updateSuperAdminCompanies: UpdateSuperAdminCompanes = (
  data,
  id
) => {
  return superadminAPI.patch(`/companies/${id}/`, data);
};
