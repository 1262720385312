import React, { FC } from "react";
import { Menu } from "antd";
import { MenuActionsProps } from "./types";
import ReplyModal from "./ReplyModal";

const MenuActions: FC<MenuActionsProps> = (props) => {
  const [visible, setVisible] = React.useState(false);
  const { isSuperAdmin, query, setDropdownVisible } = props;

  // @ts-ignore
  const handleStatusChange = (info: MenuInfo) => {
    if (info.key === "REPLY") {
      setVisible(true);
      setDropdownVisible(false);
    }
  };

  return (
    <>
      <Menu onClick={handleStatusChange}>
        <Menu.Item key="REPLY">REPLY</Menu.Item>
        {/* <Menu.Divider /> */}
      </Menu>
      {visible && (
        <ReplyModal
          isSuperAdmin={isSuperAdmin}
          query={query}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </>
  );
};

export default MenuActions;
