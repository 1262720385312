import { Spin, Row, Col } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";
import Input from "components/Input";
import React, { useEffect, useState } from "react";
import styles from "./account.module.scss";
import { PersonalInfoType } from "./types";
import { useUpdatePersonalInfo, useUploadData } from "./hooks";
import { getProfileAsync } from "store/shared/auth/thunks";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import { getProfileData } from "store/shared/auth/selectors";
import { getCompanyProfileAsync } from "store/shared/auth/thunks";
import { getJobLocationsAsync } from "store/admin/jobLocations/thunks";
import { getIndustryAsync } from "store/public/companies/thunks";
import Button from "components/Button";
import { Tabs, TabPane } from "components/misc/helper";
import CreateCompany from "uicontainers/Public/CreateCompany";
import ProfilePicture from "uicontainers/_shared/ProfilePicture/ProfilePicture";

const Account = () => {
  const updateAccount = useUpdatePersonalInfo();
  const upload = useUploadData();
  const isLoading = false;
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getProfileAsync());
    unwrap(getJobLocationsAsync());
    unwrap(getIndustryAsync());
    unwrap(getCompanyProfileAsync());
  }, []);

  const p_data = useAppSelector(getProfileData)[0];
  const { profile_picture } = p_data || {};
  const [form] = useForm();
  const [_, setChangedValues] = useState<PersonalInfoType | undefined>(
    undefined
  );

  useEffect(() => {
    form.setFieldsValue(p_data);
  }, [p_data]);

  return (
    <div className={styles.bodyContainer}>
      <h1 className={styles.heading}>User Profile</h1>
      <Tabs defaultActiveKey="1" type="card" size={"small"}>
        <TabPane tab="User Profile" key="1">
          <h3 className={styles.heading_two}>User Information.</h3>
          <p>This is the User who manages the Account and Job Information.</p>
          <Form
            className={styles.form}
            onFinish={(values: PersonalInfoType) => {
              const { email, job_title, user_name, ...othhers } = values;
              updateAccount({
                ...othhers,
                email: email,
                job_title: job_title,
                user_name: user_name,
              });
              setChangedValues(undefined);
            }}
            onValuesChange={(changedValues) => {
              setChangedValues((prevState) => {
                return { ...prevState, ...changedValues };
              });
            }}
            form={form}
            layout="vertical"
          >
            <Spin spinning={isLoading}>
              <ProfilePicture
                profile_picture={profile_picture}
                updateAccount={updateAccount}
                upload={upload}
              />

              <div className={styles.nameContainer}>
                <Row gutter={[24, 0]}>
                  <Col xs={24} lg={12}>
                    <FormItem
                      name="user_name"
                      label="User Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your User Name!",
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>
                    <FormItem
                      name="email"
                      label="User Email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ]}
                    >
                      <Input />
                    </FormItem>
                    <FormItem name="job_title" label="Job Title">
                      <Input placeholder="Type here" />
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <Button
                className={styles.button}
                type="ghost"
                isBordered={true}
                htmlType="submit"
              >
                <div className={styles.textContainer}>
                  <span className={styles.buttonTextGhost}>Submit</span>
                </div>
              </Button>
            </Spin>
          </Form>
        </TabPane>
        <TabPane tab="Company Profile" key="2">
          <CreateCompany isCompanyEdit={true} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Account;
