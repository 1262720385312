import { RootState } from "store/types";

export const getPublicCompaniesApi = (state: RootState) =>
  state.resources.public.companies;

export const selectTotalCount = (state: RootState) =>
  state.resources.public.companies.totalCount;
export const selectCurrentPage = (state: RootState) =>
  state.resources.public.companies.currentPage;
export const selectNextPage = (state: RootState) =>
  state.resources.public.companies.next;
