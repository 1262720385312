import React from "react";
import styles from "./header.module.scss";
import classNames from "classnames";
import { Header as AntdHeader } from "../../../components/misc/helper";
import Logo from "components/misc/Logo";
import Navigation from "../Navigation/Navigation";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { toggleSidebar } from "store/shared/sidebar/actions";
import { useIsMobile } from "shared/hooks";
import { selectLoginStatus } from "../Navigation/selectors";
import { selectIsShowSidebar } from "store/shared/sidebar/selectors";

const Header = () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const isLoggedIn = useAppSelector(selectLoginStatus);
  const isShowSidebar = useAppSelector(selectIsShowSidebar);

  return (
    <AntdHeader
      className={classNames({
        [styles.container]: true,
      })}
    >
      <div className={styles.logoContainer}>
        {isMobile && isLoggedIn && (
          <span
            className={styles.burgerMenu}
            onClick={() => dispatch(toggleSidebar())}
          >
            {isShowSidebar ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          </span>
        )}
        <div>
          <Logo />
        </div>
      </div>
      <Navigation />
    </AntdHeader>
  );
};

export default Header;
