import React from "react";
import { Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import MenuActions from "./MenuActions";
import { Jobs } from "api/admin/jobs/types";

type Props = {
  job: Jobs;
};

const ActionButton = (props: Props) => {
  const { job } = props;
  const [visible, setVisible] = React.useState(false);

  return (
    <Dropdown
      overlay={<MenuActions job={job} setVisible={setVisible} />}
      trigger={["click"]}
      placement="bottomCenter"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <MoreOutlined />
    </Dropdown>
  );
};

export default ActionButton;
