import { RootState } from "store/types";

export const selectPublicJobs = (state: RootState) =>
  state.resources.public.jobs;

export const selectNextPage = (state: RootState) =>
  state.resources.public.jobs.next;

export const selectCurrentPage = (state: RootState) =>
  state.resources.public.jobs.currentPage;
