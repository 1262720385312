import React, { FC, useEffect } from "react";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "./sidebar.module.scss";
import { Props } from "./types";
import { useIsMobile } from "shared/hooks";
import { useAppSelector } from "store/hooks";
import { selectIsShowSidebar } from "store/shared/sidebar/selectors";

const { Sider } = Layout;

const Sidebar: FC<Props> = (props) => {
  const { menuItems, className = "" } = props;
  const isMobile = useIsMobile();
  const location = useLocation();
  const isShowSidebar = useAppSelector(selectIsShowSidebar);
  const [selectedTab, setSelectedTab] = React.useState("0");

  useEffect(() => {
    setSelectedTab(
      menuItems
        .findIndex((item) =>
          location.pathname.includes(
            !!item.parentRoute ? item.parentRoute : item.link
          )
        )
        .toString()
    );
  }, [location.pathname]);

  return (
    <Sider
      width={210}
      collapsedWidth="0"
      className={classNames({
        [styles.sidebarContainer]: true,
        [className]: Boolean(className),
        [styles["is-mobile"]]: isMobile,
        [styles["is-sidebar"]]: isShowSidebar,
      })}
      style={{
        overflow: "auto",
        position: "fixed",
        left: 0,
        top: 90,
        bottom: 0,
        zIndex: 90,
      }}
    >
      <Menu
        className={styles.sidebarMenus}
        mode="inline"
        selectedKeys={[selectedTab]}
      >
        {menuItems.map((menuItem, index) => (
          <Menu.Item key={index} icon={menuItem.icon}>
            {menuItem.title}
            <Link to={menuItem.link} />
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
