import { useEffect } from "react";
import { useLocation } from "react-router";
import { useIsMobile } from "shared/hooks";
import { useAppDispatch } from "store/hooks";
import { setSidebarToHidden } from "store/shared/sidebar/actions";

const ScrollToTop = (props: any) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  let isMobile = useIsMobile();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isMobile) {
      dispatch(setSidebarToHidden());
    }
  }, [location.pathname]);

  return props.children;
};

export default ScrollToTop;
