import React, { useEffect } from "react";
import { AppLink } from "shared/helpers/urlHelper";
import styles from "./jobDetails.module.scss";
import { Breadcrumb } from "antd";
import Button from "components/Button/Button";
import { useUnwrapAsyncThunk, useAppSelector } from "store/hooks";
import { GetJobById, PostJobViewById } from "store/public/jobs/thunks";
import { selectPublicJobs } from "store/public/jobs/selectors";
import { useParams } from "react-router-dom";
import SimpleJobCard from "components/SimpleJobCard";
import { Link } from "react-router-dom";
import { ROUTES } from "shared/routes/constants";
import DetailsJobCard from "components/JobDetails/DetailsJobCard/DetailsJobCard";

const JobDetails = () => {
  const { id } = useParams();

  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    if (id) {
      unwrap(GetJobById(id));
      unwrap(PostJobViewById(id));
    }
  }, [id]);

  const job = useAppSelector(selectPublicJobs)?.job;

  return (
    <div className={styles.main}>
      <div className={styles.breadCrumbContainer}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <AppLink to={`/login`} className={styles.LinkHover}>
              Home
            </AppLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <AppLink to={`/jobslists`} className={styles.LinkHover}>
              Job List
            </AppLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Job Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className={styles.jobDetailsBody}>
        {job && <DetailsJobCard job={job} isJobDetailsPage={true} />}
        <div className={styles.container}>
          <h1 className={styles.RoleHead}>
            About {job?.company?.company_name}
          </h1>
          <p className={styles.RoleText}>{job?.company?.overview}</p>
          <Link to={ROUTES.PATHS.PUBLIC.COMPANIES + `/${job?.company?.id}`}>
            <Button
              type="primary"
              className={styles.lernMoreButton}
              //   onClick={handleAskMore}
            >
              Learn More
            </Button>
          </Link>
        </div>

        <div className={styles.container}>
          <h1 className={styles.RoleHead}>Similar Jobs</h1>
          {job &&
            job.similar_jobs.map((similar_job) => (
              <SimpleJobCard job={similar_job} />
            ))}
          {job && job.similar_jobs.length < 1 && (
            <h3 className={styles.noSimilarJob}>No Similar Jobs Found</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
