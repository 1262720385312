import React, { FC } from "react";
import classNames from "classnames";
import { Col, Divider, Form, message, Modal, Row } from "antd";
import Button from "components/Button/Button";
import Input from "components/Input";
import TextArea from "antd/lib/input/TextArea";
import styles from "./detailsJobCard.module.scss";
import companyLogo from "../../../assets/images/companyLogo.png";
import streetLoactaionIcon from "../../../assets/images/streetLoactaionIcon.svg";
import { handleAskMoreAsync } from "store/public/ask-more/thunks";
import { handleApplyNowAsync } from "store/public/apply-now/thunks";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import { useIsMobile } from "shared/hooks";
import { Props } from "./types";
import { askMore } from "api/public/ask-more/types";
import { applyNow } from "api/public/apply-now/types";
import moment from "moment";
import { applyNowSelector } from "store/public/apply-now/selectors";
import { askMoreSelector } from "store/public/ask-more/selectors";
import { isValidUrl } from "shared/helpers/urlHelper";
import { convertSalaryToMillion } from "shared/helpers/helperFunctions";
import Image from "components/Image/Image";

const DetailsJobCard: FC<Props> = ({ job, isJobDetailsPage }) => {
  const [visibleApplyNowModal, setVisibleApplyNowModal] = React.useState(false);
  const [visibleAskMoreModal, setVisibleAskMoreModal] = React.useState(false);
  const isMobile = useIsMobile();
  const applyNowStatus = useAppSelector(applyNowSelector)?.applyNowCreateStatus;
  const askMoreStatus = useAppSelector(askMoreSelector)?.AskMoreCreateStatus;
  const unwrap = useUnwrapAsyncThunk();

  const handleCancelApplyNowModal = () => {
    setVisibleApplyNowModal(false);
  };

  const handleCancelAskMoreModal = () => {
    setVisibleAskMoreModal(false);
  };

  // handleAskMore;
  const handleAskMore = (values: askMore) => {
    unwrap(handleAskMoreAsync({ ...values, jobs: Number(job.id) })).then(() => {
      message.success("Thanks For Your Query", 1).then(() => {
        setVisibleAskMoreModal(false);
      });
    });
  };

  const handleApplyNow = (values: applyNow) => {
    unwrap(handleApplyNowAsync({ ...values, jobs: Number(job.id) })).then(
      () => {
        message.success("Successfully Applied", 1).then(() => {
          setVisibleApplyNowModal(false);
          if (job?.apply_url && isValidUrl(job?.apply_url)) {
            window.location.href = job?.apply_url;
          }
        });
      }
    );
  };

  return (
    <>
      <div
        className={classNames({
          [styles.container]: true,
          [styles.withoutModal]: isJobDetailsPage,
        })}
      >
        <Row justify="space-between" align="middle">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className={styles.comanyFeature}>
              <div>
                <Image
                  src={
                    job?.company_profile_picture === null
                      ? ""
                      : job?.company_profile_picture
                  }
                  defaultSrc={companyLogo}
                  alt={"companyLogo"}
                  style={styles.imageLogo}
                />
              </div>
              <div className={styles.companyInfo}>
                <h3>{job?.company_name}</h3>
                <h2>{job?.job_title}</h2>
              </div>
            </div>
            <div className={styles.loaction}>
              <img
                style={{
                  marginBottom: "5px",
                  paddingRight: "5px",
                }}
                src={streetLoactaionIcon}
                alt="streetLoactaionIcon"
              />
              <h5>
                {job?.job_location === "Other"
                  ? job?.other_location
                  : job?.job_location}
              </h5>
              {job?.is_remote && <h5> / Remote</h5>}/{" "}
              <h5
                className={classNames({
                  [styles.jobTypeNamePartTime]: job?.job_type === "PART_TIME",
                  [styles.jobTypeNameFullTime]: job?.job_type === "FULL_TIME",
                  [styles.jobTypeNameFreelance]:
                    job?.job_type === "FREELANCING",
                  [styles.jobTypeNameContract]: job?.job_type === "CONTRACT",
                })}
              >
                {job?.job_type.replace("_", " ")}
              </h5>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div
              className={classNames({
                [styles.applyContainer]: true,
                [styles["is-mobile"]]: isMobile,
              })}
            >
              <p
                className={classNames({
                  [styles.postText]: true,
                  [styles["is-mobile"]]: isMobile,
                })}
              >
                Posted {moment.utc(job?.publish_date).fromNow()}
              </p>
              {isJobDetailsPage && (
                <div>
                  <Button
                    type="primary"
                    className={styles.jobActionButton}
                    onClick={() => setVisibleAskMoreModal(true)}
                  >
                    ASK A QUESTION
                  </Button>
                  <Button
                    type="ghost"
                    className={styles.jobActionButton}
                    onClick={() => setVisibleApplyNowModal(true)}
                  >
                    APPLY NOW
                  </Button>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider></Divider>
          </Col>
        </Row>
        <Row justify="start" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <h1 className={styles.RoleHead}>This Role</h1>
            {job?.job_description && (
              <div
                className={styles.RoleText}
                dangerouslySetInnerHTML={{
                  __html: job?.job_description,
                }}
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <h1 className={styles.RoleHead}>This Job Offers</h1>
            <p className={styles.RoleText}>
              {job?.salary_type === "NEGOTIABLE"
                ? "Salary: NEGOTIABLE"
                : `Salary: ¥ ${convertSalaryToMillion(
                    job?.salary_min
                  )} - ${convertSalaryToMillion(job?.salary_max)}`}
            </p>
            {job?.is_remote && (
              <p className={styles.RoleText}>Apply from: Anywhere</p>
            )}
            {job?.is_visa_sponsored && (
              <p className={styles.RoleText}>Supports Visa Sponshorship</p>
            )}
            <h1 className={styles.RoleHead}> Requirements</h1>
            <p className={styles.RoleText}>Language Requirements </p>
            {job?.japanese === "REQUIRED" && (
              <p className={styles.RoleText}>Japanese</p>
            )}
            {job?.english === "REQUIRED" && (
              <p className={styles.RoleText}>English</p>
            )}
            <p className={styles.RoleText}>Minimum Experience</p>
            <p className={styles.RoleText}>
              {job?.experience_type === "NOT_REQUIRED"
                ? "Not Required"
                : job?.experience_year
                ? `${job?.experience_year} ${
                    job?.experience_year && job?.experience_year > 1
                      ? "Years"
                      : "Year"
                  }`
                : ""}
            </p>
          </Col>
        </Row>
      </div>
      {isJobDetailsPage && visibleApplyNowModal && (
        <Modal
          centered
          visible={visibleApplyNowModal}
          mask={false}
          onOk={handleCancelApplyNowModal}
          onCancel={handleCancelApplyNowModal}
          title={<>Apply for {job?.job_title}</>}
          className={styles.filterModal}
          footer={[
            <Button
              key="back"
              isBordered={true}
              onClick={handleCancelApplyNowModal}
            >
              Cancel
            </Button>,
            <Button
              key="1"
              type="ghost"
              htmlType="submit"
              form="applyNowForm"
              loading={applyNowStatus === "started" || false}
            >
              Submit
            </Button>,
          ]}
        >
          <Form
            onFinish={handleApplyNow}
            id="applyNowForm"
            size="large"
            layout="vertical"
          >
            <Row gutter={[16, 0]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="full_name"
                  label="Full Name"
                  rules={[
                    { message: "Please enter your Full Name!", required: true },
                  ]}
                >
                  <Input placeholder="" type="text" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      message: "Please enter your email!",
                      required: true,
                    },
                  ]}
                >
                  <Input type="email" placeholder="" />
                </Form.Item>
              </Col>
              <p className={styles.helperText}>
                After submission, you will be redirected to their website to
                proceed with the next step. You will receive a notification by
                email from BIJ confirming the job you applied for, with a link
                to a Contact form if you would like to follow up.
              </p>
            </Row>
          </Form>
        </Modal>
      )}
      {/* ask more modal */}
      {isJobDetailsPage && visibleAskMoreModal && (
        <Modal
          centered
          visible={visibleAskMoreModal}
          mask={false}
          onOk={handleCancelAskMoreModal}
          onCancel={handleCancelAskMoreModal}
          title={<>Request more information</>}
          className={styles.filterModal}
          footer={[
            <Button
              key="back"
              isBordered={true}
              onClick={handleCancelAskMoreModal}
            >
              Cancel
            </Button>,
            <Button
              key="1"
              type="ghost"
              htmlType="submit"
              form="askAQuestion"
              loading={askMoreStatus === "started" || false}
            >
              Submit
            </Button>,
          ]}
        >
          <Form
            onFinish={handleAskMore}
            id="askAQuestion"
            size="large"
            layout="vertical"
          >
            <Row gutter={[16, 0]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="full_name"
                  label="Full Name"
                  rules={[
                    { message: "Please enter your Full Name!", required: true },
                  ]}
                >
                  <Input placeholder="" type="text" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      message: "Please enter your email!",
                      required: true,
                    },
                  ]}
                >
                  <Input type="email" placeholder="" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="queries"
                  label="Queries"
                  extra="Enter a little bit about yourself and your query here"
                  rules={[
                    {
                      message: "Please enter your queries!",
                      required: true,
                    },
                  ]}
                >
                  <TextArea showCount maxLength={200} style={{ height: 100 }} />
                </Form.Item>
              </Col>
              <p className={styles.helperText}>
                Your request for more information goes directly to the hiring
                company. You will get a notification by email from BIJ about
                your query with a link to a Contact form if you would like to
                follow up.
              </p>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default DetailsJobCard;
