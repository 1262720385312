import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSuperAdminJobs,
  getSuperAdminJobsCategory,
  getSuperAdminJobsTitle,
  updateSuperAdminJobs,
} from "api/superadmin/jobs";
import {
  SuperAdminJobsRequestParams,
  UpdateSuperAdminJobsData,
} from "api/superadmin/jobs/types";

export const getSuperAdminJobsAsync = createAsyncThunk(
  "superadmin/jobs/getSuperAdminJobs",
  async (params?: SuperAdminJobsRequestParams) => {
    const response = await getSuperAdminJobs(params);
    return response;
  }
);

export const getSuperAdminJobsCategoryAsync = createAsyncThunk(
  "superadmin/jobs/getSupeadminJobsCategory",
  async () => {
    const response = await getSuperAdminJobsCategory();
    return response;
  }
);

export const getSuperAdminJobsTitleAsync = createAsyncThunk(
  "superadmin/jobs/getSupeadminJobsTitle",
  async (id: string) => {
    const response = await getSuperAdminJobsTitle(id);
    return response;
  }
);

export const updateSuperAdminJobsAsync = createAsyncThunk(
  "superadmin/jobs/updateSuperAdminJobsy",
  async (data: UpdateSuperAdminJobsData) => {
    const response = await updateSuperAdminJobs(data, data.jobsId);
    return response;
  }
);
