import { Form, message, Row, Col, Modal } from "antd";
import React, { FC, useEffect } from "react";
import Button from "components/Button";
import Input from "components/Input";
import { jobTag } from "./types";
import { useUnwrapAsyncThunk } from "store/hooks";
import {
  getSuperAdminTagsAsync,
  postSuperAdminTagAsync,
  putSuperAdminTagAsync,
} from "store/superadmin/tags/thunks";
import { SuperAdminTags } from "api/superadmin/tags/types";
import { useForm } from "antd/lib/form/Form";

type TagModalType = {
  tag?: SuperAdminTags;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setDropdownVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TagModal: FC<TagModalType> = ({
  tag,
  visible,
  setVisible,
  setDropdownVisible,
}) => {
  const unwrap = useUnwrapAsyncThunk();
  const [form] = useForm();

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (tag) {
      form.setFieldsValue(tag);
    }
  }, [tag?.id]);

  const handleTag = (values: jobTag) => {
    if (tag) {
      unwrap(putSuperAdminTagAsync({ tagId: tag.id, data: { ...values } }))
        .then(() => {
          message.success("Job Tag Successfully edited", 1).then(() => {
            setVisible(false);
            setDropdownVisible && setDropdownVisible(false);
          });
          unwrap(getSuperAdminTagsAsync());
        })
        .catch((e) => {
          // message.error(e.message);
        });
    } else {
      unwrap(postSuperAdminTagAsync({ ...values }))
        .then(() => {
          message.success("Job Tag Successfully added", 1).then(() => {
            setVisible(false);
          });
          unwrap(getSuperAdminTagsAsync());
        })
        .catch((e) => {
          // message.error(e.message);
        });
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      mask={false}
      onOk={handleCancel}
      onCancel={handleCancel}
      title={<>Tag</>}
      //   className={styles.filterModal}
      footer={[
        <Button key="back" isBordered={true} onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="1" type="ghost" htmlType="submit" form="applyNowForm">
          {tag ? "Update" : "Save"}
        </Button>,
      ]}
    >
      <Form
        onFinish={handleTag}
        id="applyNowForm"
        size="large"
        layout="vertical"
        form={form}
      >
        <Row gutter={[16, 0]}>
          <Col className="gutter-row" span={24}>
            <Form.Item
              name="job_tag"
              rules={[
                { message: "Please enter an unique tag!", required: true },
              ]}
            >
              <Input label="Job Tag" placeholder="" type="text" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default TagModal;
