import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Typography,
  Input as TextAreaInput,
  Spin,
  Modal,
  InputNumber,
} from "antd";
import { RuleObject } from "antd/lib/form";
import SharingLogo from "assets/images/bij-sharing-logo.png";
import Input from "components/Input";
import Button from "components/Button";
import styles from "./createCompany.module.scss";
import SelectInput from "components/SelectInput";
import { SignupPostData } from "api/shared/auth/types";
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from "store/hooks";
import {
  getCompanyProfileData,
  selectSignUpRequestStatus,
} from "store/shared/auth/selectors";
import { getCompanyProfileAsync, signupAsync } from "store/shared/auth/thunks";
import { getJobLocationsAsync } from "store/admin/jobLocations/thunks";
import { jobLocations } from "store/admin/jobLocations/selectors";
import { OptionType, Props } from "./types";
import { getIndustryAsync } from "store/public/companies/thunks";
import { getPublicCompaniesApi } from "store/public/companies/selectors";
import { useUpdateCompanyInfo } from "./hooks";
import { Link } from "react-router-dom";
import { setSignupStatus } from "store/shared/auth/actions";
import { useCompanyUploadData } from "uicontainers/Admin/Account/hooks";
import ReactQuill from "react-quill";
import ProfilePicture from "uicontainers/_shared/ProfilePicture/ProfilePicture";
const { TextArea } = TextAreaInput;
const { Title } = Typography;

const CreateCompany: React.FC<Props> = ({ isCompanyEdit }) => {
  const [_, setIsFormData] = useState(false);
  const [isShowOtherLocation, setIsShowOtherLocation] = useState(false);
  const dispatch = useAppDispatch();
  const isSignUpLoading =
    useAppSelector(selectSignUpRequestStatus) === "started";
  const isSignUpSuccess = useAppSelector(selectSignUpRequestStatus) === "done";
  const [form] = Form.useForm();
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    unwrap(getJobLocationsAsync());
    unwrap(getIndustryAsync());
    isCompanyEdit &&
      unwrap(getCompanyProfileAsync()).then((companyProfileData) => {
        form.setFieldsValue(companyProfileData.results[0]);
        setIsFormData(true);
      });
  }, []);
  const updateCompany = useUpdateCompanyInfo();
  const upload = useCompanyUploadData();
  const companyProfileData = useAppSelector(getCompanyProfileData)[0];
  const { profile_picture } = companyProfileData || {};

  useEffect(() => {
    if (isCompanyEdit) {
      form.setFieldsValue(companyProfileData);
      if (companyProfileData?.company_location === "Other") {
        setIsShowOtherLocation(true);
      }
      setIsFormData(true);
    }
  }, [companyProfileData]);

  const onFinish = (values: SignupPostData) => {
    if (isCompanyEdit) {
      updateCompany(values);
    } else {
      dispatch(signupAsync({ ...values }));
    }
  };
  const jobLocation = useAppSelector(jobLocations).jobLocations;
  const industries = useAppSelector(getPublicCompaniesApi).industry;

  const LocationOptions: OptionType[] = jobLocation.map((location) => ({
    name: location.location,
    value: location.location,
  }));

  const onSelectCompanyLocation = (location: string) => {
    if (location === "Other") {
      setIsShowOtherLocation(true);
    } else {
      setIsShowOtherLocation(false);
      if (!isCompanyEdit) {
        form.setFieldsValue({ other_location: "" });
      }
    }
  };

  const comapnyType: OptionType[] = [
    { name: "DIRECT HIRING COMPANY ", value: "DIRECT_HIRING_COMPANY" },
    { name: "AGENCY", value: "AGENCY" },
  ];

  const IndustryType: OptionType[] = industries.map((industry) => ({
    name: industry.industry,
    value: industry.industry,
  }));

  const loginClickHandler = () => {
    dispatch(setSignupStatus("initial"));
  };

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["link"],
  ];

  const validatePassword = (
    rule: RuleObject,
    value: string,
    callback: (message?: string) => void
  ) => {
    if (value && value.includes(" ")) {
      callback("Password should not contain spaces");
    } else {
      callback();
    }
  };

  return (
    <div className={styles.main}>
      {isSignUpSuccess && (
        <Modal
          centered
          visible={isSignUpSuccess}
          closable={false}
          className={styles.filterModal}
          footer={[]}
        >
          <div className={styles.modalContainer}>
            <Title level={4}>
              Your submission is under review. If you have questions or wish to
              follow up please use the{" "}
              <a
                href="https://www.businessinjapan.com/contact/"
                target="_blank"
                rel="noreferrer"
              >
                Contact Form.
              </a>
            </Title>
            <Link to="/login">
              <Button onClick={loginClickHandler} type="ghost">
                OK
              </Button>
            </Link>
          </div>
        </Modal>
      )}

      {!isCompanyEdit && (
        <div className={styles.createCompanyContainer}>
          <Title level={3} className={styles.mainTitle}>
            Create a Company Account
          </Title>
          <p className={styles.mainSubTitle}>
            Please provide the following information about your <br />
            company.
          </p>
        </div>
      )}
      <Row justify="start" align="middle">
        <Col
          xs={20}
          sm={20}
          md={20}
          lg={9}
          className={styles.fromContainer}
          style={{ margin: isCompanyEdit ? 0 : "0 auto" }}
        >
          <Spin spinning={isSignUpLoading}>
            <h3 className={styles.title}>Company Information</h3>
            <Form
              form={form}
              onFinish={onFinish}
              size={isCompanyEdit ? "middle" : "large"}
              layout="vertical"
            >
              <div>
                {isCompanyEdit && (
                  <ProfilePicture
                    profile_picture={profile_picture}
                    updateAccount={updateCompany}
                    upload={upload}
                  />
                )}
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="company_name"
                    label="Company Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company name!",
                      },
                    ]}
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="company_location"
                    label="Location"
                    rules={[
                      {
                        required: true,
                        message: "Please select a company location",
                      },
                    ]}
                  >
                    <SelectInput
                      options={LocationOptions}
                      onSelect={onSelectCompanyLocation}
                    />
                  </Form.Item>
                </Col>
                {isShowOtherLocation && (
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      name="other_location"
                      extra="Please enter other location"
                      rules={[
                        {
                          required: true,
                          message: "Please enter other location",
                        },
                      ]}
                    >
                      <Input placeholder="" type="text" />
                    </Form.Item>
                  </Col>
                )}
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="company_type"
                    label="Company Type"
                    rules={[
                      {
                        required: true,
                        message: "Please select a company type",
                      },
                    ]}
                  >
                    <SelectInput options={comapnyType} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="industry"
                    label="Industry"
                    rules={[
                      {
                        required: true,
                        message: "Please select a job industry",
                      },
                    ]}
                  >
                    <SelectInput options={IndustryType} showSearch={true} />
                  </Form.Item>
                </Col>
                {/* <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="contact_name"
                    label="Company Contact Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company contact name",
                      },
                    ]}
                  >
                    <Input placeholder="" type="text" />
                  </Form.Item>
                </Col> */}
                {/* <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="contact_designation"
                    label="Company Contact Role"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company contact role",
                      },
                    ]}
                  >
                    <Input placeholder="" type="text" />
                  </Form.Item>
                </Col> */}
                {/* <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="contact_email"
                    label="Company Contact Email"
                    extra="For follow up communication only. Must be an email address with the company's official domain."
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company contact email",
                      },
                    ]}
                  >
                    <Input placeholder="" type="email" />
                  </Form.Item>
                </Col> */}
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="number_of_employees"
                    label="Number of Employees (worldwide)"
                    extra="The total number of employees of the company worldwide."
                    rules={[
                      {
                        required: true,
                        message: "Please enter number of employees",
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} min={0} />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="company_url"
                    label="Company Website URL"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your company website url",
                      },
                    ]}
                  >
                    <Input placeholder="" type="text" />
                  </Form.Item>
                </Col>

                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="overview"
                    label="Company Overview"
                    extra="This will be displayed as your About Company description."
                    rules={[
                      {
                        required: true,
                        message: "Please enter company overview",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder=""
                      showCount
                      maxLength={1500}
                      style={{ height: 120 }}
                    />
                  </Form.Item>
                </Col>
                {isCompanyEdit && (
                  <>
                    <Col className="gutter-row" span={24}>
                      <Form.Item name="work_style" label="Work Style">
                        <>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            theme="snow"
                            value={form.getFieldValue("work_style") || ""}
                            onChange={(value) =>
                              form.setFieldsValue({ work_style: value })
                            }
                            style={{ background: "#fff" }}
                          />
                        </>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item name="diversity" label="Diversity">
                        <>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            theme="snow"
                            value={form.getFieldValue("diversity") || ""}
                            onChange={(value) =>
                              form.setFieldsValue({ diversity: value })
                            }
                            style={{ background: "#fff" }}
                          />
                        </>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item name="mission" label="Mission">
                        <>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            theme="snow"
                            value={form.getFieldValue("mission") || ""}
                            onChange={(value) =>
                              form.setFieldsValue({ mission: value })
                            }
                            style={{ background: "#fff" }}
                          />
                        </>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item name="detailed_data" label="Detailed Data">
                        <>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            theme="snow"
                            value={form.getFieldValue("detailed_data") || ""}
                            onChange={(value) =>
                              form.setFieldsValue({ detailed_data: value })
                            }
                            style={{ background: "#fff" }}
                          />
                        </>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item name="benefit" label="Benefit">
                        <>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            theme="snow"
                            value={form.getFieldValue("benefit") || ""}
                            onChange={(value) =>
                              form.setFieldsValue({ benefit: value })
                            }
                            style={{ background: "#fff" }}
                          />
                        </>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item
                        name="interview_process"
                        label="Interview Process"
                      >
                        <>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            theme="snow"
                            value={
                              form.getFieldValue("interview_process") || ""
                            }
                            onChange={(value) =>
                              form.setFieldsValue({ interview_process: value })
                            }
                            style={{ background: "#fff" }}
                          />
                        </>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item name="important_links" label="Important Links">
                        <>
                          <ReactQuill
                            modules={{ toolbar: toolbarOptions }}
                            theme="snow"
                            defaultValue={
                              form.getFieldValue("important_links") || ""
                            }
                            value={form.getFieldValue("important_links") || ""}
                            onChange={(value) =>
                              form.setFieldsValue({ important_links: value })
                            }
                            style={{ background: "#fff" }}
                          />
                        </>
                      </Form.Item>
                    </Col>
                  </>
                )}
                {!isCompanyEdit && (
                  <>
                    <Title level={4} className={styles.title}>
                      Company and Job Management User Information
                    </Title>
                    <Col className="gutter-row" span={24}>
                      <Form.Item
                        name="email"
                        label="User Email"
                        extra="For use managing the Company Account and Job posts. Must be an email address with the company's official domain."
                        rules={[
                          {
                            required: true,
                            message: "Please enter your email!",
                          },
                        ]}
                      >
                        <Input placeholder="" type="email" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item
                        extra="At least 8 characters with upper and lowercase letters, and one number or symbol"
                        name="password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your password",
                          },
                          {
                            validator: validatePassword,
                          },
                        ]}
                      >
                        <Input inputType="password" />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col className="gutter-row" span={24}>
                  <Button
                    isBordered={true}
                    className={styles.homeButton}
                    htmlType="submit"
                    loading={isSignUpLoading || false}
                  >
                    {isCompanyEdit ? "Update" : "Submit"}
                  </Button>
                </Col>
              </div>
            </Form>
          </Spin>
        </Col>
      </Row>
      {/* This is here for just to host this image from front-end to share in social media */}
      <img
        className={styles.socialShareImage}
        src={SharingLogo}
        alt="bij-sharing-logo"
      />
    </div>
  );
};

export default CreateCompany;
